<template lang="html">
  <b-container class="profile-box">
      <b-row class="profile-menu">
        <b-col class="profile-row" md="12">
          <b-container>
            <b-row>
              <b-col col lg="12" md="12" sm="12" cols="12">
                <h5> {{$t('profileMenu.title')}}</h5>
              </b-col>  
              <b-col>
                <p>{{$t('profileMenu.description')}}</p>
              </b-col>
            </b-row>
            <b-row class="mt-25 mb-50"> 
                <b-col col lg="12" md="12" sm="12" cols="12">
                    <router-link to="/information"><b-button v-b-toggle.collapse-1 class="profile-button">{{$t('profileMenu.links.information')}}</b-button></router-link>
                    <b-collapse id="collapse-1" class="mt-2"></b-collapse>      
                </b-col>
                

                <b-col col lg="12" md="12" sm="12" cols="12">
                     <b-button v-b-toggle.collapse-3 class="profile-button">{{$t('profileMenu.links.quickQuote.title')}} <b-icon icon="caret-down-fill"></b-icon></b-button>
                    <b-collapse id="collapse-3" class="mt-2">
                        <b-card>
                            <b-row>
                                <b-col col lg="12" md="12" sm="12" cols="12">
                                    <router-link to="/new-instant"><b-button v-b-toggle.collapse-1-inner size="sm">{{$t('profileMenu.links.quickQuote.newQuickQuote')}}</b-button></router-link>
                                    <b-collapse id="collapse-1-inner" class="mt-2"></b-collapse>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col col lg="12" md="12" sm="12" cols="12">
                                    <router-link to="/my-quick-quote"><b-button v-b-toggle.collapse-2-inner size="sm">{{$t('profileMenu.links.quickQuote.myQuickQuote')}}</b-button></router-link>
                                    <b-collapse id="collapse-2-inner" class="mt-2"></b-collapse>
                                </b-col>
                            </b-row>
                        </b-card>    
                    </b-collapse> 

                </b-col>

                <b-col col lg="12" md="12" sm="12" cols="12">
                    <b-button v-b-toggle.collapse-4 href="#" class="profile-button">{{$t('profileMenu.links.eQuote.title')}}<b-icon icon="caret-down-fill"></b-icon></b-button>
                    <b-collapse id="collapse-4" class="mt-2">
                        <b-card>
                            <b-row>
                                <b-col col lg="12" md="12" sm="12" cols="12">
                                    <router-link to="/quotation"><b-button v-b-toggle.collapse-1-inner href="/quotation" size="sm">{{$t('profileMenu.links.eQuote.newEQuote')}}</b-button></router-link>
                                    <b-collapse id="collapse-1-inner" class="mt-2"></b-collapse>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col col lg="12" md="12" sm="12" cols="12">
                                    <router-link to="/my-quote"><b-button v-b-toggle.collapse-2-inner size="sm">{{$t('profileMenu.links.eQuote.myEQuote')}}</b-button></router-link>
                                    <b-collapse id="collapse-2-inner" class="mt-2"></b-collapse>
                                </b-col>
                            </b-row>
                        </b-card>    
                    </b-collapse> 

                </b-col>

                <b-col col lg="12" md="12" sm="12" cols="12">
                    <router-link to="/booking"><b-button v-b-toggle.collapse-7 class="profile-button">{{$t('profileMenu.links.booking')}}</b-button></router-link>
                    <b-collapse id="collapse-7" class="mt-2"></b-collapse>      
                </b-col>

                <!-- <b-col col lg="12" md="12" sm="12" cols="12">
                    <b-button v-b-toggle.collapse-5 href="#" class="profile-button">Booking <b-icon icon="caret-down-fill"></b-icon></b-button>
                    <b-collapse id="collapse-5" class="mt-2">
                        <b-card>
                            <b-row>
                                <b-col col lg="12" md="12" sm="12" cols="12">
                                    <router-link to="/booking-test"><b-button v-b-toggle.collapse-1-inner size="sm">Booking Test</b-button></router-link>
                                    <b-collapse id="collapse-1-inner" class="mt-2"></b-collapse>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col col lg="12" md="12" sm="12" cols="12">
                                    <router-link to="/booking"><b-button v-b-toggle.collapse-2-inner size="sm">Booking</b-button></router-link>
                                    <b-collapse id="collapse-2-inner" class="mt-2"></b-collapse>
                                </b-col>
                            </b-row>
                        </b-card>    
                    </b-collapse> 

                </b-col> -->
            <b-col col lg="12" md="12" sm="12" cols="12">
                    <router-link to="/my-b-l"><b-button v-b-toggle.collapse-7 class="profile-button">{{$t('profileMenu.links.bl.title')}}</b-button></router-link>
                    <b-collapse id="collapse-7" class="mt-2"></b-collapse>      
                </b-col>
                <!-- <b-col col lg="12" md="12" sm="12" cols="12">
                    <b-button v-b-toggle.collapse-6 class="profile-button">{{$t('profileMenu.links.bl.title')}}<b-icon icon="caret-down-fill"></b-icon></b-button>
                    <b-collapse id="collapse-6" class="mt-2">
                      <b-card>
                            //<b-row>
                                <b-col col lg="12" md="12" sm="12" cols="12">
                                    <router-link to="/b-l"><b-button v-b-toggle.collapse-6-inner size="sm">{{$t('profileMenu.links.bl.newBL')}}</b-button></router-link>
                                    <b-collapse id="collapse-6-inner" class="mt-2"></b-collapse>
                                </b-col>
                           // </b-row> 

                            <b-row>
                                <b-col col lg="12" md="12" sm="12" cols="12">
                                    <router-link to="/my-b-l"><b-button v-b-toggle.collapse-6-inner size="sm">{{$t('profileMenu.links.bl.myBL')}}</b-button></router-link>
                                    <b-collapse id="collapse-6-inner" class="mt-2"></b-collapse>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-collapse> 
                </b-col> -->

                <b-col col lg="12" md="12" sm="12" cols="12">
                    <router-link to="/scheduling"><b-button v-b-toggle.collapse-7 class="profile-button">{{$t('profileMenu.links.schedule')}}</b-button></router-link>
                    <b-collapse id="collapse-7" class="mt-2"></b-collapse>      
                </b-col>

                <b-col col lg="12" md="12" sm="12" cols="12">
                    <router-link to="/tracking"><b-button v-b-toggle.collapse-8 class="profile-button">{{$t('profileMenu.links.tracking')}}</b-button></router-link>
                    <b-collapse id="collapse-8" class="mt-2"></b-collapse>      
                </b-col>
                <b-col col lg="12" md="12" sm="12" cols="12">
                    <b-button v-b-toggle.collapse-2 href="#" class="profile-button hide-cursor">{{$t('profileMenu.links.financialFile')}}</b-button>
                    <b-collapse id="collapse-2" class="mt-2"></b-collapse>      
                </b-col>
            </b-row> 

              <b-row>
                <b-col col lg="12" md="12" sm="12" cols="12">
                  <b-button @click="signOut()" class="btnExit" ><b-icon icon="box-arrow-right"></b-icon> {{$t('profileMenu.exitBtn')}}</b-button>
                </b-col>
            </b-row>    
          </b-container>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { set } from 'vue';
export default {
  name: "profileMenu",
  components: {},
  data() {
    return {
    };
  },
  created() {},

  methods: {
    signOut(){
      localStorage.removeItem("userData")
      setTimeout(() => {
        // this.$router.push("/login");
        window.location.href = "/login";
      }, 300);
    }
  },
};
</script>
<style lang="css" scoped>
.profile-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-menu {
  color: white;
  /* background: #082139; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-image: url('../../assets/img/boatSea.jpg');
  width: 100%;
  height: auto;
  border-radius: 12px 12px 12px 12px;
}
.profile-row{
  height: 100%;
  width: 100%;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  background: linear-gradient(
    180deg,
    rgba(13, 6, 39, 0.75) 0%,
    rgba(13, 6, 40, 0.747656) 0.01%,
    #0e0728 0.02%,
    rgba(13, 6, 39, 0) 45.1%
  );
}
/*
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1.2rem !important;
}
*/
.profile-button {
  color: white !important;
  background: none;
  border: none;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding-bottom: -10px;
}

.hide-cursor {
  cursor: not-allowed !important;
}

.profile-menu .btn-secondary{
  padding-left: 0px!important;
}
.btn-secondary {
    background-color: inherit !important;
    border: none;
    /* margin-bottom: -10px; */
}
.btn-secondary.active, .btn-secondary:active {
    color: #F2AE65 !important;
}
.btn-secondary:hover, .btn-secondary:focus {
    color: #F2AE65 !important;
    border: unset !important;
}
.profile-box .card{
  border: none;
}
.profile-box .btn-secondary[data-v-eccca77c]:hover, .btn-secondary[data-v-eccca77c]:focus {
    color: #F2AE65 !important;
    border: none !important;
    box-shadow:none;
}
.card {
    background-color: inherit !important;
}

.profile-box {
  margin-bottom: 20vh !important;
  padding: 0rem 1rem;
}
.btnExit {
  color: #F2AE65 !important;
}
/* *********************************************************************************************************** */
/* responsive */

@media only screen and (max-width: 966px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .profile-menu {
    width: 100%;
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .profile-menu {
    width: 100%;
    margin-left: 0% !important;
  }
    .profile-button {
    margin-top: 1rem !important;
  }
  .hide-cursor {
  cursor: not-allowed;
}
  .profile-box {
    margin-bottom: 5vh !important;
  }
}

@media only screen and (max-width: 644px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .profile-menu {
    width: 100%;
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 372px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .profile-menu {
    width: 100%;
    margin-left: 0% !important;
  }
}
</style>