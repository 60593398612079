<template lang="html">
    <div class="booking-layout">
      <BookingBreadcrumb />
       <div class="forms-padding">
        <b-row class="row-eq-height">
          <b-col col lg="3" md="3" sm="12" cols="12" class="profile-menu-card">
              <profileMenu />
          </b-col>
          <b-col col lg="9" md="9" sm="12" cols="12" class="forms-quoation-card mb-100">
            <BookingEdit />
          </b-col>
        </b-row>
      </div>
    </div>
</template>
<script>
import BookingBreadcrumb from "../components/Booking/BookingBreadcrumbEdit.vue";
import profileMenu from "../components/Profile/profileMenu.vue";
import BookingEdit from "../components/Booking/BookingEdit.vue";

export default {
  name: "Booking",
  components: {
    BookingBreadcrumb,
    profileMenu,
    BookingEdit,
  },
  methods: {},
};
</script>
<style lang="css">
.booking-layout {
  padding-top: 25vh !important;
}
</style>
