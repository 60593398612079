<template lang="html">
  <b-container class="booking-box-tab">
      <div class="bl-tab">
        <h2 class="booking-title mb-25">{{$t('profileMenu.links.bl.myBL')}}</h2>
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{$t('homePage.hero.tracking.btn')}}:</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('homePage.hero.tracking.btn')"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

    <!-- table -->
    <vue-good-table
      styleClass="vgt-table bordered"
      theme="polar-bear"
        :line-numbers="true"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      class="mb-50"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'references'">
          <span v-if="props.row.booking.reference">{{ props.row.booking.reference }}</span>   
          <span v-else>{{ $t('myQuickQuotePage.labels.noReference') }}</span>         
        </span>
        <!-- Column: Cargo Details -->
        <span v-if="props.column.field === 'cargo'">
          <b-button class="detail-button" v-b-modal="'cargoDetails'" @click="updateCargo(props.row.cargoDetails)">{{ $t('myBLPage.view') }}</b-button>
        </span>
        <!-- Column: Parties -->
          <span v-if="props.column.field === 'party'">
          <b-button class="detail-button" v-b-modal="'parties'" @click="updateParties(props.row.parties)">{{ $t('myBLPage.view') }}</b-button>
        </span>
        <!-- Column: PDF -->
        <span v-if="props.column.field === 'doc'">
           <b-button class="detail-button" @click="downloadFile(props.row.pdf)" v-if="props.row.pdf">
            <b-icon icon="download" font-scale="0.9" class="text-body align-middle mr-25"></b-icon>
          </b-button>
          <span v-else>Pending BL</span>
        </span>

       <!-- Column: Status -->
       <!-- <span v-if="props.column.field === 'state'">
          <b-badge :variant="statusVariant(props.row.state)" class="state">
            {{ props.row.state }}
          </b-badge>
        </span> -->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <b-icon icon="three-dots-vertical" font-scale="0.9" class="text-body align-middle mr-25"></b-icon>
              </template>

               <b-dropdown-item @click="duplicateBL(props.row)">
                <b-icon icon="files" font-scale="0.9" class="mr-50"></b-icon>
                <span> {{$t('myBLPage.actions.duplicateBtn')}}</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <b-icon icon="pencil-square" font-scale="0.9" class="mr-50"></b-icon>
                 <span><router-link :to="updateBL(props.row._id)"> {{$t('myQuickQuotePage.actions.updateBtn')}}</router-link></span>
              </b-dropdown-item>

              <b-dropdown-item>
                <b-icon icon="trash" font-scale="0.9" class="mr-50"></b-icon>
                <span @click="DeleteBL(props.row._id)"> {{$t('myQuickQuotePage.actions.deleteBtn')}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap bottom-table-style">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
             {{$t('myQuickQuotePage.pagination.showing')}}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{$t('myQuickQuotePage.pagination.of')}} {{ props.total }} {{$t('myQuickQuotePage.pagination.entries')}} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <b-icon icon="chevron-compact-left"></b-icon>
              </template>
              <template #next-text>
                <b-icon icon="chevron-compact-right"></b-icon>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>  
    <b-modal id="cargoDetails" size="xl" hide-footer centered  title="Cargo Details"><cargo :data="cargoData"></cargo></b-modal>
    <b-modal id="parties" size="xl" hide-footer centered  title="Parties"><parties :data="partiesData"></parties></b-modal>
           </div> 
    </b-container>
</template>
<script>
import axios from "axios";
import { VueGoodTable } from 'vue-good-table';
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import cargo from './CargoDetails.vue'
import parties from './Parties.vue'
export default {
  name: "BookingTab",
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    cargo,
    parties
  },
  data() {
      return {
      pageLength: 3,
      columns: [

        {
          label: 'Action',
          field: 'action',
        },
                  {
          label: 'Document',
          field: 'doc',
        },
        {
          width: '180px',
          label: this.$t('myQuickQuotePage.labels.reference'),
          field: 'references',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.reference'),
          },
        },
        {
          width: '180px',
          label: this.$t('newBLPage.documentSection.documentType'),
          field: 'documentType',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newBLPage.documentSection.documentTypeSearch'),
          },
        },
        {
          width: '240px',
          label: this.$t('newBLPage.documentSection.connaissement'),
          field: 'receiverAgent',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newBLPage.documentSection.connaissementSearch'),
          },
        },
        {
          width: '210px',
          label: this.$t('newBLPage.cargoDetailsSection.title'),
          field: 'cargo',
        },
        {
          width: '140px',
          label: this.$t('newBLPage.partiesSection.title'),
          field: 'party',
        },
        {
          width: '170px',
          label: this.$t('newBLPage.paymentDetailsSection.paymentName'),
          field: 'payment.paymentName',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newBLPage.paymentDetailsSection.paymentNameSearch'),
          },
          formatFn: value => value.toUpperCase()
        },
        {
          width: '170px',
          label: this.$t('newBLPage.paymentDetailsSection.card1.paymentType'),
          field: 'payment.paymentType',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newBLPage.paymentDetailsSection.card1.paymentTypeSearch'),
          },
        },
        {
          width: '170px',
          label: this.$t('newBLPage.paymentDetailsSection.card1.paymentCountry'),
          field: 'payment.paymentCountry',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newBLPage.paymentDetailsSection.card1.paymentCountrySearch'),
          },
        },
        {
          width: '190px',
          label: this.$t('newBLPage.paymentDetailsSection.card1.inovoiceDetails'),
          field: 'payment.invoiceDetails',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newBLPage.paymentDetailsSection.card1.inovoiceDetailsSearch'),
          },
        },
        /*{
          label: this.$t('createBookingPageFromQuote.state'),
          field: 'state',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.stateSearch'),
          },
        },*/
      
      ],
      rows: [],
      searchTerm: '',
      disabled: true,
      cargoData : [],
      partiesData: [],
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newLocale) {
        this.columns[2].label = this.$t('myQuickQuotePage.labels.reference');
        this.columns[2].filterOptions.placeholder = this.$t('myQuickQuotePage.search.reference');
        this.columns[3].label = this.$t('newBLPage.documentSection.documentType');
        this.columns[3].filterOptions.placeholder = this.$t('newBLPage.documentSection.documentTypeSearch');
        this.columns[4].label = this.$t('newBLPage.documentSection.connaissement');
        this.columns[4].filterOptions.placeholder = this.$t('newBLPage.documentSection.connaissementSearch');
        this.columns[5].label = this.$t('newBLPage.cargoDetailsSection.title');
        this.columns[6].label = this.$t('newBLPage.partiesSection.title');
        this.columns[7].label = this.$t('newBLPage.paymentDetailsSection.paymentName');
        this.columns[7].filterOptions.placeholder = this.$t('newBLPage.paymentDetailsSection.paymentNameSearch');
        this.columns[8].label = this.$t('newBLPage.paymentDetailsSection.card1.paymentType');
        this.columns[8].filterOptions.placeholder = this.$t('newBLPage.paymentDetailsSection.card1.paymentTypeSearch');
        this.columns[9].label = this.$t('newBLPage.paymentDetailsSection.card1.paymentCountry');
        this.columns[9].filterOptions.placeholder = this.$t('newBLPage.paymentDetailsSection.card1.paymentCountrySearch');
        this.columns[10].label = this.$t('newBLPage.paymentDetailsSection.card1.inovoiceDetails');
        this.columns[10].filterOptions.placeholder = this.$t('newBLPage.paymentDetailsSection.card1.inovoiceDetailsSearch');
        /*this.columns[9].label = this.$t('createBookingPageFromQuote.state');
        this.columns[9].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.stateSearch');*/
      },
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        accepted     : 'success',
        refused     : 'danger',
        pending     : 'warning',
      }
        return status => statusColor[status]
    },
  },
  async created() {
    await this.getAllDataBL();
  },
  methods: {
    async getAllDataBL() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/bls?user=" +JSON.parse(localStorage.getItem("userData")).user._id
      );  
      this.rows = response.data.data.reverse();;
    },
    updateCargo(cargo) {
      this.cargoData = cargo
    },
    updateParties(data) {
      this.partiesData = Object.entries(data).map(([groupName, groupData]) => ({
            groupName,
            groupData
        })); 
    },
    duplicateBL(row){
        const form = {
        ...row,
        customer: JSON.parse(localStorage.getItem("userData")).user._id,
        booking: row.booking._id,
        state: "pending"
        };
        delete form._id;
        axios.post('https://mtl-feeder-nodejs.onrender.com/api/bls', form)
          .then((response) => {
              this.getAllDataBL();
              this.$toast.open({
                message: this.$t('newBLPage.BLAdded'),
                type: "success",
                duration: 3000
              });
          })
            .catch((error) => {
            this.$toast.open({
                message: this.$t('addErrorMessage'),
                type: "error",
                duration: 3000
            });
            })
    },
    updateBL(id){
      return '/edit-b-l?id='+id
    },
    DeleteBL(id) {
      axios.put(`https://mtl-feeder-nodejs.onrender.com/api/bls/${id}`, { active : false })
      .then(async (response) => {
        await this.getAllDataBL();           
      })
      .catch((error) => {
      })
    },
    downloadFile(url) {
      axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "File.pdf";  
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },
  }
}
</script>
<style lang="css">
.bl-tab .vgt-table.polar-bear td {
  padding: .60em 0 .60em 0 !important;
  text-align: center;
  vertical-align: middle !important;
}
.booking-box-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bl-tab {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}
.booking-title {
  position: relative;
  text-align: center;
}
.disable-text {
  color: #8a8a8a;
}
.detail-button {
  border: 1px solid #525f7f;
  background-color: transparent;
  color: #525f7f;
  font-size: 14px;
}
.detail-button:hover {
  border: 1px solid #525f7f;
  background-color: transparent;
  color: #525f7f;
}
</style>