<template lang="html">
  <b-card :title="$t('contactUsPage.ContactText.title')" body-class="text-center" header-tag="nav" class="card-login">
    <b-card-text class="register-text">
      {{$t('contactUsPage.ContactText.text')}}
    </b-card-text>
  </b-card>
</template>
<script lang="js">
export default {
  name: 'LoginRegister'
}
</script>
<style lang="css" scoped>
.card {
  border: none !important;
  margin-top: 8vh !important;
  margin-bottom: 10vh !important;
}
.register-text {
  padding: 0rem !important;
}
.register-link {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  font-size: 18px;
}
.register-link:hover, .register-link:active {
  background-color: #ffd500e7 !important;
}
.card-login{
  width: 60%;
  margin-left: 20%;
}
@media only screen and (max-width: 768px) {
 .card-login{
  width: 100%!important;
  margin-left: 0%!important;
}
}
</style>