<template lang="html">
  <div>
    <b-container class="bl-header-box">
      <b-row>
        <h1 class="breadcrumb-header">{{$t('profileMenu.links.bl.newBL')}}</h1>
      </b-row>
    </b-container>
    <b-container class="bl-breadcrumb-box">
      <b-row>
        <!-- <b-breadcrumb :items="items" class="breadcrumb-links"></b-breadcrumb> -->
        <b-breadcrumb class="breadcrumb-links">
          <b-breadcrumb-item href="../">{{$t('nav.home')}}</b-breadcrumb-item>
          <b-breadcrumb-item active>{{$t('profileMenu.links.bl.newBL')}}</b-breadcrumb-item>
        </b-breadcrumb>
      </b-row>
    </b-container>
  </div>
</template>
<script lang="js">
export default {
  name: 'BLBreadcrumb',
  data() {
      return {
        // items: [
        //   {
        //     text: 'Home',
        //     href: '../'
        //   },
        //   {
        //     text: 'New B/L',
        //     active: true
        //   }
        // ]
      }
    }
}
</script>
<style lang="css" scoped>
.bl-breadcrumb-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vh !important;
}
.bl-header-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadcrumb-links {
  background-color: #fff !important;
}
.breadcrumb-links li:last-child {
  font-weight: 600;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">"
}
.breadcrumb-item.active {
  color: #082139 !important;
}
li:not(.breadcrumb-item.active) {
  color: #6c757d !important;
}
.breadcrumb-header {
  color: #082139 !important;
}
</style>