<template lang="html">
  <div class="second-padding services-section">
    <b-card  body-class="text-center" header-tag="nav" class="card-text">
      <h2 class="h2-blue "> {{$t('ourServicesPage.servicesSection.title')}}</h2>
      <b-card-text class="accept-text ">
        <div v-html="$t('ourServicesPage.servicesSection.text')"></div>
    </b-card-text>
    </b-card>
    <!-- <b-row class="mt-50"> -->
      <!-- <b-col  col lg="4" md="4" sm="12" xs="12" cols="12">
            <b-card :title="$t('ourServicesPage.serviceOne.title')" header-tag="nav" class="card-text card-design">
              <b-card-text class="accept-text">
                <div v-html="$t('ourServicesPage.serviceOne.description')"></div>
              </b-card-text>
            </b-card>
      </b-col>
      <b-col  col lg="4" md="4" sm="12" xs="12" cols="12">
            <b-card :title="$t('ourServicesPage.serviceTwo.title')" body-class="text-center" header-tag="nav" class="card-text card-design">
              <b-card-text class="accept-text">
                  <div v-html="$t('ourServicesPage.serviceTwo.description')"></div>
              </b-card-text>
            </b-card>
      </b-col>
        <b-col  col lg="4" md="4" sm="12" xs="12" cols="12">
            <b-card :title="$t('ourServicesPage.serviceThree.title')" body-class="text-center" header-tag="nav" class="card-text card-design">
              <b-card-text class="accept-text">
                  <div v-html="$t('ourServicesPage.serviceThree.description')"></div>
              </b-card-text>
            </b-card>
      </b-col> -->
 
        <!-- <b-col col lg="4" md="4" sm="12" cols="12" align="center" justify="center" class="home-card-service">
                  <div class="service-sub-card-img1">
<div class="home-sub-card ">
  <div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
<h3>{{$t('ourServicesPage.serviceOne.title' )}}</h3>
 <div v-html="$t('ourServicesPage.serviceOne.description')"></div>
    </div>
    <div class="flip-card-back">
      <h3>{{$t('ourServicesPage.serviceOne.title' )}}</h3>

      <div v-html="$t('ourServicesPage.serviceOne.descriptionFlip')"></div>
    </div>
  </div>
</div>
 </div>
                  </div>
                 
              </b-col>
              
  <b-col col lg="4" md="4" sm="12" cols="12" align="center" justify="center" class="home-card-service">
                  <div class="service-sub-card-img2">
<div class="home-sub-card ">
  <div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
<h3>{{$t('ourServicesPage.serviceTwo.title' )}}</h3>
 <div v-html="$t('ourServicesPage.serviceTwo.description')"></div>
    </div>
    <div class="flip-card-back">
      <h3>{{$t('ourServicesPage.serviceTwo.title' )}}</h3>

      <div v-html="$t('ourServicesPage.serviceTwo.descriptionFlip')"></div>
    </div>
  </div>
</div>
 </div>
                  </div>
                 
              </b-col>
               <b-col col lg="4" md="4" sm="12" cols="12" align="center" justify="center" class="home-card-service">
                  <div class="service-sub-card-img3">
<div class="home-sub-card ">
  <div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
<h3>{{$t('ourServicesPage.serviceThree.title' )}}</h3>
 <div v-html="$t('ourServicesPage.serviceThree.description')"></div>
    </div>
    <div class="flip-card-back">
      <h3>{{$t('ourServicesPage.serviceThree.title' )}}</h3>

      <div v-html="$t('ourServicesPage.serviceThree.descriptionFlip')"></div>
    </div>
  </div>
</div>
 </div>
                  </div>
                 
              </b-col>
    </b-row>
        </b-col> -->





<div  class="mt-50">
  <div :class="classServices">
  <div class="column">
    <a href="#">
      <div class="container-services" @mouseenter="servicesBg(3)">
        <div class="text-services">
          <div>
            <font-awesome-icon icon="ship" />
            <h3> WEMEX Service</h3>
          </div>
        </div>
        <!-- <img src="https://static1.sw-cdn.net/files/cms/tutorials/featured/most-popular/3d-print-checklist.jpg" class="image"> -->
        <div class="overlay">
          <div class="text-services-hover">
           <p> rotation is as follows:<br>
            Castellon (Spain) – Barcelona (Spain) – Misurata (Libya) – Sfax (Tunisia) – Casablanca (Morocco) – Castellon (Spain)
           <br> <br><b>Frequency:</b> bimonthly departure from each port.
<br><b>Vessels:</b> this service is performed by 01 modern and well equipped vessel.
<br><b>Nature of Service:</b> Direct service without transshipment.</p>
            </div>
        </div>
      </div>
    </a>
  </div>
  <div class="column">
    <a href="#">
      <div class="container-services" @mouseenter="servicesBg(2)">
        <div class="text-services">
          <div>
            <font-awesome-icon icon="plane" />
            <h3> FEMEX Service</h3>
          </div>
        </div>
        <!-- <img src="https://static1.sw-cdn.net/files/cms/tutorials/featured/most-popular/3d-print-checklist.jpg" class="image"> -->
        <div class="overlay">
          <div class="text-services-hover">
           <p> The second main service is “FEMEX” Service which is a newly implemented service meant basically to promote Turkey/Libya leg; through this service ARCOSHIP focused on (1) frequency, (2) regularity and (3) transit times through deploying M/V FATMA to exclusively run below rotation and provide therefore weekly departures, direct connections and the shortest transit times
              FEMEX service rotation is as follows:<br>
              Istanbul (Turkey) – Mersin (Turkey) – Misurata (Libya) – Istanbul (Turkey)


                        <br> <br><b>Frequency:</b>  bimonthly departure from each port.
              <br><b>Vessels:</b> this service is performed by 01 modern and well equipped vessel.
              <br><b>Nature of Service:</b> Direct service without transshipment.</p>
            </div>
        </div>
      </div>
    </a>
  </div>
  <div class="column">
    <a href="#">
      <div class="container-services" @mouseenter="servicesBg(1)">
        <div class="text-services">
          <div>
            <font-awesome-icon icon="train" />
            <h3> EMEX Service</h3>
          </div>
        </div>
        <!-- <img src="https://static1.sw-cdn.net/files/cms/tutorials/featured/most-popular/3d-print-checklist.jpg" class="image"> -->
        <div class="overlay">
          <div class="text-services-hover">
           <p> The third service “EMEX service” referring to East Med Express Service, was implemented in parallel with WEMEX service having a direct and regular calls from/to main Turkish ports: Istanbul, Izmir and Mersin, then extended to two main Italian ports: La Spezia and Salerno with a main call to Misurata as a discharging Hub and port of connection between the three services of ARCOSHIP : WEMEX, FEMEX and EMEX
        <br>    <br> Our rotation :<br>
Salerno (Italy) – La Spezia (Italy) - Misurata (Libya) – Istanbul (Turkey) – Izmir (Turkey) – Mersin (Turkey) – Misurata (Turkey) – Salerno (Italy)</p>
            </div>
        </div>
      </div>
    </a>
  </div>
  </div>
</div>



        
        <br>
    <div class="mt-50" v-html="$t('ourServicesPage.servicesSection.textAfter')"></div>
  </div>


</template>
<script lang="js">
export default {
  methods: {
    servicesBg(arg){
      if(arg==3 || arg == 1){
        this.classServices="bg-services-1 row"
      }else{
        this.classServices="bg-services-2 row"
      }
    }
  },
  data () {
    return {
      classServices:"bg-services-1 row"
    }
  },
  name: 'ServicesSection'
}


</script>
<style lang="css" scoped>
.services-section .card {
  border: none !important;
}
.services-section .card-title {
  text-align: center;
}
.services-section .card-text {
  text-align: justify;
}
.card-design {
  background: rgb(244, 244, 244);
  border-radius: 12px 12px 12px 12px;
  box-shadow: 10px 5px 5px rgb(223, 222, 220);
  /* padding: 1rem 1rem 2rem 1rem;
  width: 50%;
  margin-left: 35% !important;
  margin-top: 0.5% !important; */
}
/* .accept-text {
  padding: 0rem !important;
  text-align: left !important;
}
.card-text{
  width: 85%;
  margin-left: 10%;
}
@media only screen and (max-width: 768px) {
 .card-text{
  width: 100%!important;
  margin-left: 0%!important;
}
} */
/* .flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-back {
  transform: rotateY(180deg);
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.service-sub-card-img2 {
  background: url(../../assets/img/home2.jpeg);
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.service-sub-card-img1 {
  background: url(../../assets/img/home.png);
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.service-sub-card-img3 {
  background: url(../../assets/img/home3.jpeg);
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} */

/* new */

.bg-services-1 {
  height: 500px;
  background: url("../../assets/img/img-services/1.jpg");
  background-size: cover;
  background-position: center;
    transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-delay: 0.3s;
}
.bg-services-2 {
  height: 500px;
  background: url("../../assets/img/img-services/2.jpg");
  background-size: cover;
  background-position: center;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-delay: 0.3s;
}

.text-services {
  display: block;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 500px;
  transition: 0.5s ease;
  color: #fff;
      background-color: rgba(0, 0, 0, 0.171);

  }
.text-services svg {
  font-size: 40px;
  padding-bottom: 3%;
}
.container-services {
  position: relative;
  width: 100%;
  padding: 0% 2%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: black;
}
.container-services:hover .overlay {
  opacity: 0.7;
}
.container-services:hover .text-services {
  opacity: 0;
}

.text-services-hover {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}

.text-services-hover p {
  font-size: 15px;
  text-align: justify;
}

.column {
  flex: 100%;
  max-width: 100%;
}
/* Tablet Styles */
@media only screen and (min-width: 481px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Desktop Styles */
@media only screen and (min-width: 1024px) {
  .column {
    flex: 33.3%;
    max-width: 33.3%;
  }
}
</style>