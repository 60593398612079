<template>
    <vue-good-table
        styleClass="vgt-table bordered"
        theme="polar-bear"
        :line-numbers="true"
        :columns="columns"
        :rows="data"
        :search-options="{
            enabled: true,
            externalQuery: searchTerm
        }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        class="cargo-details mb-50"
    >
        <template
            slot="table-row"
            slot-scope="props"
        >
            <span v-if="props.column.field === 'description'">
                <p class="column-desc">{{ props.row.cargoDescription }}</p>
            </span>
        </template>
        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
        <div class="d-flex justify-content-between flex-wrap bottom-table-style">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
             {{$t('myQuickQuotePage.pagination.showing')}}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{$t('myQuickQuotePage.pagination.of')}} {{ props.total }} {{$t('myQuickQuotePage.pagination.entries')}} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <b-icon icon="chevron-compact-left"></b-icon>
              </template>
              <template #next-text>
                <b-icon icon="chevron-compact-right"></b-icon>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
export default {
    name: "CargoDetails",
    components: {
        VueGoodTable
    },
    props: {
        data: Array
    },
    data() {
        return {
            pageLength: 3,
            rows: [],
            searchTerm: '',
            columns: [
                {
                    width: "190px",
                    label: this.$t('newBLPage.cargoDetailsSection.numberOfPackages'),
                    field: 'packagesNumber',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.numberOfPackagesSearch'),
                    },
                },
                {
                    width: "170px",
                    label: this.$t('newBLPage.cargoDetailsSection.kindOfPackages'),
                    field: 'packagesKind',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.kindOfPackagesSearch'),
                    },
                },
                {
                    width: "400px",
                    label: this.$t('newBLPage.cargoDetailsSection.cargoDescription'),
                    field: 'description',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.cargoDescriptionSearch'),
                    },
                },
                {
                    width: "130px",
                    label: this.$t('newBLPage.cargoDetailsSection.hsCode'),
                    field: 'HSCode',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.hsCodeSearch'),
                    },
                },
                {
                    width: "210px",
                    label: this.$t('newBLPage.cargoDetailsSection.weightOfMerchandise'),
                    field: 'merchandiseWeight',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.weightOfMerchandiseSearch'),
                    },
                },
                {
                    width: "130px",
                    label: this.$t('newBLPage.cargoDetailsSection.sealNumber'),
                    field: 'sealNumber',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.sealNumberSearch'),
                    },
                },
                {
                    width: "150px",
                    label: this.$t('newBLPage.cargoDetailsSection.containersListe'),
                    field: 'container',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.containersListeSearch'),
                    },
                },
                {
                    width: "180px",
                    label: this.$t('newBLPage.cargoDetailsSection.containerQuantity'),
                    field: 'quantity',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.containerQuantitySearch'),
                    },
                },
                {
                    width: "170px",
                    label: this.$t('newBLPage.cargoDetailsSection.containerNumber'),
                    field: 'containerNumber',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.cargoDetailsSection.containerNumberSearch'),
                    },
                }
            ]
        }
    }
}
</script>

<style>
.cargo-details .vgt-table.polar-bear td {
  padding: .60em 0 .60em 0 !important;
  text-align: center;
  vertical-align: middle !important;
}
.column-desc {
    padding-left: 10px;
    text-align: left !important;
    max-height: 120px;
    overflow-y: auto;
    margin-bottom : 0 !important
}
</style>