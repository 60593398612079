<template lang="html">
    <div>
        <Hero imgLink="/img/port.jpg"/>
        <SignUpForm />
         
    </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import SignUpForm from "../components/SignUp/SignUpForm.vue";

export default {
  name: "SignUp",
  components: {
    Hero,
    SignUpForm,
  },
  methods: {},
};
</script>
<style lang="css"></style>
