var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-good-table',{staticClass:"cargo-details mb-50",attrs:{"styleClass":"vgt-table bordered","theme":"polar-bear","line-numbers":true,"columns":_vm.columns,"rows":_vm.data,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm
    },"pagination-options":{
        enabled: true,
        perPage:_vm.pageLength
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'description')?_c('span',[_c('p',{staticClass:"column-desc"},[_vm._v(_vm._s(props.row.cargoDescription))])]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap bottom-table-style"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('myQuickQuotePage.pagination.showing'))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3','5','10']},on:{"input":(value)=>props.perPageChanged({currentPerPage:value})},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('myQuickQuotePage.pagination.of'))+" "+_vm._s(props.total)+" "+_vm._s(_vm.$t('myQuickQuotePage.pagination.entries'))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":(value)=>props.pageChanged({currentPage:value})},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('b-icon',{attrs:{"icon":"chevron-compact-left"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('b-icon',{attrs:{"icon":"chevron-compact-right"}})]},proxy:true}],null,true)})],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }