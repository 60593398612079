<template lang="html">
  <div>
    <div class="agency-map-bg">
        <div class="agency-map-content">
            <b-row >
               <!-- <iframe 
               src="https://www.google.com/maps/d/u/0/embed?mid=1-390vaW1sxcsNnyAcLWVhvPnUhjX05NC&ehbc=2E312F">
               </iframe> -->

<div class="distribution-map">
 <img src="../../assets/img/téléchargement.png">
    <button class="map-point" style="top:42%;left:49.8%">
        <div class="content">
            <div class="centered-y">
                  <h2 class="map-network-title">ARCOSHIP LINES Rades Aegncy (Tunisia)  </h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font">  Rue d’Athènes Rades - Tunisia port area   </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> 00216 71 469 082</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                                  <b-icon icon="printer-fill"></b-icon>
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> 00216 71 46 97 60</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">    rades@arcoship.com   </span> 
                      </b-col>
                    </b-row>

            </div>
        </div>
    </button>
     <button class="map-point" style="top:40%;left:54%">
        <div class="content">
            <div class="centered-y">
                <!-- <h2>ARCOSHIP LINES Istanbul Agency (Turkey) </h2>
                <p> Acibadem mah. çeçen SOK. akasya acibadem evleri kent etabi A1 kule K:24 D:124-134 ÜSKÜDAR / İSTANBUL – </p> -->
               <h2 class="map-network-title">ARCOSHIP LINES Head Office</h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font"> Acibadem mah. çeçen SOK. akasya acibadem evleri kent etabi A1 kule K:24 D:124-134 ÜSKÜDAR / İSTANBUL </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> +9085 05 229 854</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">   abs@arcoship.com  </span> 
                      </b-col>
                    </b-row>

            </div>
        </div>
    </button>
    <button class="map-point" style="top:40%;left:53%">
        <div class="content">
            <div class="centered-y">
                <!-- <h2>ARCOSHIP LINES Istanbul Agency (Turkey) </h2>
                <p> Acibadem mah. çeçen SOK. akasya acibadem evleri kent etabi A1 kule K:24 D:124-134 ÜSKÜDAR / İSTANBUL – </p> -->
               <h2 class="map-network-title">ARCOSHIP LINES Izmir Agency (Turkey)</h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font">  Limonluk Mah. 2410 Sok. Semt Yeniköy Ofis 1 Sitesi Bina No:6 Kat:8 No:55 Yenişehir / MERSİN/TÜRKİYE </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> +90 216 759 81 03</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">  Turkey@arcoship.com  </span> 
                      </b-col>
                    </b-row>

            </div>
        </div>
    </button>
    <button class="map-point" style="top:43%;left:54.5%">
        <div class="content">
            <div class="centered-y">
                <h2 class="map-network-title">ARCOSHIP LINES Mersin Agency (Turkey) </h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font"> Limonluk Mah. 2410 Sok. Semt Yeniköy Ofis 1 Sitesi Bina No:6 Kat:8 No:55 Yenişehir / MERSİN/TÜRKİYE  </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> +90 216 759 81 03</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">  Turkey@arcoship.com  </span> 
                      </b-col>
                    </b-row>

            </div>
        </div>
    </button>
    <button class="map-point" style="top:40%;left:48%">
        <div class="content">
            <div class="centered-y">

                <h2 class="map-network-title">ARCOSHIP LINES Castellon Agency (Spain)  </h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font"> C / REINA 6 PUERTA 2 46011 Valencia - Spain –   </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> 34 96 110 88 00</span> 
                      </b-col>
 <b-col col lg="1" md="1" sm="1" cols="1">
                                  <b-icon icon="printer-fill"></b-icon>
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">34 961 108 830</span> 
                      </b-col>
                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">    castellon@arcoship.com  </span> 
                      </b-col>
                    </b-row>

            </div>
        </div>
    </button>
    <button class="map-point" style="top:47%;left:50%">
        <div class="content">
            <div class="centered-y">
              <h2 class="map-network-title">ARCOSHIP LINES Sfax Agency (Tunisia)  </h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font"> Imm Kyranis, App 1 - 4th floor Ali Bach Hamba Street.3000 Sfax  </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> +9085 05 229 854</span> 
                      </b-col>
 <b-col col lg="1" md="1" sm="1" cols="1">
                                  <b-icon icon="printer-fill"></b-icon>
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> 00216 71 46 97 60</span> 
                      </b-col>
                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">    sfax@arcoship.com    </span> 
                      </b-col>
                    </b-row>
            </div>
        </div>
    </button>
    <button class="map-point" style="top:44%;left:48%">
        <div class="content">
            <div class="centered-y">
                 <h2 class="map-network-title">ARCOSHIP LINES Barcelona Agency (Spain)   </h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font">  Avda. De Roma 36-40, Mezzanine 2ª Staircase A 08015 Barcelona  </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> 34 672 01 56 04</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">    barcelona@arcoship.com    </span> 
                      </b-col>
                    </b-row>
            </div>
        </div>
    </button>

    <button class="map-point" style="top:47%;left:46%">
        <div class="content">
            <div class="centered-y">
                 <h2 class="map-network-title">ARCOSHIP LINES Casablanca Agency (Morocco)  </h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font">  137, bd Moulya Ismail Roches Noires - Casablanca 20300  </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> 00 212 5222 433 86 / 89 / 92</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                                  <b-icon icon="printer-fill"></b-icon>
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> 00 212 5222 433 85</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">   casablanca@arcoship.com  </span> 
                      </b-col>
                    </b-row>
            </div>
        </div>
    </button>


    <button class="map-point" style="top:48%;left:51.5%">
        <div class="content">
            <div class="centered-y">
                 <h2 class="map-network-title">ARCOSHIP LINES Misurata Agency (Libya)  </h2>
                 <b-row class="partners-row">
                      <b-col col lg="1" md="1" sm="1" cols="1">
                            <font-awesome-icon icon="map-marker-alt" />
                      </b-col>
                      <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                          <span class="map-network-font"> Street Sana El Mhimdi - Misurata    </span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="phone" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font"> 00216 27682787 / 00218912117640</span> 
                      </b-col>

                      <b-col col lg="1" md="1" sm="1" cols="1">
                      <font-awesome-icon icon="envelope" />
                      </b-col>
                        <b-col col lg="11" md="11" sm="11" cols="11">
                          <span class="map-network-font">   misurata@arcoship.com  </span> 
                      </b-col>
                    </b-row>
            </div>
        </div>
    </button>


</div>


            </b-row>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurAgencyMap",
  components: {},
  data() {
    return {
      // maps: [
      //   {
      //     name: "Tunis",
      //     localisation: "Rue d'Athènes. ZP Rades 2040",
      //     phone: "00216 71 469 082",
      //     mail: "md@mtlfeeder.com",
      //   },
      //   {
      //     name: "Sfax",
      //     localisation: "Adresse: Imm Kyranis, App 1 - 4 eme etage Rue Ali Bach Hamba.3000 Sfax",
      //     phone: "+00216 74 20 34 20",
      //     mail: "Line@mtlfeeder.com",
      //   },
      //   {
      //     name: "Spain",
      //     localisation: "REINA Nº 6 PTA 2 46011 Valencia",
      //     phone: "34 96 110 88 00",
      //     mail: "comercialcastellon@mtlfeeder.es",
      //   },
      //     {
      //     name: "Spain",
      //     localisation: "Consell de Cent 106-108 , 4º 1ª 08015 Barcelona ",
      //     phone: "34 932 221 700",
      //     mail: "comercialbcn@mtlfeeder.es",
      //   },
      //    {
      //     name: "Maroc",
      //     localisation: "137, bd Moulya Ismail Roches Noires - Casablanca 20300 ",
      //     phone: "00 212 5222 433 86",
      //     mail: "mtl@mtlmaroc.ma",
      //   },
      //    {
      //     name: "Libya",
      //     localisation: "Tripoli Tower, 2nd floor office33",
      //     phone: "+218910112090",
      //     mail: "acc@artranslyb.com",
      //   },
      //  {
      //     name: "Libya",
      //     localisation: "Street Sana El Mhimdi – Misurata",
      //     phone: "0021627682787",
      //     mail: "acc@artranslyb.com",
      //   },
      //   {
      //     name: "Libya",
      //     localisation: "Adress: Street Sana El Mhimdi – Misurata ",
      //     phone: "00216 27682787",
      //     mail: "acc@artranslyb.com",
      //   },
      // ],
      // name: "",
      // phone: "",
      // mail: "",
      // name: "",
      // clicked0: false,
      // clicked1: false,
      // clicked2: false,
    };
  },
  computed: {},
  // created() {
  //   this.changeLocalisation(0);
  // },

  // methods: {
  //   changeLocalisation: function (indice) {
  //     this.localisation = this.maps[indice].localisation;
  //     this.name = this.maps[indice].name;
  //     this.mail = this.maps[indice].mail;
  //     this.phone = this.maps[indice].phone;
  //     if (indice == 0) {
  //           this.clicked0 = true;
  //       this.clicked1 = false;
  //       this.clicked2 = false;
  //       this.clicked3 = false;
  //       this.clicked4 = false;
  //       this.clicked5 = false;
  //       this.clicked6 = false;
  //       this.clicked7 = false;
  //       this.clicked8 = false;
  //     } else if (indice == 1) {
  //          this.clicked0 = false;
  //       this.clicked1 = true;
  //       this.clicked2 = false;
  //       this.clicked3 = false;
  //       this.clicked4 = false;
  //       this.clicked5 = false;
  //       this.clicked6 = false;
  //       this.clicked7 = false;
  //       this.clicked8 = false;
  //     } else if (indice == 2) {
  //            this.clicked0 = false;
  //       this.clicked1 = false;
  //       this.clicked2 = true;
  //       this.clicked3 = false;
  //       this.clicked4 = false;
  //       this.clicked5 = false;
  //       this.clicked6 = false;
  //       this.clicked7 = false;
  //       this.clicked8 = false;
  //     }else if (indice == 3) {
  //       this.clicked0 = false;
  //       this.clicked1 = false;
  //       this.clicked2 = false;
  //       this.clicked3 = true;
  //       this.clicked4 = false;
  //       this.clicked5 = false;
  //       this.clicked6 = false;
  //       this.clicked7 = false;
  //       this.clicked8 = false;

  //     }else if (indice == 4) {
  //       this.clicked0 = false;
  //       this.clicked1 = false;
  //       this.clicked2 = false;
  //       this.clicked3 = false;
  //       this.clicked4 = true;
  //       this.clicked5 = false;
  //       this.clicked6 = false;
  //       this.clicked7 = false;
  //       this.clicked8 = false;

  //     }
  //     else if (indice == 5) {
  //       this.clicked0 = false;
  //       this.clicked1 = false;
  //       this.clicked2 = false;
  //       this.clicked3 = false;
  //       this.clicked4 = false;
  //       this.clicked5 = true;
  //       this.clicked6 = false;
  //       this.clicked7 = false;
  //       this.clicked8 = false;

  //     }
  //     else if (indice == 6) {
  //       this.clicked0 = false;
  //       this.clicked1 = false;
  //       this.clicked2 = false;
  //       this.clicked3 = false;
  //       this.clicked4 = false;
  //       this.clicked5 = false;
  //       this.clicked6 = true;
  //       this.clicked7 = false;
  //       this.clicked8 = false;

  //     }
  //     else if (indice == 7) {
  //       this.clicked0 = false;
  //       this.clicked1 = false;
  //       this.clicked2 = false;
  //       this.clicked3 = false;
  //       this.clicked4 = false;
  //       this.clicked5 = false;
  //       this.clicked6 = false;
  //       this.clicked7 = true;
  //       this.clicked8 = false;

  //     }
  //     if (indice == 8) {
  //       this.clicked0 = false;
  //       this.clicked1 = false;
  //       this.clicked2 = false;
  //       this.clicked3 = false;
  //       this.clicked4 = false;
  //       this.clicked5 = false;
  //       this.clicked6 = false;
  //       this.clicked7 = false;
  //       this.clicked8 = true;

  //     }
  //   },
  // },
};
</script>
<style lang="css">
.agency-map-bg {
  background: linear-gradient(99.27deg, #082139 -5.24%, #082139 106.47%);
  /* background: rgba(13, 6, 39, 0.5); */
}
.agency-map-content {
  /* background: url(../../assets/img/world.png);
  background-position: center;
  background-repeat: repeat-x; */
  /* padding: 4.5rem 2rem; */
  /* background: url(../../assets/img/world.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  /* padding: 2.5rem 4rem; */
}
/* .agency-map-card-tracking {
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1rem 1rem 2rem 1rem;
  width: 50%;
  margin-left: 35% !important;
}
.agency-map-content .agency-map-card-tracking p {
  font-size: 18px;
  color: #fff;
  width: 80%;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.agency-map-content .agency-map-card-tracking h2 {
  margin-bottom: 1.5rem;
}
.agency-map-icon {
  color: #FFF;
  font-size: 1rem;
}
.agency-map-icon0-yellow {
  color: #F2AE65;
  font-size: 50px;
  margin-left: 60% !important;
  margin-top: 200% !important;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.agency-map-icon1-yellow {
  color: #F2AE65;
  font-size: 50px;
  margin-left: 10% !important;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.agency-map-icon2-yellow {
  color: #F2AE65;
  font-size: 50px;
  margin-left: 20% !important;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.agency-map-icon0-white {
  color: white;
  font-size: 30px;
  margin-left: 60% !important;
  margin-top: 230% !important;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.agency-map-icon1-white {
  color: white;
  font-size: 30px;
  margin-left: 10% !important;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.agency-map-icon2-white {
  color: white;
  font-size: 30px;
  margin-left: 20% !important;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.agency-map-card-tracking .row {
  margin-left: 0px !important;
}
.agency-map-card-tracking .row div {
  margin-bottom: 0.8rem !important;
}
.agency-icon {
  display: inline;
  width: 100%;
  margin-right: 0.125rem;
} */


/* @media only screen and (max-width: 1067px) {
  .agency-map-card-tracking {
    width: 80% !important;
    margin-left: 10% !important;
  }
}
.agency-map-content iframe{
  width: 100%;
  height: 500px;
}
.agency-map-content .i4ewOd-pzNkMb-haAclf{
  opacity: 0!important;
} */




.distribution-map div, .distribution-map img {
  position: relative;
  box-sizing: border-box;
}

.distribution-map .map-network-title {
  font-size: 1.2rem;
}
.map-network-font{
    font-size: 1rem;
}
.centered-y {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.distribution-map {
  position: relative;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}
.distribution-map > img {
  width: 100%;
  position: relative;
  /* margin: 0; */
  padding: 0;
}
.distribution-map .map-point {
  cursor: pointer;
  outline: none;
  z-index: 0;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  transform: translate(-50%, -50%);
  -moz-transition: opacity 0.25s ease-in-out 0.25s, width 0.25s ease-in-out 0.25s, height 0.25s ease-in-out 0.25s, z-index 0.25s ease-in-out 0.25s;
  -o-transition: opacity 0.25s ease-in-out 0.25s, width 0.25s ease-in-out 0.25s, height 0.25s ease-in-out 0.25s, z-index 0.25s ease-in-out 0.25s;
  -webkit-transition: opacity 0.25s ease-in-out, width 0.25s ease-in-out, height 0.25s ease-in-out, z-index 0.25s ease-in-out;
  -webkit-transition-delay: 0.25s, 0.25s, 0.25s, 0.25s;
  transition: opacity 0.25s ease-in-out 0.25s, width 0.25s ease-in-out 0.25s, height 0.25s ease-in-out 0.25s, z-index 0.25s ease-in-out 0.25s;
  background: rgba(26, 26, 26, 0.85);
  border: 5px solid #fff;
}
.distribution-map .map-point .content {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  overflow: overlay;
}
.distribution-map .map-point:active, .distribution-map .map-point:focus {
  margin: 0;
  padding: 10px;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  width: 350px;
  height: 270px;
  color: #e5e5e5;
  z-index: 1;
  transition: opacity 0.25s ease-in-out, width 0.25s ease-in-out, height 0.25s ease-in-out;
}
.distribution-map .map-point:active .content, .distribution-map .map-point:focus .content {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  -moz-transition: opacity 0.25s ease-in-out 0.25s, height 0.25s ease-in-out, overflow 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out 0.25s, height 0.25s ease-in-out, overflow 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out, height 0.25s ease-in-out, overflow 0.25s ease-in-out;
  -webkit-transition-delay: 0.25s, 0s, 0s;
  transition: opacity 0.25s ease-in-out 0.25s, height 0.25s ease-in-out, overflow 0.25s ease-in-out;
  overflow: hidden;
}
.distribution-map .map-point:active .content a:hover, .distribution-map .map-point:active .content a:active, .distribution-map .map-point:focus .content a:hover, .distribution-map .map-point:focus .content a:active {
  color: #afe1fa;
}
@media only screen and (max-width: 908px) {
  .distribution-map > img {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .agency-map-bg {
    display: none;
  }
}
</style>