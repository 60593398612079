
<template  >
<!-- Footer -->
<footer class="footer text-white">
  <!-- Grid container -->
  <div class="container primary-padding border-divider">

    <!-- Section: Links -->
    <section class="">
      <!--Grid row-->
      <b-row>
        <!--Grid column-->
        <b-col col lg="3" md="3" sm="12" cols="12">
          <div class="bg-image hover-overlay ripple shadow-1-strong rounded text-left" data-ripple-color="light">
            <img src="../assets/img/logo_gold.png" class="logo-footer mb-25" />
            <a href="#!">
              <div class="mask" style="background-color: rgba(251, 251, 251, 0.2);"></div>
            </a>
          </div>
          <div>
            <p class="p-white-footer text-left">
               {{$t('footer.description')}}
            </p>
          </div>
        </b-col>
        <!--Grid column-->

        <!--Grid column-->
        <b-col col lg="3" md="3" sm="12" cols="12">
          <ul class="list-unstyled mb-0 text-left pl-150 responsive-footer">
            <!-- <li>
              <router-link to="/about-us" class="text-white">{{$t('nav.aboutUs.aboutUs')}}</router-link>
            </li> -->
            <li>
              <router-link to="/ceo" class="text-white">{{$t('nav.aboutUs.ceoMessage')}}</router-link>
            </li>
            <li>
              <router-link to="/our-fleet" class="text-white">{{$t('nav.aboutUs.ourFleet')}}</router-link>
            </li>
            <li>
              <router-link to="/our-services" class="text-white">{{$t('nav.aboutUs.ourServices')}}</router-link>
            </li>
             <li>
              <router-link to="/our-solution" class="text-white">{{$t('nav.aboutUs.ourSolutions')}}</router-link>
            </li>
          </ul>
        </b-col>
        <!--Grid column-->

        <!--Grid column-->
        <b-col col lg="3" md="3" sm="12" cols="12">
          <ul class="list-unstyled mb-0 text-left pl-150 responsive-footer">
            <li>
              <router-link to="/tracking" class="text-white">{{$t('footer.links.tracking')}}</router-link>
            </li>
            <li>
              <router-link to="/scheduling" class="text-white">{{$t('nav.schedule')}}</router-link>
            </li>
            <li>
              <router-link to="/accept-term-condition" class="text-white">{{$t('footer.links.termAndConditions')}}</router-link>
            </li>
            <li>
              <router-link to="/contact" class="text-white">{{$t('footer.links.contactUs')}}</router-link>
            </li>
          </ul>
        </b-col>

         <b-col col lg="3" md="3" sm="12" cols="12">
          <ul class="list-unstyled mb-0 text-left pl-150 responsive-footer">
            <li>
       <a href="https://www.linkedin.com/company/arcoshiplines/" class="text-white align-self-center" target="_blank">LinkedIn</a>
                </li> <li>
        <a href="https://www.facebook.com/arcoship" class="text-white align-self-center" target="_blank">Facebook</a>
            </li>
            <li>
        <a href="https://www.instagram.com/arcoship.lines/" class="text-white align-self-center" target="_blank">Instragram</a>            
        </li>
          <li>
        <a href="https://twitter.com/arcoship" class="text-white align-self-center" target="_blank">Twitter</a>            
        </li>
          </ul>
        </b-col>
        <!--Grid column-->
      </b-row>
      <!--Grid row-->
    </section>
    <!-- Section: Links -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <b-row class="footer-padding">
    <!--Grid column-->

    <b-col col lg="6" md="6" sm="12" xs="12">
      <div class="p-3">
       {{$t('footer.developper')}}
        <a class="text-white" href="">New Technologies</a>
      </div>
    </b-col>

    <b-col col lg="6" md="6" sm="12" xs="12">
      <div class="text-center p-3 social-media-links">
        <router-link to="" href="https://www.linkedin.com/in/arcoship/" class="text-white align-self-center" target="_blank">LinkedIn</router-link>
        <router-link to="" href="https://www.facebook.com/arcoship" class="text-white align-self-center" target="_blank">Facebook</router-link>
        <router-link to="" href="https://www.instagram.com/arcoship/" class="text-white align-self-center" target="_blank">Instragram</router-link>
      </div>
    </b-col>

  </b-row>

  <!-- Copyright -->
</footer>
<!-- Footer -->
</template>
<script >
export default {
  name: "CommonFooter",
};
</script>
<style lang="css">
.footer {
  position: relative;
  bottom: 0 !important;
  width: 100% !important;
  background-color: #082139 !important;
}
.container {
  max-width: 100% !important;
}
/* .h-divider {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  border-top: 2px solid #e9e9e9;
} */
.social-media-links a{
  padding-left:25px
}
.border-divider{
 border-bottom: 0.075rem solid #e9e9e9!important;
}
.bottom-links {
  height: 100% !important;
}
div.links-section,
div.links {
  height: 30vh !important;
}
.copyright {
  height: 100% !important;
}
.bottom-text {
  min-height: 5vh !important;
  font-size: 1.5rem;
}
.top-text {
  min-height: 20hv !important;
}
.p-white-footer{
    font-size: 1rem;
  color: #fff;
}
/* li {
  line-height: 5vh;
  font-size: 2rem;
} */
@media only screen and (max-width: 768px) {
    .footer .responsive-footer{
      padding-left: 0!important
    }
}
@media all and (max-device-width: 720px) {
  /* li {
    line-height: 5vh;
    font-size: 1.5rem;
  }
  .bottom-text {
    min-height: 100px !important;
    font-size: 1rem;
  } */
}
.footer-padding{
    padding: 0.5rem 3rem;
}

@media all and (max-device-width: 640px) {
  li {
    line-height: 5vh;
    font-size: 18px;
  }
  /* .bottom-text {
    min-height: 100px !important;
    font-size: 1rem;
  } */
}

/* @media all and (max-device-width: 320px) {
  li {
    line-height: 5vh;
    font-size: 1.3rem;
  }
  .bottom-text {
    min-height: 100px !important;
    font-size: 1rem;
  }
} */
</style>