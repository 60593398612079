
<template lang="html">

<nav>
  <div :class="getClass()">
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand to="/"><img src="../assets/img/CCL.png"> </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav avbar-nav class="ml-auto">

          <b-nav-item to="/"> {{$t('nav.home')}}</b-nav-item>

          <b-nav-item-dropdown :text="$t('nav.aboutUsTitle')" right>
            <b-dropdown-item to="/ceo">{{$t('nav.aboutUs.ceoMessage')}}</b-dropdown-item>
            <b-dropdown-item to="/about-us">{{$t('nav.aboutUs.aboutUs')}}</b-dropdown-item><b-dropdown-item to="/our-agency">{{$t('nav.aboutUs.ourAgencies')}}</b-dropdown-item>
            <b-dropdown-item to="/our-services">{{$t('nav.aboutUs.ourServices')}}</b-dropdown-item>
            <b-dropdown-item to="/our-solution">{{$t('nav.aboutUs.ourSolutions')}}</b-dropdown-item>
            <b-dropdown-item to="/our-fleet">{{$t('nav.aboutUs.ourFleet')}}</b-dropdown-item>
          </b-nav-item-dropdown>

            <b-nav-item-dropdown :text="$t('nav.quotationTitle')" right>
              <b-dropdown-item to="/quotation">{{$t('nav.quotation.eQuote')}}</b-dropdown-item>
              <b-dropdown-item to="/new-instant">{{$t('nav.quotation.quickQuote')}}</b-dropdown-item>
            </b-nav-item-dropdown>

          <b-nav-item>
            <router-link to="/tracking">{{$t('nav.tracking')}}</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/scheduling">{{$t('nav.schedule')}}</router-link>
          </b-nav-item>
           <b-nav-item>
            <router-link to="/contact">Contact</router-link>
          </b-nav-item>
          
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto nav-bar-button">

         <b-nav-item-dropdown :text="langValue" right class="lang">
            <b-dropdown-item class="btn-lang" @click="changeLanguage('fr')">{{$t('nav.languages.fr')}}</b-dropdown-item>
             <b-dropdown-item class="btn-lang" @click="changeLanguage('en')">{{$t('nav.languages.en')}}</b-dropdown-item>
        </b-nav-item-dropdown>

          <b-button class="button-user-login">
            <div v-if="user != '' ">
             <router-link :to="getLink()"> <font-awesome-icon icon="user" /> 
              {{ user }}</router-link>
            </div>
            <div v-else>
               <router-link :to="getLink()"><font-awesome-icon icon="user" />
             {{$t('nav.loginBtn')}}</router-link>
            </div>
          </b-button>


        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>

</nav>
</template>
<script>
import axios from "axios";
export default {
  name: "CommonNavbar",
  data() {
    return {
      langValue:localStorage.getItem('lang') || "En",
      // active: "guide",
      // valueSelect2: '1',
      // valueSelect1: '1',
          activeItem: 0,
          user: "",
    };
  },
  computed: {
  },
  async created() {
    await this.getAllInformation();
  },
  methods: {
    getClass(){
      if ( (this.$route.name === 'Tracking') || (this.$route.name === 'Quotation') || (this.$route.name === 'Information') || (this.$route.name === 'InstantQuotation') || (this.$route.name === 'Booking') || (this.$route.name === 'Scheduling') || (this.$route.name === 'MyQuote') || (this.$route.name === 'MyQuickQuote') || (this.$route.name === 'createBooking') || (this.$route.name === 'BL') || (this.$route.name === 'MyBL') || (this.$route.name === 'QuotationEdit') || (this.$route.name === 'EditBooking') || (this.$route.name === 'EditBL')
      || (this.$route.name === 'ViewInstantQuotation') || (this.$route.name === 'ViewQuote'))
      {
            return 'nav-bar-style navbar-bleu'
      } else{
            return 'nav-bar-style navbar-transparent'
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      this.langValue= lang
      localStorage.setItem('lang', lang)
    },
    getLink(){
      if(!localStorage.getItem("userData")){
          return "/login";
      }else{
           return "/information";
      }
    },
    async getAllInformation() {
         var idUser=JSON.parse(localStorage.getItem("userData"))
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/customers?id=" + idUser.user.customer._id
      );     
        //console.log(response.data.data[0]);
        this.user= response.data.data[0].firstName + " " + response.data.data[0].lastName;
        //console.log(this.user);
    },
  },
};
</script>
<style lang="css">

a, a:hover, a:focus, a:active {
      text-decoration: none !important;
      color: inherit !important; 
}
.nav-bar-style img{
    max-height: 80.5px;
}
.nav-bar-style .nav-link{
      font-family: Roboto;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff!important;
    padding: 0rem 1rem!important;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #082139!important;
}
.dropdown-item:hover, .dropdown-item:focus {
    color: #fff!important;
    text-decoration: none;
  background-color: #082139!important;
}

.nav-bar-style .navbar{
  /* background: transparent !important; */
  position: absolute!important;
  width: 100%;
  padding: 2.5rem 4rem;
}
  .navbar-bleu .navbar{
  background: #082139 !important;
  }
  .navbar-transparent .navbar{
  background: transparent !important;
  }
/* button */
.navbar-bleu  .nav-bar-button button{
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  color: #082139;
  background: #F2AE65;
  border: 0;
  padding: 0.375rem 1rem;
}
.navbar-transparent  .nav-bar-button button{
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  background: #082139;
  border: 0;
  padding: 0.375rem 1rem;
}
.lang {
  text-transform: uppercase
}
/* .btn-secondary:not(:disabled):not(.disabled):active:focus{
    color: #fff;
    background-color: #082139!important;
    border-color: #082139!important;
}
.btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #082139!important;
    border-color: #082139!important;
}
.btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #082139!important;
    border-color: #082139!important;
    box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
}
.btn-secondary:hover {
    color: #fff;
    background-color: #082139!important;
    border-color: #082139!important;
}
.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
} */
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
  color: #F2AE65!important;
}
.nav-bar-button svg{
  margin-right: 8px!important;
}
.navbar .navbar-collapse{
    padding-left: 1rem;
  }
  .nav-bar-button li{
    display: flex;
    align-items: center;
    padding-right: 1rem;
}
.btn-lang-selected{
  width:100%;
  background-color:#082139;
  color:#fff
}
.btn-lang{
  width:100%;
  background-color:#fff;
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
}
.btn-lang:hover{
  width:100%;
  background-color:#082139;
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
}
/* *********************************************************************************************************** */
/* responsive */

@media only screen and (max-width: 991px) {
  .nav-bar-button li{
    display: none;
   }
   .navbar .navbar-collapse{
    padding-left: 0rem;
  }
  .navbar .navbar-collapse{
    background:#fff!important;
    z-index:9999
  }
  .navbar .navbar-collapse .nav-link{
    color:#082139!important
  }
   .button-user-login{
  margin: 2%;
}
}

@media only screen and (max-width: 420px) {
  .navbar .navbar-collapse{
    background:#fff!important;
    z-index:9999
  }
  .nav-bar-style .navbar{
    padding: 1.25rem 2rem;
  }
}

</style>