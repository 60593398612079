<template lang="html">
    <div>
         <Hero imgLink="/img/istockphoto.jpg"/>
         <div>
          <div class="second-padding">
              <b-row  class="">
              <b-col col lg="6" md="6" sm="12" cols="12">
                <div class="agency-card-text-left">
                  <h2> {{$t('ourAgenciesPage.SectionOne.title')}}</h2>
                    <p class="text-justify" v-html="$t('ourAgenciesPage.SectionOne.description')"></p>
                  </div>
              </b-col>
              <b-col col lg="6" md="6" sm="12" cols="12" class="img-agency">
                <div class="agency-img">
                  <img src="../assets/img/home.jpeg" alt="">
                </div>
              </b-col>
            </b-row>
            <!-- <b-row  class="mt-100">
              <b-col col lg="6" md="6" sm="12" cols="12" class="img-agency">
                <div class="agency-img">
                  <img src="../assets/img/home.jpeg" alt="">
                </div>
              </b-col>
              <b-col col lg="6" md="6" sm="12" cols="12">
                <div class="agency-card-text-right">
                  <h2>{{$t('ourAgenciesPage.SectionTwo.title')}}</h2>
                    <p class="text-justify" v-html="$t('ourAgenciesPage.SectionTwo.description')"></p>
                  </div>
              </b-col>
            </b-row> -->
          </div>
        </div>
         <OurAgencyMap />
         <Partners/>
    </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import Partners from "../components/Partners.vue";
import OurAgencyMap from "../components/OurAgency/OurAgencyMap.vue";

export default {
  name: "OurAgency",
  components: {
    Hero,
    Partners,
    OurAgencyMap,
  },
  methods: {},
};
</script>
<style lang="css">
/* .agency-content {
  padding: 5rem 4rem;
} */
.agency-content-h2 {
  font-size: 35px;
  color: #082139;
}
.agency-content-p {
  font-size: 18px;
  color: #082139;
  width: 70%;
}
/* img */
.agency-img img {
  width: 95%;
  height: 78%;
}
.agency-card-text-right {
  margin-left: 5% !important;
}
.agency-card-text-left {
  margin-right: 7% !important;
}
.agency-card-text-left h2 {
  font-size: 35px;
  color: #082139;
  font-weight: bolder;
}
.agency-card-text-left p {
  font-size: 20px;
  color: #212529;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.agency-card-text-right h2 {
  font-size: 35px;
  color: #082139;
  font-weight: bolder;
}
.agency-card-text-right p {
  font-size: 20px;
  color: #212529;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 768px) {
  .img-agency {
    display: none;
  }
}
</style>