<template lang="html">
  <b-container fluid class="contact-section">
    <b-row class="contact-row">
        <b-col class="contact-form" lg="8" md="8" offset-md="2" offset-lg="2" offset-sm="2" sm="8">
        <b-row>
           <b-col  col lg="12" md="12" sm="12" xs="12" ><h2>{{$t('contactUsPage.contactForm.title')}}</h2></b-col>
        </b-row>
          <b-form @submit.stop.prevent="Submit()">
        <b-container>
         <b-row class="contact-input">
              <b-col col lg="6" md="6" sm="12" cols="12" class="contact-input-1">
                <input v-model="form.firstName" type="text" :placeholder="$t('contactUsPage.contactForm.placeholderFirstName')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
              <b-col col lg="6" md="6" sm="12" cols="12" class="contact-input-2">
                <input v-model="form.lastName" type="text" :placeholder="$t('contactUsPage.contactForm.placeholderLastName')"  required class="input-outline-yellow input-color-white"></input>
              </b-col>
            </b-row>
          <b-row>
            <input v-model="form.email" type="email" :placeholder="$t('contactUsPage.contactForm.placeholderEmail')"  required class="input-outline-yellow input-color-white"></input>
          </b-row>
           <!-- <b-row>
            <input v-model="form.phoneNumber" type="number" :placeholder="$t('contactUsPage.contactForm.placeholderNumber')"  required class="input-outline-yellow input-color-white"></input>
          </b-row> -->
           <b-row>
              <b-col lg="4">

               <v-select v-model="form.phonePrefix" :options="info" :reduce="info => info.dial_code" label="dial_code" 
                  class="input-outline-white-border-select"/>
              </b-col>
              <b-col lg="8">
                <input v-model="form.phoneNumber" @keypress="onlyNumberKey($event)" type="tel" :placeholder="$t('registrePage.placeholderPhone')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
            </b-row>
           <b-row>
            <input v-model="form.company" type="text" :placeholder="$t('contactUsPage.contactForm.placeholderCompany')"  required class="input-outline-yellow input-color-white"></input>
          </b-row>
           <b-row>
  <!-- <b-form-select
               v-model="form.country"
               :options="info"
               value-field="name"
                text-field="name"
               required
               class="input-outline-white-border"
               ></b-form-select>          -->
                               <v-select v-model="form.country" :options="info" :reduce="info => info.name" label="name" 
                  class="input-outline-white-border-select"/>
                </b-row>
           <!-- <b-row>
           <b-form-select v-model="form.emailMTL" :options="optionsContacltMTL" required class="input-outline-yellow" placeholder="Subject"></b-form-select>
          </b-row> -->
          <b-row>
           <input v-model="form.subject" type="text" :placeholder="$t('contactUsPage.contactForm.placeholderSubject')"  required class="input-outline-yellow input-color-white"></input>
          </b-row>
          <b-row>
           <b-form-textarea v-model="form.message" type="text" :placeholder="$t('contactUsPage.contactForm.placeholderMessage')"  required class="input-outline-yellow input-color-white"></b-form-textarea>
          </b-row>
          <b-row>
            <b-button type="submit" block class="contact-button mb-25 mt-25" >{{$t('contactUsPage.contactForm.btn')}}</b-button>
          </b-row>
        </b-container>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from "axios";
import country from "./../../assets/files/country.json";

export default {
  name: "ContactForm",
  components: {},
  data() {
    return {
      info: country,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        country: "Tunisia",
        phonePrefix:"",
        phoneNumber: "",
        company: "",
        subject: null,
        message: "",
      },
      optionsContacltMTL: [
        { value: null, text: "Please select an option" },
        { value: "Quotation", text: "Quotation" },
        { value: "instant Estimation", text: "Instant estimation" },
        { value: "bl", text: "B/L" },
        { value: "Account", text: "Account" },
      ],
    };
  },
 async mounted() {
    await axios
      .get("http://ipinfo.io/json?token=7f2f4735626039")
      .then((response) => {
        console.log("location", response.data);
        country.forEach((element) => {
          if (response.data.country == element.code) {
            this.form.phonePrefix = element.dial_code;
          }
        });
      });
  },
  methods: {
    onlyNumberKey(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    Submit() {
      console.log(JSON.stringify(this.form));
      axios
        .post("https://mtl-feeder-nodejs.onrender.com/api/contacts", this.form)
        .then((response) => {
          console.log(response);
          this.$toast.open({
            message: "Contact sent",
            type: "success",
            duration: 7000,
          });
          // this.$router.push("/quotation");
        })
        .catch((error) => {
          this.$toast.open({
            message: "Error during send",
            type: "error",
            duration: 7000,
          });
        });
    },
  },
};
</script>
<style lang="css" scoped>
.contact-input .contact-input-1 {
  padding: 0 10px 0 0 !important;
}
.contact-input .contact-input-2 {
  padding: 0 0 0 10px !important;
}
.contact-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-form {
  color: white;
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 2.5rem;
  /* margin: 1rem; */
  width: 40%;
  height: auto;
  margin-top: -10rem !important;
  /* margin-bottom: 100px; */
}
.contact-section .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 1.7rem !important;
  /* margin-bottom: 1.7rem !important; */
}
.contact-button {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-redirection {
  display: flex;
  align-items: center;
  justify-content: right;
}
.contact-button:hover,
.contact-button:active {
  background-color: #ffd500e7 !important;
}
@media only screen and (max-width: 1193px) {
  .contact-redirection {
    display: none !important;
  }
}
@media only screen and (max-width: 575px) {
  .contact-form {
    width: 100%;
    /* margin-left: 0% !important; */
  }
}
</style>