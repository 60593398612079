
<template lang="html">
  <div>
    <div class="partners-content text-center mt-25 mb-50">
    <h2 class="h2-blue"> {{$t('homePage.networkSection.networkTitle')}}</h2>
    <div>
      <div class="title-line mb-25 ml-48"></div>
    </div>
    
    <b-row>
      <b-col offset="1" col lg="10" md="10" cols="11">
          <b-card-text class="register-text mb-50 ">
            {{$t('homePage.networkSection.networkDescription')}}
          </b-card-text>
      </b-col>    
    </b-row>
    		 <!-- :stagePadding=20  -->
     <carousel 
     :items="items" 
     :dots="true" 
		 :nav="false" 
     :responsive="{0:{items:1},650:{items:2},1000:{items:4}}">

        <div class="text-left partners-content-bg" v-for="(item, index) in $t('homePage.networkSection.networkTab')" :key="index">
          <div class="partners-content-card" >
            <h3 class="h3-blue">{{ item.name }} </h3>
              <b-row class="partners-row">

                <b-col col lg="1" md="1" sm="1" cols="1" v-if="item.pays && item.pays!=''">
                      <font-awesome-icon icon="map-marker-alt" />
                </b-col>
                <b-col col lg="11" md="11" sm="11" cols="11" class="partners-adresse-section" >
                    <span v-if="item.address && item.address!=''"> {{ item.address }} – </span>  <span v-if="item.pays && item.pays!=''">{{ item.pays }}</span> 
                </b-col>

                <b-col col lg="1" md="1" sm="1" cols="1">
                <font-awesome-icon icon="phone" />
                </b-col>
                  <b-col col lg="11" md="11" sm="11" cols="11">
                    <span>{{ item.phone }}</span> 
                </b-col>

                <b-col col lg="1" md="1" sm="1" cols="1" v-if="item.fax && item.fax!=''">
                <b-icon icon="printer-fill"></b-icon>
                </b-col>
                  <b-col col lg="11" md="11" sm="11" cols="11" v-if="item.fax && item.fax!=''">
                    <span>{{ item.fax }}</span> 
                </b-col>

                <b-col col lg="1" md="1" sm="1" cols="1">
                <font-awesome-icon icon="envelope" />
                </b-col>
                  <b-col col lg="11" md="11" sm="11" cols="11">
                    <span> {{ item.email }} </span> 
                </b-col>

              </b-row>
            </div>

        </div>
    </carousel>

    </div>
  </div>
</template>
<script>
import carousel from 'vue-owl-carousel'

export default {
  name: "Partners",
    components: { carousel },
  data() {
    return {
      items: 6
    };
  },
  computed: {},
  created() {},

  methods: {},
};
</script>
<style lang="css">
.partners-content{
    padding: 2.5rem 2rem;

}
.partners-content .owl-stage .owl-item{
  padding: 0.8rem!important;
  /* border: 2px solid #C4C4C4; */
}

.partners-row{
  padding-top: 1rem;
}
.partners-row div{
  padding-bottom: 0.7rem;
  /* padding-left: 0px!important; */
  padding-right: 0px!important;
}
/* .partners-row .partners-adresse-section{
  padding-bottom: 2.4rem;
}
.partners-row .partners-adresse-section-lg{
  padding-bottom: 0.8rem!important;
} */

/* .partners-card {
  background: #EEEEEE;
  padding: 1rem;
} */

.partners-content-bg {
  background: #EEEEEE;
  height: 260px;
  /* color:#fff!important; */
}
@media only screen and (max-width: 404px) {
  .partners-content-bg {
  background: #EEEEEE;
  height: 300px;
  /* color:#fff!important; */
}
}
.partners-content-card {
  /* background: url(../assets/img/world.png);
  background-position: center;
  background-repeat: repeat-x; */
  padding: 1rem 1rem;
}

/* .partners-adresse-section{
  height: 90px;
} */
</style>