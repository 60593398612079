<template lang="html">
    <div class="quotation-layout">
      <QuotationBreadcrumb />
      <div class="forms-padding">
        <b-row class="row-eq-height">
          <b-col col lg="3" md="3" sm="12" cols="12" class="profile-menu-card">
              <ProfileMenu />
          </b-col>
          <b-col col lg="9" md="9" sm="12" cols="12" class="forms-quoation-card mb-100">
            <QuotationForm />
          </b-col>
        </b-row>
      </div>
    </div>
  </template>
  <script>
  import QuotationBreadcrumb from "../components/MyQuote/DetailQuoteBreadcrumb.vue";
  import ProfileMenu from "../components/Profile/profileMenu.vue";
  import QuotationForm from "../components/MyQuote/DetailEQuote.vue";
  
  export default {
    name: "Quotation",
    components: {
      QuotationBreadcrumb,
      ProfileMenu,
      QuotationForm,
    },
    methods: {},
  };
  </script>
  <style lang="css">
  .quotation-layout {
    padding-top: 25vh !important;
  }
  @media only screen and (max-width: 1077px) {
   .profile-menu-card{
       padding: 0px!important;
    }
    .forms-quoation-card{
        padding: 0px!important;
    }
  }
  
  </style>