<template lang="html">
  <div class="booking-layout">
    <CreateBookingBreadcrumb />
     <div class="forms-padding">
      <b-row class="row-eq-height">
        <b-col col lg="3" md="3" sm="12" cols="12" class="profile-menu-card">
            <profileMenu />
        </b-col>
        <b-col col lg="9" md="9" sm="12" cols="12" class="forms-quoation-card mb-100">
          <CreateBookingForm />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import CreateBookingBreadcrumb from "../components/CreateBooking/CreateBookingBreadcrumb.vue";
import profileMenu from "../components/Profile/profileMenu.vue";
import CreateBookingForm from "../components/CreateBooking/CreateBookingForm.vue";

export default {
  name: "Booking",
  components: {
    CreateBookingBreadcrumb,
    profileMenu,
    CreateBookingForm,
  },
  methods: {},
};
</script>
<style lang="css">
.booking-layout {
  padding-top: 25vh !important;
}
</style>