<template lang="html">
    <div>
      <Hero imgLink="/img/aboutBoat.jpeg" />
      <div>
        <div class="second-padding">
          <b-row class="">
            <b-col col lg="5" md="5" sm="12" cols="12">
              <div class="about-img">
                <img src="../assets/img/home.jpeg" alt="">
              </div>
            </b-col>
            <b-col col lg="7" md="7" sm="12" cols="12">
              <div class="about-card-text">
                <h2 class="h2-blue">{{$t('aboutUsPage.aboutUsSection.title')}}</h2>
                <p class="p-blue text-justify" v-html="$t('aboutUsPage.aboutUsSection.text1')"></p>
              </div>
            </b-col>

<b-col col lg="7" md="7" sm="12" cols="12">
              <div class="about-card-text about-card-text-right">
                <p class="p-blue text-justify" v-html="$t('aboutUsPage.aboutUsSection.text2')"></p>
              </div>
            </b-col>
               <b-col col lg="5" md="5" sm="12" cols="12">
              <div class="about-img">
                <img src="../assets/img/home.jpeg" alt="">
              </div>
            </b-col>
            

          </b-row>
        </div>
      </div>
      <AboutUsTimeLine />
      <AboutUsSchedule />
      <Partners />
  </div>
</template>
<script>
// import AboutUsHero from "../components/AboutUs/AboutUsHero.vue";
import Hero from "../components/Hero.vue";

import Partners from "../components/Partners.vue";
import AboutUsTimeLine from "../components/AboutUs/AboutUsTimeLine.vue";
import AboutUsSchedule from "../components/AboutUs/AboutUsSchedule.vue";

export default {
  name: "AboutUs",
  components: {
    // AboutUsHero,
    Hero,
    Partners,
    AboutUsTimeLine,
    AboutUsSchedule,
  },
  methods: {},
};
</script>
<style lang="css">
.about-content {
  padding: 8rem 12rem 8rem 12rem;
}

.about-content-p {
  font-size: 18px;
  color: #082139;
  width: 70%;
}

/* img */
.about-img img {
  width: 100%;
  height: 78%;
}
.about-card-text {
  margin-left: 5% !important;
}
.about-card-text-right {
  margin-right: 5%!important;
    margin-left: 0% !important;
}
.about-card-text h2 {
  font-size: 35px;
  color: #082139;
  font-weight: bolder;
}
.about-card-text p {
  font-size: 20px;
  color: #212529;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
  .about-card-text h2{
  margin-top:2rem
}
}

</style>
