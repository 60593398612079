<template lang="html">
  <div>
    <div class="about-time-bg">
        <div class="about-time-content">
            <br><br>
            <b-row class="timeline-years">
               <b-col col lg="8" md="8" sm="12" offset-lg="4" offset-md="4" >
                 <b-row >  
                     <!-- <b-col col lg="2" md="2" sm="12"  class="clickable-element">
                        <div :class="aboutTimeButton[0]" @click="changeText(0)">2010</div>
                    </b-col> 
                    <b-col col lg="1" md="1" sm="12">
                       <div class="about-time-button-line" ></div>
                    </b-col>
                     <b-col col lg="2" md="2" sm="12"  class="clickable-element">
                        <div :class="aboutTimeButton[1]" @click="changeText(1)">2012</div>
                    </b-col>  
                    <b-col  col lg="1" md="1" sm="12" >
                       <hr class="about-time-button-line">
                    </b-col>-->
                     <b-col col lg="2" md="2" sm="12" class="clickable-element">
                        <div :class="aboutTimeButton[0]"  @click="changeText(0)">2021</div>

                    </b-col> 
                    <b-col  col lg="2" md="2" sm="12">
                       <hr class="about-time-button-line">
                    </b-col>
                     <b-col col lg="2" md="2" sm="12"   class="clickable-element">
                        <div :class="aboutTimeButton[1]" @click="changeText(1)">2022</div>
                    </b-col>    
                </b-row>
                <br><br><br><br><br> 
               </b-col>
            </b-row>   
            <b-row >
                <b-col col lg="8" md="8" sm="12" cols="12" offset-lg="2" offset-md="2" align="center">
                    <div class="about-time-card-text">
                        <!-- <p class="p-white">{{timeLineText[selectedElement]}}</p> -->
                         <p class="p-white">{{$t('aboutUsPage.timeLineSection.timeLineText')[selectedElement]}}</p>
                    </div>
                </b-col>
                <b-col  col lg="1" md="1" sm="12"></b-col>
            </b-row>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutTimeLine",
  components: {},
  data() {
    return {
        active: 0,
        selectedElement:0,
        aboutTimeButton:['about-time-button-yellow','about-time-button-white','about-time-button-white','about-time-button-white']
    };
  },
  computed: {},
  created() {},

  methods: {
    changeText(arg){
      this.selectedElement=arg
      for (let index = 0; index < this.aboutTimeButton.length; index++) {
        this.aboutTimeButton[index]='about-time-button-white'
      }
      this.aboutTimeButton[arg]='about-time-button-yellow'
    }
  },
};
</script>
<style lang="css">
.about-time-content-bg{
background: linear-gradient(99.27deg, #082139 -5.24%, #0081DC 106.47%);
background: rgba(13, 6, 39, 0.5);
}
.about-time-content{
  background: url(../../assets/img/shutterstock.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4.5rem 4rem;
}

.about-time-button-yellow
{
    text-align: center;
    font-size: 22px !important;
    margin-left: 11% !important;
    border: 2px solid #F2AE65;
    box-sizing: border-box;
    border-radius: 25px;
    width: 120px;
    height: 70px;
    color: #F2AE65;
    padding-top: 1.3rem;
}
.about-time-button-white
{
    text-align: center;
    font-size: 22px !important;
    margin-left: 11% !important;
    border: 2px solid white;
    box-sizing: border-box;
    border-radius: 25px;
    width: 120px;
    height: 70px;
    color: white;
    padding-top: 1.3rem;
}
.about-time-button-line
{
  width: 150px;
  border: 1px solid #FFFFFF;
  margin-top: 25% !important;
  background-color: white;
}

@media only screen and (max-width: 1400px) {
  .about-time-button-line {
    width: 80px!important; 
    margin-left: 15px;
  }
}
@media only screen and (max-width: 1148px) {
  .about-time-content .about-time-button-line {
    width: 25px!important; 
    margin-left: 28px;
    margin-top:100%!important;
    display: none;
  }
}
@media only screen and (max-width: 1036px) {
  .about-time-content .about-time-button-line {
    /* display: none; */
  }
}

@media only screen and (max-width: 898px) {
  .timeline-years {
    display: none!important;
  }
  .about-time-bg{
    display: none;
  }
}

</style>