import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from "../views/Layout.vue";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import Home from "../views/Home.vue";
import AboutUs from "../views/AboutUs.vue";
import OurAgency from "../views/OurAgency.vue";
import Ceo from "../views/Ceo.vue";
import Tracking from "../views/Tracking.vue";
import Quotation from "../views/Quotation.vue";
import QuotationEdit from "../views/QuotationEdit.vue";

import Information from "../views/Information.vue";
import InstantQuotation from "../views/InstantQuotation.vue";
import ViewInstantQuotation from "../views/DetailInstantQuotation.vue";
import Booking from "../views/Booking.vue";
import OurFleet from "../views/OurFleet.vue";
import Scheduling from "../views/Scheduling.vue";
import Contact from "../views/Contact.vue";
import MyQuote from "../views/MyQuote.vue";
import ViewQuote from "../views/DetailQuote.vue";
import MyQuickQuote from "../views/MyQuickQuote.vue";
import BookingTest from "../views/BookingTest.vue";
import CreateBooking from "../views/CreateBooking.vue";
import EditBooking from "../views/EditBooking.vue";
import AcceptTermAndCondition from "../views/AcceptTermAndCondition.vue";
import BL from "../views/BL.vue";
import MyBL from "../views/MyBL.vue";
import EditBL from "../views/EditBL.vue";
import OurServices from "../views/OurServices.vue";
import OurSolution from "../views/OurSolution.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
import ResetPasswordForm from "../views/ResetPassword.vue";

import VueGoodTablePlugin from 'vue-good-table';

// import the styles 
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

Vue.use(VueRouter);



const ifAuthenticated = (to, from, next) => {
  if (!localStorage.getItem('userData'))
    next({
      path: '/login'
    })
  else
    next()
};
const NotAuthenticated = (to, from, next) => {
  if (localStorage.getItem('userData'))
    next({
      path: '/'
    })
  else next()
};





const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home
      },
      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs
      },
      {
        path: "/our-agency",
        name: "OurAgency",
        component: OurAgency
      },
      {
        path: "/ceo",
        name: "Ceo",
        component: Ceo
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: NotAuthenticated,
      },
      {
        path: "/sign-up",
        name: "SignUp",
        component: SignUp,
        beforeEnter: NotAuthenticated,
      },
      {
        path: "/tracking",
        name: "Tracking",
        component: Tracking
      },
      {
        path: "/quotation",
        name: "Quotation",
        component: Quotation,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/information",
        name: "Information",
        component: Information,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/new-instant",
        name: "InstantQuotation",
        component: InstantQuotation,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/view-new-instant",
        name: "ViewInstantQuotation",
        component: ViewInstantQuotation,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/quotation-edit",
        name: "QuotationEdit",
        component: QuotationEdit,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/booking",
        name: "Booking",
        component: Booking,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/booking-edit",
        name: "EditBooking",
        component: EditBooking,
        beforeEnter: ifAuthenticated,
      },
      // {
      //   path: "/booking-test",
      //   name: "Booking",
      //   component: BookingTest
      // },
      {
        path: "/scheduling",
        name: "Scheduling",
        component: Scheduling,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/our-fleet",
        name: "OurFleet",
        component: OurFleet
      },
      {
        path: "/contact",
        name: "contact",
        component: Contact
      },
      {
        path: "/my-quote",
        name: "MyQuote",
        component: MyQuote,
        beforeEnter: ifAuthenticated,

      },
      {
        path: "/view-my-quote",
        name: "ViewQuote",
        component: ViewQuote,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/my-quick-quote",
        name: "MyQuickQuote",
        component: MyQuickQuote,
        beforeEnter: ifAuthenticated,

      },
      {
        path: "/create-booking",
        name: "createBooking",
        component: CreateBooking,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/accept-term-condition",
        name: "AcceptTermAndCondition",
        component: AcceptTermAndCondition
      },
      {
        path: "/b-l",
        name: "BL",
        component: BL,
        beforeEnter: ifAuthenticated,

      },
      {
        path: "/my-b-l",
        name: "MyBL",
        component: MyBL,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/edit-b-l",
        name: "EditBL",
        component: EditBL,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/our-services",
        name: "OurServices",
        component: OurServices
      },
      {
        path: "/our-solution",
        name: "OurSolution",
        component: OurSolution
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
        beforeEnter: NotAuthenticated,
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPasswordForm,
        beforeEnter: NotAuthenticated,
      },
      {
        path: '*',
        redirect: '/',
      },
    ]
  } 
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
