<template lang="html">
  <b-container class="bl-box-form">
      <div class="bl-form">
            <b-row>
                <b-col>
                    <div>
                        <b-tabs content-class="mt-3" align="center" v-model="tabIndex">
                           <b-tab active>
                                <template #title>
                                   <span class="cercle-steps" > <span>1</span> </span>
                                     <span>{{$t('newQuickQuotePage.step1')}}</span>
                                </template>
                                <p class="p-3">
                                     <b-row >
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newBLPage.documentSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                                <b-form-group :label="$t('newBLPage.documentSection.documentType')" label-for="document-type">
                                                  <v-select
                                                  id="document-type"
                                                  :placeholder="$t('newBLPage.documentSection.documentTypePlaceholder')"                          
                                                  v-model="form.documentType"
                                                  :options="DocumentType"
                                                  required
                                                  class="input-outline-bleu-border"/>
                                                </b-form-group>                           
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                           <b-form-group :label="$t('newBLPage.documentSection.connaissement')" label-for="connaissement">
                                                    <v-select
                                                    id="connaissement"
                                                    :placeholder="$t('newBLPage.documentSection.connaissementPlaceholder')"                          
                                                    v-model="form.receiverAgent"
                                                    :options="connaissement"
                                                    required
                                                    class="input-outline-bleu-border"/>
                                                </b-form-group>      
                                        </b-col>
                                    </b-row>

                                    <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newBLPage.cargoDetailsSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row v-for="(item, index) in form.cargoDetails" :key="index">
                                         <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                                <b-form-group :label="$t('newBLPage.cargoDetailsSection.numberOfPackages')" label-for="packagesNumber">
                                                    <b-form-input
                                                    id="packagesNumber"
                                                    v-model="item.packagesNumber"
                                                    placeholder="Number of packages"
                                                    type="number"
                                                    required
                                                     class="input-outline-bleu-border"
                                                    ></b-form-input>
                                                </b-form-group>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                            <b-form-group :label="$t('newBLPage.cargoDetailsSection.kindOfPackages')" label-for="packagesKind">
                                                    <b-form-input
                                                    id="packagesKind"
                                                    v-model="item.packagesKind"
                                                    :placeholder="$t('newBLPage.cargoDetailsSection.kindOfPackages')"
                                                    required
                                                     class="input-outline-bleu-border"
                                                    ></b-form-input>
                                                </b-form-group>
                                        </b-col>
                                         <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                                <b-form-group :label="$t('newBLPage.cargoDetailsSection.cargoDescription') " label-for="CargoDescription ">
                                                    <b-form-textarea
                                                    rows="3"
                                                    max-rows="6"
                                                    id="CargoDescription"
                                                    v-model="item.cargoDescription"
                                                    :placeholder="$t('newBLPage.cargoDetailsSection.cargoDescription')"
                                                    required
                                                     class="input-outline-bleu-border"
                                                    ></b-form-textarea>
                                                </b-form-group>
                                        </b-col>
                                         <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                                <b-form-group :label="$t('newBLPage.cargoDetailsSection.hsCode')" label-for="HSCode">
                                                    <b-form-input
                                                    id="HSCode"
                                                    v-model="item.HSCode"
                                                    :placeholder="$t('newBLPage.cargoDetailsSection.hsCode')"
                                                    required
                                                     class="input-outline-bleu-border"
                                                    ></b-form-input>
                                                </b-form-group>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                            <b-form-group :label="$t('newBLPage.cargoDetailsSection.weightOfMerchandise')" label-for="merchandiseWeight">
                                                    <v-select
                                                    id="merchandiseWeight"
                                                    :placeholder="$t('newBLPage.cargoDetailsSection.weightOfMerchandisePlaceholder')"                          
                                                    v-model="item.merchandiseWeight"
                                                    :options="weightOfMerchandise"
                                                    required
                                                    class="input-outline-bleu-border"/>
                                                </b-form-group>
                                        </b-col> 
                                         <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                                <b-form-group :label="$t('newBLPage.cargoDetailsSection.sealNumber')" label-for="sealNumber ">
                                                    <b-form-input
                                                    id="sealNumber"
                                                    v-model="item.sealNumber"
                                                    :placeholder="$t('newBLPage.cargoDetailsSection.sealNumber')"
                                                    required
                                                     class="input-outline-bleu-border"
                                                    ></b-form-input>
                                                </b-form-group>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                                <b-form-group :label="$t('newBLPage.cargoDetailsSection.containersListeBl')" label-for="container">
                                                    <!-- <v-select
                                                    id="container"
                                                    :placeholder="$t('newBLPage.cargoDetailsSection.containersListePlaceholder')"                          
                                                    v-model="item.container"
                                                    :options="containersTab"
                                                    required
                                                    @input="updateContainer(index,item.container)"
                                                    class="input-outline-bleu-border"/> -->
                                                     <b-form-input
                                                      id="container"
                                                    v-model="item.container"
                                                      :placeholder="$t('newBLPage.cargoDetailsSection.containersListePlaceholder')"
                                                      required
                                                      class="input-outline-bleu-border"
                                                    ></b-form-input>
                                                </b-form-group>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                                <b-form-group :label="$t('newQuickQuotePage.containerSection.containerQuantityLabel')" label-for="Container-qte">
                                                <b-form-spinbutton 
                                                    id="Container-qte" 
                                                    v-model="item.quantity"
                                                    min="1" 
                                                    max="100000"
                                                     class="input-outline-bleu-border"
                                                    ></b-form-spinbutton> 
                                                </b-form-group>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                                <b-form-group :label="$t('newBLPage.cargoDetailsSection.containerNumber')" label-for="containerNumber">
                                                    <b-form-input
                                                    id="containerNumber"
                                                    size="11"
                                                    v-model="item.containerNumber"
                                                    :placeholder="$t('newBLPage.cargoDetailsSection.containerNumber')"
                                                    required
                                                     class="input-outline-bleu-border"
                                                    ></b-form-input>

                                                </b-form-group>
                                        </b-col>
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12" v-if="index>maxLength-1">
                                            <div class="delete-container-div">
                                            <button class="delete-container" @click="deleteCargo(index)"><b-icon icon="plus-circle"></b-icon> {{$t('newBLPage.deleteCargo')}}</button>   
                                            </div>
                                        </b-col>
                                        <b-col v-if="index !== form.cargoDetails.length-1"><br><hr class="cargo-line"><br></b-col>
                                  </b-row> 
                                  <!-- <b-row>
                                        <b-col >
                                            <button class="addCargo" @click="addCargo()"><b-icon icon="plus-circle"></b-icon> {{$t('newBLPage.addCargo')}}</button>   
                                        </b-col>
                                    </b-row>  -->
                                     <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newBLPage.partiesSection.title')}} </p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                     <b-row v-for="(partyType,j) in partyTypes" :key="`${partyType}-${j}`">
                                      <b-col lg="12" md="12" sm="12" xs="12" cols="12" class="mb-2"><span class="party-title">{{ listParties[j] }}</span></b-col>
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                          <b-form-group :label="$t('newBLPage.partiesSection.companyName')" label-for="companyName">
                                            <b-form-input
                                              id="companyName"
                                              v-model="form.parties[partyType].companyName"
                                              :placeholder="$t('newBLPage.partiesSection.companyName')"
                                              class="input-outline-bleu-border"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <b-form-group :label="`${$t('newBLPage.partiesSection.address')} (${$t('newBLPage.partiesSection.optional')})`" label-for="address">
                                            <b-form-input
                                              id="address"
                                              v-model="form.parties[partyType].address"
                                              :placeholder="$t('newBLPage.partiesSection.address')"
                                              class="input-outline-bleu-border"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <b-form-group :label="`Contact (${$t('newBLPage.partiesSection.optional')})`" label-for="contact">
                                            <b-form-input
                                              id="contact"
                                              v-model="form.parties[partyType].contact"
                                              placeholder="Contact"
                                              class="input-outline-bleu-border"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                              <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <b-form-group :label="`Email (${$t('newBLPage.partiesSection.optional')})`" label-for="email">
                                            <b-form-input
                                              id="email"
                                              v-model="form.parties[partyType].email"
                                              placeholder="Email"
                                              class="input-outline-bleu-border"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <b-form-group :label="`Tel (${$t('newBLPage.partiesSection.optional')})`" label-for="tel">
                                            <b-form-input
                                              id="tel"
                                              v-model="form.parties[partyType].tel"
                                              placeholder="Tel"
                                              class="input-outline-bleu-border"
                                            ></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col><br></b-col>
                                    </b-row>
                                     
                                     <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newBLPage.paymentDetailsSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row class="mt-25">
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">

                                        <div class="container-paiment">
                                              <div class="card">
                                                    <div class="imgBx">
                                                        <img src="../../assets/img/logo-icon5.png" alt="ARCOSHOP logo">
                                                    </div>

                                                    <div class="contentBx">

                                                        <h2>{{$t('newBLPage.paymentDetailsSection.card1.title')}}</h2>

                                                      <div class="country"> 
                                                        <v-select
                                                           :placeholder="$t('newBLPage.paymentDetailsSection.card1.paymentType')"
                                                            v-model="paymentFRET.paymentType"
                                                            :options="paiementType"
                                                            required
                                                            class="input-outline-bleu-border payment-select"
                                                        ></v-select>
                                                      </div>
                                                       <div class="country" v-if="paymentFRET.paymentType !== null"> 
                                                        <v-select
                                                        :placeholder="$t('newBLPage.paymentDetailsSection.card1.paymentCountry')"
                                                            v-model="paymentFRET.paymentCountry"
                                                            :options="paiementCountry"
                                                            required
                                                            class="input-outline-bleu-border payment-select"
                                                       />
                                                      </div>
                                                        <div class="country" v-if="paymentFRET.paymentType !== null"> 
                                                               <b-form-input
                                                                v-model="paymentFRET.invoiceDetails"
                                                                :placeholder="$t('newBLPage.paymentDetailsSection.card1.inovoiceDetails')"
                                                                required
                                                                class="input-outline-bleu-border payment-select invoice"
                                                                ></b-form-input>
                                                      </div>
                                                        <button @click="chooseOffre('fret',paymentFRET)">{{$t('newBLPage.paymentDetailsSection.offerBtn')}}</button>
                                                    </div>
                                                 </div>  
                                                </div>  
                                        </b-col>
                                               <b-col lg="4" md="4" sm="6" xs="12" cols="12">

                                        <div class="container-paiment">
                                              <div class="card">
                                                    <div class="imgBx">
                                                        <img src="../../assets/img/logo-icon3.png" alt="ARCOSHOP logo">
                                                    </div>

                                                    <div class="contentBx">

                                                        <h2>{{$t('newBLPage.paymentDetailsSection.card2.title')}}</h2>

                                                      <div class="country"> 
                                                        <v-select
                                                           :placeholder="$t('newBLPage.paymentDetailsSection.card2.paymentType')"
                                                            v-model="paymentLTHC.paymentType"
                                                            :options="paiementType"
                                                            required
                                                            class="input-outline-bleu-border payment-select"
                                                        ></v-select>
                                                      </div>
                                                       <div class="country" v-if="paymentLTHC.paymentType !== null"> 
                                                        <v-select
                                                        :placeholder="$t('newBLPage.paymentDetailsSection.card2.paymentCountry')"
                                                            v-model="paymentLTHC.paymentCountry"
                                                            :options="paiementCountry"
                                                            required
                                                            class="input-outline-bleu-border payment-select"
                                                       />
                                                      </div>
                                                        <div class="country" v-if="paymentLTHC.paymentType !== null"> 
                                                               <b-form-input
                                                                v-model="paymentLTHC.invoiceDetails"
                                                                :placeholder="$t('newBLPage.paymentDetailsSection.card2.inovoiceDetails')"
                                                                required
                                                                class="input-outline-bleu-border payment-select invoice"
                                                                ></b-form-input>
                                                      </div>
                                                        <button @click="chooseOffre('lthc',paymentLTHC)">{{$t('newBLPage.paymentDetailsSection.offerBtn')}}</button>
                                                    </div>
                                                 </div>  
                                                </div>  
                                        </b-col>
                                         <b-col lg="4" md="4" sm="6" xs="12" cols="12">

                                        <div class="container-paiment">
                                              <div class="card">
                                                    <div class="imgBx">
                                                        <img src="../../assets/img/logo-icon2.png" alt="ARCOSHOP logo">
                                                    </div>

                                                    <div class="contentBx">

                                                        <h2>{{$t('newBLPage.paymentDetailsSection.card3.title')}}</h2>

                                                       <div class="country"> 
                                                        <v-select
                                                           :placeholder="$t('newBLPage.paymentDetailsSection.card3.paymentType')"
                                                            v-model="paymentDTHC.paymentType"
                                                            :options="paiementType"
                                                            required
                                                            class="input-outline-bleu-border payment-select"
                                                        ></v-select>
                                                      </div>
                                                       <div class="country" v-if="paymentDTHC.paymentType !== null"> 
                                                        <v-select
                                                        :placeholder="$t('newBLPage.paymentDetailsSection.card3.paymentCountry')"
                                                            v-model="paymentDTHC.paymentCountry"
                                                            :options="paiementCountry"
                                                            required
                                                            class="input-outline-bleu-border payment-select"
                                                       />
                                                      </div>
                                                        <div class="country" v-if="paymentDTHC.paymentType !== null"> 
                                                               <b-form-input
                                                                v-model="paymentDTHC.invoiceDetails"
                                                                :placeholder="$t('newBLPage.paymentDetailsSection.card3.inovoiceDetails')"
                                                                required
                                                                class="input-outline-bleu-border payment-select invoice"
                                                                ></b-form-input>
                                                      </div>
                                                        <button @click="chooseOffre('dthc',paymentDTHC)">{{$t('newBLPage.paymentDetailsSection.offerBtn')}}</button>
                                                    </div>
                                                 </div>  
                                                </div>  
                                        </b-col>
                                    </b-row>
                                 </p>
                                   <div class="text-center mt-50">
                  <b-button-group class="mt-2">
                    <b-button
                      class="button-yellow prl-30"
                      @click="tabIndex++"
                      >{{ $t("next") }}</b-button
                    >
                  </b-button-group>
                </div>
                            </b-tab>
                            <b-tab title="Step 2">
                                <template #title>
                            <span class="cercle-steps"> <span>2</span> </span>
                            <span>{{$t('newQuickQuotePage.step2')}}</span>
                            </template>
                                 <p class="p-3" v-if="validData()">
                                     <b-row >
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newBLPage.documentSection.title')}}</p> 
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                                <div v-if="form.documentType">
                                                    <span class="subTitle-forms">
                                                        {{$t('newBLPage.documentSection.documentType')}}:
                                                    </span>
                                                      <span>
                                                         {{form.documentType}}
                                                    </span>                                                
                                                </div>
                                        </b-col>
                                        <b-col lg="5" md="6" sm="6" xs="12" cols="12">
                                                <div v-if="form.receiverAgent">
                                                    <span class="subTitle-forms">
                                                      {{$t('newBLPage.documentSection.connaissement')}}:
                                                    </span>
                                                      <span>
                                                         {{form.receiverAgent}}
                                                    </span>
                                                </div>
                                        </b-col>
                                    </b-row>
                                    
                                    <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newBLPage.cargoDetailsSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row v-for="(item, index) in form.cargoDetails" :key="index">
                                       <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                            <div v-if="item.packagesNumber">
                                                    <span class="subTitle-forms">
                                                      {{$t('newBLPage.cargoDetailsSection.numberOfPackages')}}:
                                                    </span>
                                                      <span>
                                                         {{item.packagesNumber}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                             <div v-if="item.packagesKind">
                                                    <span class="subTitle-forms">
                                                      {{$t('newBLPage.cargoDetailsSection.kindOfPackages')}}:
                                                    </span>
                                                      <span>
                                                         {{item.packagesKind}}
                                                    </span>
                                                </div>
                                        </b-col>
                                         <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                            <div v-if="item.cargoDescription">
                                                    <span class="subTitle-forms">
                                                       {{$t('newBLPage.cargoDetailsSection.cargoDescription')}}:
                                                    </span>
                                                      <span>
                                                         {{item.cargoDescription}}
                                                    </span>
                                                </div>
                                        </b-col>
                                         <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                              <div v-if="item.HSCode">
                                                    <span class="subTitle-forms">
                                                      {{$t('newBLPage.cargoDetailsSection.hsCode')}}:
                                                    </span>
                                                      <span>
                                                         {{item.HSCode}}
                                                    </span>
                                                </div> 
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                             <div v-if="item.merchandiseWeight">
                                                    <span class="subTitle-forms">
                                                       {{$t('newBLPage.cargoDetailsSection.weightOfMerchandise')}}:
                                                    </span>
                                                      <span>
                                                         {{item.merchandiseWeight}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                              <div v-if="item.sealNumber">
                                                    <span class="subTitle-forms">
                                                      {{$t('newBLPage.cargoDetailsSection.sealNumber')}}:
                                                    </span>
                                                      <span>
                                                         {{item.sealNumber}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                             <div v-if="item.container">
                                                    <span class="subTitle-forms">
                                                      {{$t('newBLPage.cargoDetailsSection.containersListeBl')}}:
                                                    </span>
                                                      <span>
                                                         {{item.container}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                             <div v-if="item.quantity">
                                                    <span class="subTitle-forms">
                                                      {{$t('newQuickQuotePage.containerSection.containerQuantityLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.quantity}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                             <div v-if="item.containerNumber">
                                                    <span class="subTitle-forms">
                                                      {{$t('newBLPage.cargoDetailsSection.containerNumber')}}:
                                                    </span>
                                                      <span>
                                                         {{item.containerNumber}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col v-if="index !== form.cargoDetails.length-1"><br><hr class="cargo-line"><br></b-col>
                                    </b-row>  
                                    <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newBLPage.partiesSection.title')}} </p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row v-for="(partyType,j) in partyTypes" :key="`${partyType}-${j}`">
                                      <b-col lg="12" md="12" sm="12" xs="12" cols="12" class="mb-2"><span class="party-title">{{ listParties[j] }}</span></b-col>
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                          <div v-if="form.parties[partyType].companyName">
                                            <span class="subTitle-forms">
                                              {{$t('newBLPage.partiesSection.companyName')}}:
                                            </span>
                                            <span>
                                              {{ form.parties[partyType].companyName }}
                                            </span>
                                          </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <div v-if="form.parties[partyType].address">
                                            <span class="subTitle-forms">
                                              {{$t('newBLPage.partiesSection.address')}}:
                                            </span>
                                            <span>
                                              {{ form.parties[partyType].address }}
                                            </span>
                                          </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <div v-if="form.parties[partyType].contact">
                                            <span class="subTitle-forms">
                                              Contact:
                                            </span>
                                            <span>
                                              {{ form.parties[partyType].contact }}
                                            </span>
                                          </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <div v-if="form.parties[partyType].email">
                                            <span class="subTitle-forms">
                                              Email:
                                            </span>
                                            <span>
                                              {{ form.parties[partyType].email }}
                                            </span>
                                          </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <div v-if="form.parties[partyType].tel">
                                            <span class="subTitle-forms">
                                              Tel:
                                            </span>
                                            <span>
                                              {{ form.parties[partyType].tel }}
                                            </span>
                                          </div>
                                        </b-col>
                                        <b-col><br></b-col>
                                    </b-row>
                                    <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newBLPage.paymentDetailsSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                      <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <div v-if="form.payment.paymentName">
                                            <span class="subTitle-forms">
                                              {{ $t('newBLPage.paymentDetailsSection.paymentName') }}:
                                            </span>
                                            <span>
                                              {{ form.payment.paymentName }}
                                            </span>
                                          </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <div v-if="form.payment.paymentType">
                                            <span class="subTitle-forms">
                                              {{ $t('newBLPage.paymentDetailsSection.card2.paymentType') }}:
                                            </span>
                                            <span>
                                              {{ form.payment.paymentType }}
                                            </span>
                                          </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <div v-if="form.payment.paymentCountry">
                                            <span class="subTitle-forms">
                                              {{ $t('newBLPage.paymentDetailsSection.card2.paymentCountry') }}:
                                            </span>
                                            <span>
                                              {{ form.payment.paymentCountry }}
                                            </span>
                                          </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                          <div v-if="form.payment.invoiceDetails">
                                            <span class="subTitle-forms">
                                              {{ $t('newBLPage.paymentDetailsSection.card2.inovoiceDetails') }}:
                                            </span>
                                            <span>
                                              {{ form.payment.invoiceDetails }}
                                            </span>
                                          </div>
                                        </b-col>
                                    </b-row>
                                    <b-form-group class="mt-25">
                                        <b-form-checkbox v-model="acceptTerm" true-value="true" false-value="false"><a href="https://res.cloudinary.com/dfsd4vgsl/image/upload/v1648413829/BLClauses_qnlylv.pdf" target="_blank" class="linkAccept">{{$t('newBLPage.confirmBL')}}</a></b-form-checkbox>
                                    </b-form-group>
                                    <b-row class="btn-footer-forms mt-50">
                                        <b-col lg="2" md="3" sm="4" xs="5" cols="12">
                                            <b-button @click="onSubmit()" class="btnSubmit" type="submit" > {{$t('newQuickQuotePage.valideteBtn')}} </b-button>
                                         </b-col>
                                         <b-col lg="2" md="3" sm="4" xs="5" cols="12" class="ml-25">    
                                            <b-button @click="onReset()" class="btnReset" type="reset"> {{$t('newQuickQuotePage.resetBtn')}}</b-button>
                                        </b-col>
                                    </b-row>     
                                </p>
                                <p class="p-3 text-center" v-else>{{$t('newBLPage.step2Desc')}}</p>
                                <div class="text-center mt-50">
                    <b-button-group class="">
                      <b-button
                        class="button-yellow prl-30"
                        @click="tabIndex--"
                        >{{ $t("previous") }}</b-button
                      >
                    </b-button-group>
                  </div>
                                </b-tab>
                        </b-tabs>
                    </div>
                </b-col>
            </b-row>
             <br>
        </div>
    </b-container>
</template>
<script>
import axios from "axios";
import countriesList from '../../Country.json';
export default {
  name: "BLForm",
  components: {},
  data() {
    return {
            tabIndex:1,
      form: {
        customer:JSON.parse(localStorage.getItem("userData")).user._id,
        booking:this.$router.history.current.query.id,
        documentType: null,
        receiverAgent: null,
        cargoDetails :
        [
          {
            packagesNumber: "",
            packagesKind: "",
            cargoDescription: "",
            HSCode: "",
            container: null,
            quantity : 1,
            containerNumber: "",
            sealNumber: "",
            merchandiseWeight: null,
          }      
        ],
        parties: {
          shipper : {
            companyName : "",
            address : "",
            contact : "",
            email : "",
            tel : ""
          },
          consignee : {
            companyName : "",
            address : "",
            contact : "",
            email : "",
            tel : ""
          },
          notifyParty : {
            companyName : "",
            address : "",
            contact : "",
            email : "",
            tel : ""
          },
          outwardForwarder : {
            companyName : "",
            address : "",
            contact : "",
            email : "",
            tel : ""
          },
        },
        payment: {
          paymentName:'',
          paymentType:null,
          paymentCountry:null,
          invoiceDetails:""
        } 
      },
      paymentFRET : {
        paymentName:'',
        paymentType:null,
        paymentCountry:null,
        invoiceDetails:""         
      },
      paymentLTHC : {
        paymentName:'',
        paymentType:null,
        paymentCountry:null,
        invoiceDetails:""         
      },
      paymentDTHC : {
        paymentName:'',
        paymentType:null,
        paymentCountry:null,
        invoiceDetails:""         
      },
      paiementType:["Prepaid", "Collect", "Other"],
      paiementCountry: [],
      DocumentType: [
        "Document Type 1",
        "Document Type 2",
        "Document Type 3",
      ],
      connaissement: [
        "connaissement 1",
        "connaissement 2",
        "connaissement 3",
        "connaissement 4",
      ],
      partyTypes: ["shipper", "consignee", "notifyParty", "outwardForwarder"],
      listParties: ["Shipper","Consignee","Notify Party","Outward Forwarder"],
      weightOfMerchandise: [
        "Net",
        "VGM",
      ],
      containersTab:[],
      show: true,
      maxLength : null,
      acceptTerm: false,
    };
  },
  async created() {
    await this.getBooking()
    await this.getContainers()
    this.getCountries()
  },
  // computed: {
  //   missingFields() {
  //     const fields = [];
  //     const requiredFields = [
  //       "loadingPort",
  //       "dischargePort",
  //       "effectiveDate",
  //       "expiryDate",
  //       "incoterm",
  //     ];

  //     for (const field of requiredFields) {
  //       const path = field.split("."); // Handle nested fields like containers.containerType
  //       let value = this.form;
  //       for (const part of path) {
  //         value = value[part];
  //         if (value === null) {
  //           fields.push(field);
  //           break; // Stop iterating for this field if a null value is found
  //         }
  //       }
  //     }

  //     return fields;
  //   },
  //   allFieldsFilled() {
  //     return this.missingFields.length === 0;
  //   },
  // },
  methods: {
    async getBooking() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/bookings?id="+ this.$router.history.current.query.id
      );
      this.form.cargoDetails = Array.from({ length: response.data.data.commodityContainer.length }, () => ({
        packagesNumber: "",
        packagesKind: "",
        cargoDescription: "",
        HSCode: "",
        container: null,
        quantity : 1,
        containerNumber: "",
        sealNumber: "",
        merchandiseWeight: null,
      }));
      this.maxLength = response.data.data.commodityContainer.length
      response.data.data.commodityContainer.forEach((element,index) => {
        this.form.cargoDetails[index].container = element.container.containerType
        this.form.cargoDetails[index].quantity = element.container.quantity
        this.form.cargoDetails[index].containerNumber = element.container.reference
      })
    },
    async getContainers() {
        const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/containers"); 

         const uniqueSizes = new Set();
        const uniqueArray = response.data.data.filter(obj => {
          if (!uniqueSizes.has(obj.sizeType)) {
            uniqueSizes.add(obj.sizeType);
            return true;
          }
          return false;
        });


        this.containersTab = uniqueArray.map(item => ({
          // label: "Container " + item.type + "/" + item.size,
          label: "Container " + item.sizeType ,
          value: {"_id": item._id , "title": item.title},
        }));
    },
    getCountries() { 
      countriesList.forEach(country => {
        this.paiementCountry.push(country.name)
      });
    },
    updateContainer(index,container) {
      if (container) {
        this.form.cargoDetails[index].container = container.label
      }
    },
    addCargo() {
      this.form.cargoDetails.push({
        packagesNumber: "",
        packagesKind: "",
        cargoDescription: "",
        HSCode: "",
        container: null,
        quantity : 1,
        containerNumber: "",
        sealNumber: "",
        merchandiseWeight: null,      
      })
    },
    deleteCargo(index) {
      this.form.cargoDetails.splice(index,1)
    },
    chooseOffre(name,payment) {
      this.form.payment = payment
      this.form.payment.paymentName = name
      if (Object.values(this.form.payment).some(value => value === '' || value === null)) {
          this.$toast.open({
          message: this.$t('newBLPage.fillForm'),
          type: "error",
          duration: 4000
        })
      }
      else {
        this.$toast.open({
         message: this.$t('newBLPage.paymentToast'),
         type: "success",
         duration: 4000
        })
      }
    },
    validData() {
      const hasEmpty = (this.form.documentType === "" || this.form.documentType === null) ||
      (this.form.receiverAgent === "" || this.form.receiverAgent === null)
      const cargoEmpty = this.form.cargoDetails.some(element => {
        return Object.values(element).some(value => value === '' || value === null);
      });
      const partiesEmpty = this.form.parties.shipper.companyName === "" || this.form.parties.shipper.companyName === null &&
      this.form.parties.consignee.companyName === "" || this.form.parties.consignee.companyName === null &&
      this.form.parties.notifyParty.companyName === "" || this.form.parties.notifyParty.companyName === null &&
      this.form.parties.outwardForwarder.companyName === "" || this.form.parties.outwardForwarder.companyName === null
      return cargoEmpty===false && hasEmpty===false && partiesEmpty===false && 
      (Object.values(this.form.payment).some(value => value === '' || value === null)) ===false
    },
    onSubmit() {
      if (this.acceptTerm) {
        axios
        .post(
              'https://mtl-feeder-nodejs.onrender.com/api/bls', this.form
            )
            .then((response) => {
                this.$toast.open({
                    message: this.$t('newBLPage.BLAdded'),
                    type: "success",
                    duration: 4000
                });
                 this.$router.push("/my-b-l");
            })
            .catch((error) => {
            this.$toast.open({
                message: this.$t('addErrorMessage'),
                type: "error",
                duration: 4000
            });
            })
      }
      else {
        this.$toast.open({
          message: this.$t('newBLPage.confirmBLToast'),
          type: "warning",
          duration: 3000
        });
      }     
    },
    onReset() {
      this.getBooking();
      this.form = {
        customer:JSON.parse(localStorage.getItem("userData")).user._id,
        booking:this.$router.history.current.query.id,
        documentType: null,
        receiverAgent: null,
        cargoDetails :
        [
          {
            packagesNumber: "",
            packagesKind: "",
            cargoDescription: "",
            HSCode: "",
            container: null,
            quantity : 1,
            containerNumber: "",
            sealNumber: "",
            merchandiseWeight: null,
          }      
        ],
        parties: {
          shipper : {
            companyName : "",
            address : "",
            contact : "",
            email : "",
            tel : ""
          },
          consignee : {
            companyName : "",
            address : "",
            contact : "",
            email : "",
            tel : ""
          },
          notifyParty : {
            companyName : "",
            address : "",
            contact : "",
            email : "",
            tel : ""
          },
          outwardForwarder : {
            companyName : "",
            address : "",
            contact : "",
            email : "",
            tel : ""
          },
        },
        payment: {
          paymentName:'',
          paymentType:null,
          paymentCountry:null,
          invoiceDetails:""
        } 
      }
      this.paymentFRET = {
        paymentName:'',
        paymentType:null,
        paymentCountry:null,
        invoiceDetails:""         
      }
      this.paymentLTHC = {
        paymentName:'',
        paymentType:null,
        paymentCountry:null,
        invoiceDetails:""         
      }
      this.paymentDTHC = {
        paymentName:'',
        paymentType:null,
        paymentCountry:null,
        invoiceDetails:""         
      }
      this.acceptTerm = false
    },
  },
};
</script>
<style lang="css" scoped>
.bl-box-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bl-form {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}
/* .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1.2rem !important;
} */
.btnReset {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.btnSubmit {
  color: white !important;
  background: #082139 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.addRequest {
  background-color: #fff;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.words-divider {
  border: 1px solid;
  border-radius: 10px 10px 10px 10px;
  padding-top: 8px;
  color: white;
  background-color: #082139;
  height: 40px;
}
.words-divider p {
  font-weight: 500;
}

.cercle-steps {
  border: 2px solid #082139;
  color: #082139;
  border-radius: 50%;
  padding: 0.05rem 0.35rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}
.bl-form .nav-tabs .nav-link.active .cercle-steps {
  color: #F2AE65 !important;
  border-color: #F2AE65 !important;
}
.bl-form .nav-tabs .nav-link.active span {
  color: #F2AE65 !important;
}
.linkAccept  {
    color: #082139!important;
    text-decoration: underline !important;
}
.cargo-line {
  background-color: #495057!important;
}
.party-title {
  font-size: 15px;
  color:#555555;
}
.payment-select{
  border-bottom : 2px solid white !important
}
.invoice {
  color: white !important;
}
.invoice::placeholder {
  color: white;
}
.addCargo {
  margin-top: 10px;
    border: none;
}

.delete-container {
    color: red;
    border: none;
}
.delete-container-div{
    height: 100%;
    display: flex;
    margin-bottom: 15px;
}
.delete-container,.addCargo {
    background-color: transparent;
}
@media only screen and (max-width: 575px) {
  .btn-footer-forms .ml-25 {
    margin-left: 0px !important;
  }
}
/* cards */


.container-paiment {
    position: relative;
}

.container-paiment .card {
    position: relative;
    width: 100%;
    height: 500px;
    background: #082139;
    border-radius: 20px;
    overflow: hidden;
}

.container-paiment .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F2AE65;
    /*clip-path: circle(150px at 80% 20%);
    transition: 0.5s ease-in-out;*/
    clip-path: circle(270px at 80% -20%);
}

/*.container-paiment .card:hover:before {
    clip-path: circle(300px at 80% -20%);
}*/

.container-paiment .card:after {
    content: "MTL";
    position: absolute;
    top: 25%;
    left: -5%;
    font-size: 12em;
    font-weight: 800;
    font-style: italic;
    color: rgba(255, 255, 255, 0.04);

}

.container-paiment .card .imgBx {
    position: absolute;
    /*top: 40%;
    transform: translateY(-50%);*/
    z-index: 1000;
    width: 100%;
    height:  52%;
    /*transition: .5s;*/
    top: 0%;
    transform: translateY(-25%);
}

/*.container-paiment .card:hover .imgBx {
    top: 0%;
    transform: translateY(-25%);
    /* bug  */
/*}*/

.container-paiment .card .imgBx img {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%) rotate(20deg);
    width: 170px;
}

.container-paiment .card .contentBx {
    position: absolute;
    bottom: 0;
    width: 100%;
    /*height: 100px;*/
    height: 310px;
    text-align: center;
    transition: 1s;
    z-index: 90;
}

/*.container-paiment .card:hover .contentBx {
    height: 280px;
}*/

.container-paiment .card .contentBx h2 {
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
}

.container-paiment .card .contentBx .country {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    transition: .5s;
    /*opacity: 0;
    visibility: hidden;*/
}

/*.container-paiment .card:hover .contentBx .country {
    opacity: 1;
    visibility: visible;
    transition-delay: .5s;
}*/

.container-paiment .card .contentBx button {
    display: inline-block;
    padding: 7px 16px;
    background: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    color: #111;
   /* opacity: 0;*/
    transform: translateY(50px);
    transition: .5s;
    border:none!important
}

/*.container-paiment .card:hover .contentBx button {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .7s;
} */

</style>