<template lang="html">
  <b-container class="booking-box-tab">
      <div class="booking-tab">
        <h2 class="booking-title mb-25">{{$t('profileMenu.links.booking')}}</h2>
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{$t('homePage.hero.tracking.btn')}}:</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('homePage.hero.tracking.btn')"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

    <!-- table -->
    <vue-good-table
      styleClass="vgt-table bordered"
      theme="polar-bear"
        :line-numbers="true"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      class="mb-50"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >   
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.state)" class="state">
            {{ props.row.state }}
          </b-badge>
        </span>
        <span v-if="props.column.field === 'references'">
          <span v-if="props.row.reference">{{ props.row.reference }}</span>   
          <span v-else>{{ $t('myQuickQuotePage.labels.noReference') }}</span>         
        </span>
        <span v-if="props.column.field === 'comCon'">
          <b-button class="detail-button" v-b-modal="'comCon'" @click="updateComCon(props.row.commodityContainer)">{{ $t('myBLPage.view') }}</b-button>
        </span>
          <!-- Column: PDF -->
          <span v-if="props.column.field === 'doc'">
           <b-button class="detail-button" @click="downloadFile(props.row.pdf)" v-if="props.row.pdf">
            <b-icon icon="download" font-scale="0.9" class="text-body align-middle mr-25"></b-icon>
          </b-button>
          <span v-else>No booking file Yet</span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <b-icon icon="three-dots-vertical" font-scale="0.9" class="text-body align-middle mr-25"></b-icon>
              </template>
              <!-- <b-dropdown-item>
                <b-icon icon="eye" font-scale="0.9" class="mr-50"></b-icon>
                <span> {{$t('bookingpage.actions.viewQuotationBtn')}}</span>
              </b-dropdown-item> -->
              <b-dropdown-item @click="updateBooking(props.row._id)" v-if="props.row.state!='accepted'">
                <b-icon icon="pencil-square" font-scale="0.9" class="mr-50"></b-icon>
                <span> {{$t('myQuickQuotePage.actions.updateBtn')}}</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-icon icon="trash" font-scale="0.9" class="mr-50"></b-icon>
                <span @click="DeleteBooking(props.row._id)"> {{$t('myQuickQuotePage.actions.deleteBtn')}}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="duplicateBooking(props.row)" v-if="props.row.state=='accepted'">
                <b-icon icon="files" font-scale="0.9" class="mr-50"></b-icon>
                <span> {{$t('bookingpage.actions.duplicatBookingBtn')}}</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="props.row.state=='accepted'">
                <b-icon icon="files" font-scale="0.9" class="mr-50"></b-icon>
                <span> <router-link :to="getLinkNewBL(props.row._id)"> {{$t('bookingpage.actions.createBLBtn')}}</router-link></span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap bottom-table-style">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{$t('myQuickQuotePage.pagination.showing')}}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{$t('myQuickQuotePage.pagination.of')}} {{ props.total }} {{$t('myQuickQuotePage.pagination.entries')}} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <b-icon icon="chevron-compact-left"></b-icon>
              </template>
              <template #next-text>
                <b-icon icon="chevron-compact-right"></b-icon>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>  
    <b-modal id="comCon" size="xl" hide-footer centered  :title="$t('createBookingPageFromQuote.commodity/container')"><com :data="data"></com></b-modal>
           </div> 
    </b-container>
</template>
<script>
import axios from "axios";
import moment from 'moment';
import { VueGoodTable } from 'vue-good-table';
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import com from "./CommodityContainer.vue"
export default {
  name: "BookingTab",
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    com
  },
  data() {
      return {
        data : [],
      pageLength: 3,
      columns: [
            {
          label: 'Action',
          field: 'action',
        },
         {
          label: 'Document',
          field: 'doc',
        },
     {
          width: '100px',
          label: this.$t('createBookingPageFromQuote.state'),
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.stateSearch'),
          },
        },
        {
          width: '180px',
          label: this.$t('myQuickQuotePage.labels.reference'),
          field: 'references',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.reference'),
          },
        },
        {
          width: '190px',
          label: this.$t('myQuickQuotePage.labels.portFrom'),
          field: 'loadingPort',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.portFrom'),
          },
        },
        {
          width: '200px',
          label: this.$t('myQuickQuotePage.labels.portTo'),
          field: 'dischargePort',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.portTo'),
          },
        },
        {
          label: 'Incoterm',
          field: 'incoterm',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newQuotePage.routeSection.incotermSearch'),
          },
        },
        {
          width: '170px',
          label: this.$t('newQuotePage.routeSection.transportType'),
          field: 'transportType',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.transportType'),
          },
        },
        {
          width: '210px',
          label: this.$t('newQuotePage.routeSection.departureAddressCity'),
          field: 'departureAddressCity',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.departureAddressCity'),
          },
        },
        {
          width: '200px',
          label: this.$t('newQuotePage.routeSection.arrivalAddressCity'),
          field: 'arrivalAddressCity',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.departureAddressCity'),
          },
        },
        {
          width: '230px',
          label: this.$t('myQuickQuotePage.labels.commodities')+ "/ " +this.$t('myQuickQuotePage.labels.container'),
          field: 'comCon',
        },
        {
          width: '140px',
          label: this.$t('newQuotePage.dateSection.effectiveDate'),
          field: "effectiveDate",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newQuotePage.dateSection.effectiveDateSearch'),
          },
          formatFn: (value) => {
            return moment(value).format('MM/DD/YYYY');
          },
        },
        {
          width: '160px',
          label: this.$t('newQuotePage.dateSection.expiryDate'),
          field: "expiryDate",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newQuotePage.dateSection.expiryDateSearch'),
          },
          formatFn: (value) => {
            return moment(value).format('MM/DD/YYYY');
          },
        },
        {
          width: '160px',
          label: this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.customerCode'),
          field: "customerCode",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.customerCodeSearch'),
          }
        },
        {
          width: '180px',
          label: this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.companyName'),
          field: "companyName",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.companyNameSearch'),
          }
        },
        {
          width: '160px',
          label: this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.country'),
          field: "country",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.countrySearch'),
          }
        },
        {
          width: '200px',
          label: this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.postalPlaceholder'),
          field: "zipCode",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.postalSearch'),
          }
        },
        {
          width: '160px',
          label: this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.streetNamePlaceholder'),
          field: "streetName",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.streetNameSearch'),
          }
        },
        {
          width: '160px',
          label: this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.streetNumberPlaceholder'),
          field: "streetNumber",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.streetNumberSearch'),
          }
        },
        {
          width: '140px',
          label: this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.POBoxPlaceholder'),
          field: "POBox",
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.POBoxSearch'),
          }
        },
       
       
      ],
      searchTerm: '',
      disabled: true,
      rows:[]
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newLocale) {
        this.columns[3].label = this.$t('myQuickQuotePage.labels.reference');
        this.columns[3].filterOptions.placeholder = this.$t('myQuickQuotePage.search.reference');
        this.columns[4].label = this.$t('myQuickQuotePage.labels.portFrom');
        this.columns[4].filterOptions.placeholder = this.$t('myQuickQuotePage.search.portFrom');
        this.columns[5].label = this.$t('myQuickQuotePage.labels.portTo');
        this.columns[5].filterOptions.placeholder = this.$t('myQuickQuotePage.search.portTo');
        this.columns[6].filterOptions.placeholder = this.$t('newQuotePage.routeSection.incotermSearch');
        this.columns[7].label = this.$t('newQuotePage.routeSection.transportType');
        this.columns[7].filterOptions.placeholder = this.$t('myQuickQuotePage.search.transportType');
        this.columns[8].label = this.$t('newQuotePage.routeSection.departureAddressCity');
        this.columns[8].filterOptions.placeholder = this.$t('myQuickQuotePage.search.departureAddressCity');
        this.columns[9].label = this.$t('newQuotePage.routeSection.arrivalAddressCity');
        this.columns[9].filterOptions.placeholder = this.$t('myQuickQuotePage.search.arrivalAddressCity');
        this.columns[10].label = this.$t('myQuickQuotePage.labels.commodities')+ "/ " +this.$t('myQuickQuotePage.labels.container');
        this.columns[11].label = this.$t('newQuotePage.dateSection.effectiveDate');
        this.columns[11].filterOptions.placeholder = this.$t('newQuotePage.dateSection.effectiveDateSearch');
        this.columns[12].label = this.$t('newQuotePage.dateSection.expiryDate');
        this.columns[12].filterOptions.placeholder = this.$t('newQuotePage.dateSection.expiryDateSearch');

        this.columns[13].label = this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.customerCode');
        this.columns[13].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.customerCodeSearch');
        this.columns[14].label = this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.companyName');
        this.columns[14].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.companyNameSearch');
        this.columns[15].label = this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.country');
        this.columns[15].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.countrySearch');
        this.columns[16].label = this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.postalPlaceholder');
        this.columns[16].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.postalSearch');
        this.columns[17].label = this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.streetNamePlaceholder');
        this.columns[17].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.streetNameSearch');
        this.columns[18].label = this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.streetNumberPlaceholder');
        this.columns[18].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.streetNumberSearch');
        this.columns[19].label = this.$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.POBoxPlaceholder');
        this.columns[19].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.POBoxSearch');
        this.columns[20].label = this.$t('createBookingPageFromQuote.state');
        this.columns[20].filterOptions.placeholder = this.$t('createBookingPageFromQuote.search.stateSearch');
      }
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        accepted     : 'success',
        refused     : 'danger',
        pending     : 'warning',
      }
        return state => statusColor[state]
    },
  },
  async created() {
    await this.getAllDataBookings();
  },
  methods: {
    async getAllDataBookings() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/bookings?user=" +JSON.parse(localStorage.getItem("userData")).user._id
      );   
      this.rows = response.data.data.reverse();
    },
    updateBooking(id) {
      var link="/booking-edit?id="+id
      this.$router.push(link);
    },
    duplicateBooking(row){
      const form = {
      ...row,
      customer: JSON.parse(localStorage.getItem("userData")).user._id,
      quotation: row.quotation._id,
      state: "pending"
      };
      delete form._id;
      axios
        .post(
              'https://mtl-feeder-nodejs.onrender.com/api/bookings', form
            )
            .then((response) => {
               this.getAllDataBookings();
                this.$toast.open({
                    message: "booking added",
                    type: "success",
                    duration: 3000
                });
            })
            .catch((error) => {
            this.$toast.open({
                message: "Error during add",
                type: "error",
                duration: 3000
            });
            })
    },
    getLinkNewBL(id){
      return '/b-l?id='+id
    },
    DeleteBooking(id) {
          axios.put(`https://mtl-feeder-nodejs.onrender.com/api/bookings/${id}`, { active : false })
            .then(async (response) => {
              await this.getAllDataBookings();           
            })
            .catch((error) => {
            })
    },
    updateComCon(item) {
      this.data = item
    },
    downloadFile(url) {
      axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "File.pdf";  
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },
  }
}
</script>
<style lang="css">
.booking-box-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.booking-tab {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}
.booking-title {
  position: relative;
  text-align: center;
}
.disable-text {
  color: #8a8a8a;
}
.mr-25 {
  margin-right: 0 !important;
}
.booking-tab .vgt-table.polar-bear td {
  padding: .60em 0 .60em 0 !important;
  text-align: center;
  vertical-align: middle !important;
}
.booking-tab .state {
  padding : 6px 10px !important;
  border-radius: 5px !important
}
.booking-tab .list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.booking-tab .vgt-table.polar-bear td:nth-child(10) .list li {
  margin-bottom: 25px;
}
.booking-tab .vgt-table.polar-bear td:nth-child(10) {
 text-align: left;
 padding-left: 10px !important;
 padding-right: 10px !important;
}
.scrollable-cell {
  max-height: 100px; /* Set the maximum height for the cell content */
  overflow-y: auto; /* Add a vertical scrollbar if the content overflows */
}
.detail-button {
  border: 1px solid #525f7f !important;
  background-color: transparent !important;
  color: #525f7f !important;
  font-size: 14px !important;
}
.detail-button:hover {
  border: 1px solid #525f7f !important;
  background-color: transparent !important;
  color: #525f7f !important;
}
</style>