<template lang="html">
    <div>
        <Hero imgLink="/img/port.jpg"/>
        <ServicesSection />
    </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import ServicesSection from "../components/Services/ServicesSection.vue";

export default {
  name: "OurServices",
  components: {
    Hero,
    ServicesSection,
  },
  methods: {},
};
</script>
<style lang="css"></style>