<template>
    <vue-good-table
        styleClass="vgt-table bordered"
        theme="polar-bear"
        :columns="columns"
        :rows="rows"
        :group-options="{
            enabled: true
        }"
        :search-options="{
            enabled: true,
            externalQuery: searchTerm
        }"
        class="cargo-details mb-50"
    >
        <template
            slot="table-row"
            slot-scope="props"
        >
        </template>
    </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
export default {
    name: "Parties",
    components: {
        VueGoodTable
    },
    props: {
        data: Array
    },
    data() {
        return {
            rows: [],
            searchTerm: '',
            columns: [
                {
                    label: this.$t('newBLPage.partiesSection.companyName'),                
                    field: 'groupData.companyName',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.partiesSection.companyNameSearch'),
                    },
                }, 
                {
                    label: this.$t('newBLPage.partiesSection.address'),                
                    field: 'groupData.address',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.partiesSection.addressSearch'),
                    },
                }, 
                {
                    label: "Contact",                
                    field: 'groupData.contact',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.partiesSection.contactSearch'),
                    },
                }, 
                {
                    label: "Email",                
                    field: 'groupData.email',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.partiesSection.emailSearch'),
                    },
                }, 
                {
                    label: "Tel",                
                    field: 'groupData.tel',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newBLPage.partiesSection.telSearch'),
                    },
                }, 
            ]
        }
    },
    created() {
        this.data.forEach((element) => {
            this.rows.push({
                mode: 'span',
                label: element.groupName,
                html: false,
                children : [element]
            })
        })      
    }
}
</script>
