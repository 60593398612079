<template lang="html">
  <b-container class="quick-quote-box-tab">
      <div class="quick-quote-tab">
        <h2 class="quick-quote-title mb-25">{{$t('profileMenu.links.quickQuote.myQuickQuote')}}</h2>
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1 mb-0">{{$t('homePage.hero.tracking.btn')}}:</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('homePage.hero.tracking.btn')"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

    <!-- table -->
    <vue-good-table
      styleClass="vgt-table bordered"
      theme="polar-bear"
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm 
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      class="mb-50"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

      <!-- Column: Container -->
      <span v-if="props.column.field === 'container'">
        <ul class="list-containers">
          <li v-for="container in props.row.containers">{{ container.containerType }} - {{ container.quantity }} - {{ container.weight }}KG</li>
        </ul>
      </span>
       <span v-if="props.column.field === 'estimationPrice'">
        <span v-if="props.row.estimationPrice">{{props.row.estimationPrice}} TND</span>
        <span v-else>No price yet</span>
      </span>
      
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="table-action">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <b-icon icon="three-dots-vertical" font-scale="0.9" class="text-body align-middle mr-25"></b-icon>
              </template>
              <b-dropdown-item>
                <b-icon icon="eye" font-scale="0.9" class="mr-50"></b-icon>
                <span @click="ViewQuote(props.row._id)"> {{$t('myQuickQuotePage.actions.viewBtn')}}</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                <b-icon icon="pencil-square" font-scale="0.9" class="mr-50"></b-icon>
                <span> {{$t('myQuickQuotePage.actions.updateBtn')}}</span>
              </b-dropdown-item> -->
              <b-dropdown-item>
                <b-icon icon="trash" font-scale="0.9" class="mr-50"></b-icon>
                <span @click="DeleteQuote(props.row._id)"> {{$t('myQuickQuotePage.actions.deleteBtn')}}</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <b-icon icon="plus-circle" font-scale="0.9" class="mr-50"></b-icon>
                <span @click="CreateQuote(props.row._id)"> {{$t('myQuickQuotePage.actions.createQuoteBtn')}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap bottom-table-style">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{$t('myQuickQuotePage.pagination.showing')}}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{$t('myQuickQuotePage.pagination.of')}} {{ props.total }} {{$t('myQuickQuotePage.pagination.entries')}} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <b-icon icon="chevron-compact-left"></b-icon>
              </template>
              <template #next-text>
                <b-icon icon="chevron-compact-right"></b-icon>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>  

           </div> 
    </b-container>
</template>
<script>
import moment from 'moment';
import axios from "axios";
import { VueGoodTable } from 'vue-good-table';
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
export default {
  name: "InformationForm",
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
      return {
      pageLength: 3,
      columns: [
         {
          width: '5%',
          label: 'Action',
          field: 'action',
        },
        {
          label: this.$t('myQuickQuotePage.labels.portFrom'),
          field: 'loadingPort',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.portFrom'),
          },
        },
        {
          label: this.$t('myQuickQuotePage.labels.portTo'),
          field: 'dischargePort',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.portTo'),
          },
        },
        {
          label: this.$t('myQuickQuotePage.labels.commodity'),
          field: 'commodity',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.commodity'),
          },
        },
        {
          width: '25%',
          label: this.$t('myQuickQuotePage.labels.container'),
          field: 'container',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.container'),
          },
        },
        {
          width: '14%',
          label: this.$t('myQuickQuotePage.labels.pricingDate'),
          field: 'estimationDate',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.pricingDate'),
          },
          formatFn: (value) => {
            return moment(value).format('MM/DD/YYYY');
          },
        },
         {
          width: '14%',
          label: this.$t('myQuickQuotePage.labels.estimationPrice'),
          field: 'estimationPrice',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.labels.estimationPrice'),
          },
        },
       
      ],
      searchTerm: '',
      disabled: true,
      rows:[]
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newLocale) {
        this.columns[1].label = this.$t('myQuickQuotePage.labels.portFrom');
        this.columns[1].filterOptions.placeholder = this.$t('myQuickQuotePage.search.portFrom');
        this.columns[2].label = this.$t('myQuickQuotePage.labels.portTo');
        this.columns[2].filterOptions.placeholder = this.$t('myQuickQuotePage.search.portTo');
        this.columns[3].label = this.$t('myQuickQuotePage.labels.commodity');
        this.columns[3].filterOptions.placeholder = this.$t('myQuickQuotePage.search.commodity');
        this.columns[4].label = this.$t('myQuickQuotePage.labels.container');
        this.columns[4].filterOptions.placeholder = this.$t('myQuickQuotePage.search.container');
        this.columns[5].label = this.$t('myQuickQuotePage.labels.pricingDate');
        this.columns[5].filterOptions.placeholder = this.$t('myQuickQuotePage.search.pricingDate');
      }
    }
  },
  async created() {
    await this.getAllDataQuote();
  },
  methods: {
    async getAllDataQuote() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/instant-estimations?user=" + JSON.parse(localStorage.getItem("userData")).user._id
      );     
      this.rows = response.data.data.reverse();
    },
    ViewQuote(id) {
      this.$router.push({name: 'ViewInstantQuotation',query: { id }});
    },
    async DeleteQuote(id) {
      axios
        .delete(
          `https://mtl-feeder-nodejs.onrender.com/api/instant-estimations/${id}`
        )
        .then((response) => {
          this.getAllDataQuote();
        })
        .catch((error) => {
        })
    },
    async CreateQuote(id) {
      this.$router.push({name: 'Quotation',query: { id }});
    }
  }
}
</script>
<style lang="css">
.quick-quote-box-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quick-quote-box-tab .quick-quote-tab {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1rem;
  width: 100%;
  height: auto;
}
.quick-quote-box-tab .quick-quote-title {
  position: relative;
  text-align: center;
}
.quick-quote-box-tab .disable-text {
  color: #8a8a8a;
  cursor: not-allowed !important;
}
.quick-quote-box-tab .vgt-table.polar-bear td {
  padding: .60em 0 .60em 0 !important;
  text-align: center;
  vertical-align: middle !important;
}
.quick-quote-box-tab .list-containers {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mr-25 {
  margin-right: 0 !important;
}
</style>