
<template lang="html">
  <b-container fluid class="login-section mb-100">
    <b-row class="login-row">
        <b-col class="login-form" lg="4" md="6" offset-md="3" offset-lg="4" offset-sm="2" sm="8">
        <b-row>
                <b-col col lg="6" md="12" sm="12" cols="12">
                <h2>{{$t('ForgetPassword.title')}}</h2>
              </b-col>
              <b-col class="text-right login-redirection" col lg="6" md="12" sm="12" cols="12">
                <router-link to='/login'><font-awesome-icon icon="arrow-right" />  {{$t('loginPage.title')}}</router-link>
              </b-col> </b-row>
        <b-container>
          <b-row>
            <input v-model="fullName" type="text" :placeholder="$t('ForgetPassword.placeholderUsername')" required class="input-outline-yellow input-color-white"></input>
          </b-row>
          <b-row>
            <input v-model="email" type="email" :placeholder="$t('ForgetPassword.placeholderEmail')" required class="input-outline-yellow input-color-white"></input>
          </b-row>
          
          <b-row>
            <b-button type="button" block class="login-button" @click="send()">{{$t('ForgetPassword.ForgetPasswordButton')}}</b-button>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from "axios";

export default {
  name: "LoginForm",
  components: {},
  data() {
    return {
      email: "",
      fullName: "",
    };
  },

  methods: {
      async send() {
      try {
          await axios
        .post(
          "https://mtl-feeder-nodejs.onrender.com/api/account/password-request",
           { email: this.email, fullName: this.fullName }
        )
        .then((response) => {
          this.email="";
          this.fullName="";
           this.$toast.open({
            message: "Email send",
            type: "success",
            duration: 7000
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: "Please verify your information",
            type: "error",
            duration: 7000
          });
        });
       
      } catch (error) {
        console.error(error);
      }
    },


    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>
<style lang="css" scoped>
.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  color: white;
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 2.5rem;
  /* margin: 1rem; */
  width: 40%;
  height: auto;
  margin-top: -10rem !important;
}
.login-section .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 1.7rem !important;
  margin-bottom: 1.7rem !important;
}
.login-button {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-redirection {
  display: flex;
  align-items: center;
  justify-content: right;
}
.login-button:hover, .login-button:active {
  background-color: #ffd500e7 !important;
}
@media only screen and (max-width: 1193px) {
  .login-redirection {
    display: none !important;
  }
}
@media only screen and (max-width: 575px) {
  .login-form {
    width: 100%;
    /* margin-left: 0% !important; */
  }
}
</style>