<template lang="html">
    <div>
        <Hero imgLink="/img/port.jpg"/>
        <SolutionSection />
    </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import SolutionSection from "../components/Solutions/SolutionSection.vue";

export default {
  name: "OurSolution",
  components: {
    Hero,
    SolutionSection,
  },
  methods: {},
};
</script>
<style lang="css"></style>