<template >
  <b-container class="quotation-box-form">
    <div class="quotation-form" v-if="role === 'customer'">
      <b-row class="text-right mb-25 mr-25">
        <b-col col lg="12" md="12" sm="12" cols="12">
          <router-link to="/new-instant"
            ><b-icon icon="plus-circle"></b-icon>
            {{ $t("profileMenu.links.quickQuote.newQuickQuote") }}</router-link
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-tabs v-model="tabIndex" content-class="mt-3" align="center">
              <b-tab active>
                <template #title>
                  <span class="cercle-steps"> <span>1</span> </span>
                  <span>{{ $t("newQuickQuotePage.step1") }}</span>
                </template>
                <div class="p-3">
                 <p>All fields are required </p>
                  <b-row>
                    <b-col class="words-divider" cols="12">
                      <p>{{ $t("newQuickQuotePage.routeSection.title") }}</p>
                    </b-col>
                  </b-row>
                  <br />
                  <b-form
                  >
                    <b-row>
                      <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                        <b-form-group
                          :label="$t('newQuickQuotePage.routeSection.fromPort')"
                          label-for="country-from"
                        >
                          <v-select
                            id="country-from"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.routeSection.portPlaceholder'
                              )
                            "
                            v-model="form.loadingPort"
                            :options="departurePorts"
                            required
                            class="input-outline-bleu-border"
                            @input="updateArrivalPorts"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                        <b-form-group
                          :label="$t('newQuickQuotePage.routeSection.ToPort')"
                          label-for="contry-to"
                        >
                          <v-select
                            id="contry-to"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.routeSection.portPlaceholder'
                              )
                            "
                            v-model="form.dischargePort"
                            :options="arrivalPorts"
                            required
                            class="input-outline-bleu-border"
                            @input="updateDeparturePorts"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                        <b-form-group
                          :label="$t('newQuotePage.routeSection.incoterm')"
                          label-for="Incoterm"
                        >
                          <v-select
                            id="Incoterm"
                            :placeholder="
                              $t(
                                'newQuotePage.routeSection.incotermPlaceholder'
                              )
                            "
                            v-model="form.incoterm"
                            :options="IncotermOptions"
                            required
                            class="input-outline-bleu-border"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        lg="6"
                        md="6"
                        sm="6"
                        xs="6"
                        cols="12"
                        v-if="form.incoterm == 'EXW' || form.incoterm == 'FCA'"
                      >
                        <b-form-group
                          :label="$t('newQuotePage.routeSection.transportType')"
                          label-for="Transport"
                        >
                          <v-select
                            id="Transport"
                            :placeholder="
                              $t(
                                'newQuotePage.routeSection.transportTypePlaceholder'
                              )
                            "
                            v-model="form.transportType"
                            :options="TransportLoadingOptions"
                            required
                            class="input-outline-bleu-border"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        lg="6"
                        md="6"
                        sm="6"
                        xs="6"
                        cols="12"
                        v-if="
                          form.transportType == 'Transport Marchant' &&
                          (form.incoterm == 'EXW' || form.incoterm == 'FCA')
                        "
                      >
                        <b-form-group
                          :label="
                            $t('newQuotePage.routeSection.departureAddressCity')
                          "
                          label-for="departureAddressCity"
                        >
                          <b-form-input
                            v-model="form.departureAddressCity"
                            :placeholder="
                              $t(
                                'newQuotePage.routeSection.departureAddressCity'
                              )
                            "
                            required
                            class="input-outline-bleu-border"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="6"
                        md="6"
                        sm="6"
                        xs="6"
                        cols="12"
                        v-if="form.incoterm == 'DAP'"
                      >
                        <b-form-group
                          :label="$t('newQuotePage.routeSection.transportType')"
                          label-for="Transport"
                        >
                          <v-select
                            id="Transport"
                            :placeholder="
                              $t(
                                'newQuotePage.routeSection.transportTypePlaceholder'
                              )
                            "
                            v-model="form.transportType"
                            :options="TransportLoadingOptions"
                            required
                            class="input-outline-bleu-border"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        lg="6"
                        md="6"
                        sm="6"
                        xs="6"
                        cols="12"
                        v-if="
                          form.transportType == 'Transport Marchant' &&
                          form.incoterm == 'DAP'
                        "
                      >
                        <b-form-group
                          :label="
                            $t('newQuotePage.routeSection.arrivalAddressCity')
                          "
                          label-for="arrivalAddressCity"
                        >
                          <b-form-input
                            v-model="form.arrivalAddressCity"
                            :placeholder="
                              $t('newQuotePage.routeSection.arrivalAddressCity')
                            "
                            required
                            class="input-outline-bleu-border"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-25">
                      <b-col class="words-divider" cols="12">
                        <p>
                          {{ $t("newQuickQuotePage.commoditySection.title") }}
                        </p>
                      </b-col>
                    </b-row>
                    <br />
                    <b-row>
                      <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                        <b-form-group
                          :label="
                            $t(
                              'newQuickQuotePage.commoditySection.commodityLabel'
                            )
                          "
                          label-for="commodity"
                        >
                          <v-select
                            id="commodity"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.commoditySection.commodityPlaceholder'
                              )
                            "
                            v-model="form.commodity"
                            :options="commodities"
                            required
                            class="input-outline-bleu-border"
                          />
                        </b-form-group>
                        <b-form-group>
                          <b-form-radio-group
                            v-model="selectedOption"
                            class="commodity-radio-group"
                            @change="updateSelectedOption"
                          >
                            <!--<b-form-radio value="radio1">
                                                        {{ $t('newQuickQuotePage.commoditySection.radio1') }}
                                                    </b-form-radio>-->
                            <b-form-radio value="radio2">
                              {{
                                $t("newQuickQuotePage.commoditySection.radio2")
                              }}
                            </b-form-radio>
                            <b-form-radio value="radio3">
                              {{
                                $t("newQuickQuotePage.commoditySection.radio3")
                              }}
                            </b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </b-col>

                      <!--<b-col lg="4" md="4" sm="6" xs="12" cols="12" v-if="selectedOption === 'radio1'">
                                                <b-form-group :label="$t('newQuickQuotePage.commoditySection.TemperatureLabel')" label-for="temperature">
                                                    <b-form-input
                                                    id="temperature"
                                                    :placeholder="$t('newQuickQuotePage.commoditySection.TemperaturePlaceholder')"
                                                    v-model="form.TemperatureControl"
                                                    class="input-outline-bleu-border"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>-->

                      <b-col
                        lg="4"
                        md="4"
                        sm="6"
                        xs="12"
                        cols="12"
                        v-if="selectedOption === 'radio2'"
                      >
                        <b-form-group
                          :label="
                            $t('newQuickQuotePage.commoditySection.HeightLabel')
                          "
                          label-for="height"
                        >
                          <b-form-input
                            id="height"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.commoditySection.HeightPlaceholder'
                              )
                            "
                            v-model="form.specialEquipment.height"
                            class="input-outline-bleu-border"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col
                        lg="4"
                        md="4"
                        sm="6"
                        xs="12"
                        cols="12"
                        v-if="selectedOption === 'radio2'"
                      >
                        <b-form-group
                          :label="
                            $t('newQuickQuotePage.commoditySection.WidthLabel')
                          "
                          label-for="width"
                        >
                          <b-form-input
                            id="width"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.commoditySection.WidthPlaceholder'
                              )
                            "
                            v-model="form.specialEquipment.width"
                            class="input-outline-bleu-border"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="4"
                        md="4"
                        sm="6"
                        xs="12"
                        cols="12"
                        v-if="selectedOption === 'radio2'"
                      >
                        <b-form-group
                          :label="
                            $t('newQuickQuotePage.commoditySection.LengthLabel')
                          "
                          label-for="length"
                        >
                          <b-form-input
                            id="length"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.commoditySection.LengthPlaceholder'
                              )
                            "
                            v-model="form.specialEquipment.length"
                            class="input-outline-bleu-border"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col
                        lg="6"
                        md="6"
                        sm="6"
                        xs="12"
                        cols="12"
                        v-if="selectedOption === 'radio3'"
                      >
                        <b-form-group
                          :label="
                            $t('newQuickQuotePage.commoditySection.classeLabel')
                          "
                          label-for="classe"
                        >
                          <v-select
                            id="classe"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.commoditySection.classePlaceholder'
                              )
                            "
                            v-model="form.commodityClass"
                            :options="classes"
                            class="input-outline-bleu-border"
                            @input="updateClasse"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="6"
                        md="6"
                        sm="6"
                        xs="12"
                        cols="12"
                        v-if="selectedOption === 'radio3'"
                      >
                        <b-form-group
                          :label="
                            $t('newQuickQuotePage.commoditySection.unLabel')
                          "
                          label-for="un"
                        >
                          <v-select
                            id="un"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.commoditySection.unPlaceholder'
                              )
                            "
                            v-model="form.commodityUn"
                            :options="uns"
                            class="input-outline-bleu-border"
                            @input="updateUn"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-25">
                      <b-col class="words-divider" cols="12">
                        <p>
                          {{ $t("newQuickQuotePage.containerSection.title") }}
                        </p>
                      </b-col>
                    </b-row>
                    <br />
                    <b-row
                      v-for="(item, index) in form.containers"
                      :key="index"
                    >
                      <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                        <b-form-group
                          :label="
                            $t(
                              'newQuickQuotePage.containerSection.containerTypeLabel'
                            )
                          "
                          label-for="container"
                        >
                          <v-select
                            id="container"
                            :placeholder="
                              $t(
                                'newQuickQuotePage.containerSection.containerTypePlaceholder'
                              )
                            "
                            v-model="item.containerType"
                            :options="containers"
                            required
                            class="input-outline-bleu-border"
                            @input="updateContainer(index, item.containerType)"
                          />
                        </b-form-group>

                        <b-form-group>
                          <b-form-checkbox v-model="item.isOwnedByShipper">{{
                            $t(
                              "newQuickQuotePage.containerSection.checkboxShipper"
                            )
                          }}</b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" md="3" sm="6" xs="12" cols="12">
                        <b-form-group
                          :label="
                            $t(
                              'newQuickQuotePage.containerSection.containerQuantityLabel'
                            )
                          "
                          label-for="Container-qte"
                        >
                          <b-form-spinbutton
                            id="Container-qte"
                            v-model="item.quantity"
                            min="1"
                            max="100000"
                            class="input-outline-bleu-border"
                          ></b-form-spinbutton>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" md="3" sm="6" xs="12" cols="12">
                        <b-form-group
                          :label="
                            $t(
                              'newQuickQuotePage.containerSection.approximateWeightLabel'
                            )
                          "
                          label-for="Container-quantity"
                        >
                          <b-form-input
                            placeholder="18000,000 KG"
                            v-model="item.weight"
                            @change="financial(item)"
                            required
                            id="Container-quantity"
                            disable
                            class="input-outline-bleu-border"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="index > 0">
                        <div class="delete-container-div">
                          <button
                            class="delete-container"
                            @click="deleteContainer(index)"
                          >
                            <b-icon icon="plus-circle"></b-icon>
                            {{
                              $t("newQuickQuotePage.containerSection.DeleteBtn")
                            }}
                          </button>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <button class="addContainer" @click="addContainer()">
                          <b-icon icon="plus-circle"></b-icon>
                          {{
                            $t(
                              "newQuickQuotePage.containerSection.addContainer"
                            )
                          }}
                        </button>
                      </b-col>
                    </b-row>
                    <b-row class="mt-25">
                      <b-col class="words-divider" cols="12">
                        <p>{{ $t("newQuickQuotePage.dateSection.title") }}</p>
                      </b-col>
                    </b-row>
                    <br />
                    <b-row >
                      <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                        <b-form-group
                          :label="$t('newQuotePage.dateSection.effectiveDate')"
                          label-for="effectiveDate"
                        >
                          <b-form-input
                            id="effectiveDate"
                            type="date"
                            v-model="form.effectiveDate"
                            required
                            class="input-outline-bleu-border"
                          >
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                        <b-form-group
                          :label="$t('newQuotePage.dateSection.expiryDate')"
                          label-for="expiryDate"
                        >
                          <b-form-input
                            id="expiryDate"
                            type="date"
                            v-model="form.expiryDate"
                            required
                            class="input-outline-bleu-border"
                          >
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- <b-row class="addRequest mt-25 mb-50">
                                        <b-col offset-lg="4" offset-md="2" offset-sm="2">
                                             <p><b-icon icon="plus-circle"></b-icon> CREATE ADDITIONAL REQUEST</p>
                                        </b-col>
                                    </b-row>     -->
                    <div class="text-center mt-50">
                      <b-button-group class="mt-2">
                        <b-button class="button-yellow prl-30" @click="tabIndex++"
                          >{{ $t("next") }}</b-button
                        >
                      </b-button-group>
                    </div>
                  </b-form>
                </div>
              </b-tab>

              <b-tab title="Step 2">
                <template #title>
                  <span>
                    <span class="cercle-steps"> <span>2</span> </span>
                    <span>{{ $t("newQuickQuotePage.step2") }}</span>
                  </span>
                </template>

                <div
                  class="p-3"
                  v-if="
                    form.loadingPort &&
                    form.dischargePort &&
                    form.containers.every(
                      (container) => container.containerType !== null
                    ) &&
                    form.effectiveDate &&
                    form.expiryDate &&
                    form.commodity &&
                    form.incoterm
                  "
                >
                  <b-row>
                    <b-col class="words-divider" cols="12">
                      <p>{{ $t("newQuickQuotePage.routeSection.title") }}</p>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                      <div v-if="form.departurePortAgent">
                        <span class="subTitle-forms">
                          {{ $t("newQuickQuotePage.routeSection.fromPort") }}:
                        </span>
                        <span>
                          {{ form.loadingPort }}
                        </span>
                      </div>
                    </b-col>

                    <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                      <div v-if="form.arrivalPortAgent">
                        <span class="subTitle-forms">
                          {{ $t("newQuickQuotePage.routeSection.ToPort") }}:
                        </span>
                        <span>
                          {{ form.dischargePort }}
                        </span>
                      </div>
                    </b-col>

                    <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                      <div v-if="form.incoterm">
                        <span class="subTitle-forms">
                          {{ $t("newQuotePage.routeSection.incoterm") }}:
                        </span>
                        <span>
                          {{ form.incoterm }}
                        </span>
                      </div>
                    </b-col>

                    <b-col
                      lg="6"
                      md="6"
                      sm="6"
                      xs="6"
                      cols="12"
                      v-if="form.incoterm == 'EXW' || form.incoterm == 'FCA'"
                    >
                      <div v-if="form.transportType">
                        <span class="subTitle-forms">
                          {{ $t("newQuotePage.routeSection.transportType") }}:
                        </span>
                        <span>
                          {{ form.transportType }}
                        </span>
                      </div>
                    </b-col>

                    <b-col
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      cols="12"
                      v-if="
                        form.transportType == 'Transport Marchant' &&
                        (form.incoterm == 'EXW' || form.incoterm == 'FCA')
                      "
                    >
                      <div v-if="form.departureAddressCity">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuotePage.routeSection.departureAddressCity"
                            )
                          }}:
                        </span>
                        <span>
                          {{ form.departureAddressCity }}
                        </span>
                      </div>
                    </b-col>

                    <b-col
                      lg="6"
                      md="6"
                      sm="6"
                      xs="6"
                      cols="12"
                      v-if="form.incoterm == 'DAP'"
                    >
                      <div v-if="form.transportType">
                        <span class="subTitle-forms">
                          {{ $t("newQuotePage.routeSection.transportType") }}:
                        </span>
                        <span>
                          {{ form.transportType }}
                        </span>
                      </div>
                    </b-col>

                    <b-col
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      cols="12"
                      v-if="
                        form.transportType == 'Transport Marchant' &&
                        form.incoterm == 'DAP'
                      "
                    >
                      <div v-if="form.arrivalAddressCity">
                        <span class="subTitle-forms">
                          {{
                            $t("newQuotePage.routeSection.arrivalAddressCity")
                          }}:
                        </span>
                        <span>
                          {{ form.arrivalAddressCity }}
                        </span>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row class="mt-25">
                    <b-col class="words-divider" cols="12">
                      <p>
                        {{ $t("newQuickQuotePage.commoditySection.title") }}
                      </p>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                      <div v-if="form.commodity">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuickQuotePage.commoditySection.commodityLabel"
                            )
                          }}:
                        </span>
                        <span>
                          {{ form.commodity }}
                        </span>
                      </div>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" xs="12" cols="12">
                      <div v-if="form.TemperatureControl">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuickQuotePage.commoditySection.TemperatureLabel"
                            )
                          }}:
                        </span>
                        <span>
                          {{ form.TemperatureControl }}
                        </span>
                      </div>
                    </b-col>
                    <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                      <div v-if="form.specialEquipment.height">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuickQuotePage.commoditySection.HeightLabel"
                            )
                          }}:
                        </span>
                        <span>
                          {{ form.specialEquipment.height }}
                        </span>
                      </div>
                    </b-col>
                    <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                      <div v-if="form.specialEquipment.width">
                        <span class="subTitle-forms">
                          {{
                            $t("newQuickQuotePage.commoditySection.WidthLabel")
                          }}:
                        </span>
                        <span>
                          {{ form.specialEquipment.width }}
                        </span>
                      </div>
                    </b-col>
                    <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                      <div v-if="form.specialEquipment.length">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuickQuotePage.commoditySection.LengthLabel"
                            )
                          }}:
                        </span>
                        <span>
                          {{ form.specialEquipment.length }}
                        </span>
                      </div>
                    </b-col>
                    <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                      <div v-if="form.commodityClass">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuickQuotePage.commoditySection.classeLabel"
                            )
                          }}:
                        </span>
                        <span>
                          {{ form.commodityClass }}
                        </span>
                      </div>
                    </b-col>
                    <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                      <div v-if="form.commodityUn">
                        <span class="subTitle-forms">
                          {{
                            $t("newQuickQuotePage.commoditySection.unLabel")
                          }}:
                        </span>
                        <span>
                          {{ form.commodityUn }}
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-25">
                    <b-col class="words-divider" cols="12">
                      <p>
                        {{ $t("newQuickQuotePage.containerSection.title") }}
                      </p>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row v-for="(item, index) in form.containers" :key="index">
                    <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                      <div v-if="item.containerType">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuickQuotePage.containerSection.containerTypeLabel"
                            )
                          }}:
                        </span>
                        <span>
                          {{ item.containerType }}
                        </span>
                      </div>
                    </b-col>
                    <b-col>
                      <div v-if="item.quantity">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuickQuotePage.containerSection.containerQuantityLabel"
                            )
                          }}:
                        </span>
                        <span>
                          {{ item.quantity }}
                        </span>
                      </div>
                    </b-col>

                    <b-col>
                      <div v-if="item.weight">
                        <span class="subTitle-forms">
                          {{
                            $t(
                              "newQuickQuotePage.containerSection.approximateWeightLabel"
                            )
                          }}:
                        </span>
                        <span>
                          {{ item.weight }}
                        </span>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row class="mt-25">
                    <b-col class="words-divider" cols="12">
                      <p>{{ $t("newQuickQuotePage.dateSection.title") }}</p>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                      <div v-if="form.effectiveDate">
                        <span class="subTitle-forms">
                          {{ $t("newQuotePage.dateSection.effectiveDate") }}:
                        </span>
                        <span>
                          {{ form.effectiveDate }}
                        </span>
                      </div>
                    </b-col>
                    <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                      <div v-if="form.expiryDate">
                        <span class="subTitle-forms">
                          {{ $t("newQuotePage.dateSection.expiryDate") }}:
                        </span>
                        <span>
                          {{ form.expiryDate }}
                        </span>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row class="btn-footer-forms mt-50">
                    <b-col lg="2" md="3" sm="4" xs="5" cols="12">
                      <b-button
                        @click="onSubmit()"
                        class="btnSubmit"
                        type="submit"
                      >
                        {{ $t("newQuickQuotePage.valideteBtn") }}
                      </b-button>
                    </b-col>
                    <b-col lg="2" md="3" sm="4" xs="5" cols="12" class="ml-25">
                      <b-button
                        @click="onReset()"
                        class="btnReset"
                        type="reset"
                      >
                        {{ $t("newQuickQuotePage.resetBtn") }}</b-button
                      >
                    </b-col>
                  </b-row>
                </div>
                <div class="p-3" v-else>
                  <p class="text-center">{{ $t("newBLPage.step2Desc") }}</p>

                   <ul class="list-group list-group-flush">
                        <li v-for="field in missingFields" :key="field" class="list-group-item">
                          {{ $t(`newQuotePage.missingField.${field}`) }}
                        </li>
                    </ul>

                  <div class="text-center mt-50">
                      <b-button-group class="">
                        <b-button class="button-yellow prl-30" @click="tabIndex--">{{ $t("previous") }}</b-button>
                      </b-button-group>
                    </div>
                </div>

              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
      <br />
    </div>
    <div class="quotation-form text" v-else>{{ $t("messageAccess") }}</div>
  </b-container>
</template>
<script>
import axios from "axios";
export default {
  name: "QuotationForm",
  components: {},
  data() {
    return {
      tabIndex: 1,
      role: JSON.parse(localStorage.getItem("userData")).user.role,
      quoteId: this.$route.query.id,
      form: {
        customer: JSON.parse(localStorage.getItem("userData")).user._id,
        arrivalPortAgent: null,
        departurePortAgent: null,
        loadingPort: null,
        dischargePort: null,
        incoterm: null,
        transportType: null,
        departureAddressCity: null,
        TransportDischarge: null,
        arrivalAddressCity: null,
        commodity: null,
        /*requiresTemperatureControl: false,*/
        requiresSpecialEquipment: false,
        dangerousCargo: false,
        commodityClass: null,
        commodityUn: null,
        TemperatureControl: null,
        specialEquipment: {
          height: null,
          width: null,
          length: null,
        },
        containers: [
          {
            containerType: null,
            quantity: 1,
            weight: "1000.000",
            isOwnedByShipper: false,
          },
        ],
        effectiveDate: null,
        expiryDate: null,
      },
      ports: [],
      departurePorts: [],
      arrivalPorts: [],
      IncotermOptions: ["FOB", "CFR", "EXW", "FCA", "DAP"],
      TransportLoadingOptions: ["Transport Marchant", "Transport Carrier"],
      commodities: [],
      classes: [],
      uns: [],
      containers: [],
      selectedOption: null,
      departurePortId: null,
      arrivalPortId: null,
    };
  },
  async created() {
    if (this.quoteId) {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/instant-estimations",
        {
          params: { id: this.quoteId },
        }
      );
      const quote = response.data.data;
      this.form.arrivalPortAgent = quote.arrivalPortAgent.agent.user;
      this.form.departurePortAgent = quote.departurePortAgent.agent.user;
      this.form.loadingPort = quote.loadingPort;
      this.form.dischargePort = quote.dischargePort;
      this.form.commodity = quote.commodity;
      this.form.requiresTemperatureControl = quote.requiresTemperatureControl;
      this.form.requiresSpecialEquipment = quote.requiresSpecialEquipment;
      this.form.dangerousCargo = quote.dangerousCargo;
      if (quote.requiresTemperatureControl) {
        this.selectedOption = "radio1";
        this.form.TemperatureControl = quote.TemperatureControl;
      }
      if (quote.requiresSpecialEquipment) {
        this.selectedOption = "radio2";
        this.form.specialEquipment.height = quote.specialEquipment.height;
        this.form.specialEquipment.width = quote.specialEquipment.width;
        this.form.specialEquipment.length = quote.specialEquipment.length;
      }
      if (quote.dangerousCargo) {
        this.selectedOption = "radio3";
        this.form.commodityClass = quote.commodityClass;
        this.form.commodityUn = quote.commodityUn;
      }
      this.form.containers = quote.containers;
      this.form.containers.forEach((container) => {
        delete container._id;
      });
    }
    this.getPorts();
    this.getCommodities();
    this.getClasses();
    this.getUns();
    this.getContainers();
  },
 computed: {
    missingFields() {
      const fields = [];
      const requiredFields = [
        "loadingPort",
        "dischargePort",
        "commodity",
        "containers.containerType",
        "effectiveDate",
        "expiryDate",
         "incoterm",
      ];

      for (const field of requiredFields) {
        const path = field.split("."); // Handle nested fields like containers.containerType
        let value = this.form;
        for (const part of path) {
          value = value[part];
          if (value === null) {
            fields.push(field);
            break; // Stop iterating for this field if a null value is found
          }
        }
      }

      return fields;
    },
    allFieldsFilled() {
      return this.missingFields.length === 0;
    },
  },
  methods: {
    onSubmitRequired() {
      console.log("here");
    },
    async getPorts() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/ports"
      );
      this.ports = response.data.data.map((item) => ({
        _id: item._id,
        portAgent: item.portAgent._id,
        title: item.title,
      }));
      this.departurePorts = this.ports.map((item) => ({
        label: item.title,
        value: { _id: item._id, portAgent: item.portAgent, title: item.title },
      }));
      this.arrivalPorts = this.ports.map((item) => ({
        label: item.title,
        value: { _id: item._id, portAgent: item.portAgent, title: item.title },
      }));
      if (this.quoteId) {
        this.departurePorts = this.departurePorts.filter(
          (item) => item.value.portAgent !== this.form.arrivalPortAgent
        );
        this.arrivalPorts = this.arrivalPorts.filter(
          (item) => item.value.portAgent !== this.form.departurePortAgent
        );
      }
    },
    async getCommodities() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/commodities"
      );
      this.commodities = response.data.data.map((item) => item.title);
    },
    async getClasses() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/classes"
      );
      this.classes = response.data.data.map((item) => ({
        label: item.title,
        value: { _id: item._id, title: item.title },
      }));
    },
    async getUns() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/uns"
      );
      this.uns = response.data.data.map((item) => ({
        label: item.title,
        value: { _id: item._id, title: item.title },
      }));
    },
    async getContainers() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/containers"
      );
              const uniqueSizes = new Set();
        const uniqueArray = response.data.data.filter(obj => {
          if (!uniqueSizes.has(obj.sizeType)) {
            uniqueSizes.add(obj.sizeType);
            return true;
          }
          return false;
        });

      this.containers = uniqueArray.map((item) => ({
        // label: "Container " + item.type + "/" + item.size, 
                    label: "Container " + item.sizeType ,
        value: { _id: item._id, title: item.title },
      }));
    },
    updateArrivalPorts() {
      if (this.form.loadingPort) {
        this.form.departurePortAgent = this.form.loadingPort.value.portAgent;
        this.form.loadingPort = this.form.loadingPort.value.title;
        this.arrivalPorts = this.ports
          .filter((item) => item.title !== this.form.loadingPort)
          .map((item) => ({
            label: item.title,
            value: {
              _id: item._id,
              portAgent: item.portAgent,
              title: item.title,
            },
          }));
      }
    },
    updateDeparturePorts() {
      if (this.form.dischargePort) {
        this.form.arrivalPortAgent = this.form.dischargePort.value.portAgent;
        this.form.dischargePort = this.form.dischargePort.value.title;
        this.departurePorts = this.ports
          .filter((item) => item.title !== this.form.dischargePort)
          .map((item) => ({
            label: item.title,
            value: {
              _id: item._id,
              portAgent: item.portAgent,
              title: item.title,
            },
          }));
      }
    },
    updateSelectedOption() {
      if (this.selectedOption === "radio1") {
        this.form.requiresTemperatureControl = true;
        this.form.requiresSpecialEquipment = false;
        this.form.dangerousCargo = false;
        this.form.commodityClass = null;
        this.form.commodityUn = null;
        this.form.specialEquipment.height = null;
        this.form.specialEquipment.width = null;
        this.form.specialEquipment.length = null;
      } else if (this.selectedOption === "radio2") {
        this.form.requiresTemperatureControl = false;
        this.form.requiresSpecialEquipment = true;
        this.form.dangerousCargo = false;
        this.form.commodityClass = null;
        this.form.commodityUn = null;
        this.form.TemperatureControl = null;
      } else if (this.selectedOption === "radio3") {
        this.form.requiresTemperatureControl = false;
        this.form.requiresSpecialEquipment = false;
        this.form.dangerousCargo = true;
        this.form.specialEquipment.height = null;
        this.form.specialEquipment.width = null;
        this.form.specialEquipment.length = null;
        this.form.TemperatureControl = null;
      }
    },
    updateClasse() {
      this.form.commodityClass = this.form.commodityClass.value.title;
    },
    updateUn() {
      this.form.commodityUn = this.form.commodityUn.value.title;
    },
    updateContainer(index, container) {
      this.form.containers[index].containerType = container.label;
    },
    financial(item) {
      item.weight = Number.parseFloat(item.weight).toFixed(3);
      return Number.parseFloat(item.weight).toFixed(3);
    },
    addContainer() {
      this.form.containers.push({
        containerType: null,
        quantity: 1,
        weight: "1000.000",
        isOwnedByShipper: false,
      });
    },
    deleteContainer(index) {
      this.form.containers.splice(index, 1);
    },
    onReset() {
      this.form.loadingPort = null;
      this.form.dischargePort = null;
      this.form.incoterm = null;
      this.form.commodity = null;
      this.form.requiresTemperatureControl = false;
      this.form.requiresSpecialEquipment = false;
      this.form.dangerousCargo = false;
      this.form.TemperatureControl = null;
      this.form.commodityClass = null;
      this.form.commodityUn = null;
      this.form.specialEquipment.height = null;
      this.form.specialEquipment.width = null;
      this.form.specialEquipment.length = null;
      this.form.containers = [
        {
          containerType: null,
          quantity: 1,
          weight: "1000.000",
          isOwnedByShipper: false,
        },
      ];
      (this.form.transportType = null),
        (this.form.departureAddressCity = null),
        (this.form.TransportDischarge = null),
        (this.form.arrivalAddressCity = null),
        (this.form.effectiveDate = null);
      this.form.expiryDate = null;
      this.selectedOption = null;
    },
    onSubmit() {
      const filteredObj = Object.fromEntries(
        Object.entries(this.form).filter(([key, value]) => value !== null)
      );
      if (this.form.requiresSpecialEquipment === false) {
        delete filteredObj.specialEquipment;
      }
      axios
        .post(
          "https://mtl-feeder-nodejs.onrender.com/api/quotations",
          filteredObj
        )
        .then((response) => {
          this.$toast.open({
            message: this.$t("newQuickQuotePage.quoteAdded"),
            type: "success",
            duration: 7000,
          });
          this.$router.push("/my-quote");
        })
        .catch((error) => {
          this.$toast.open({
            message: this.$t("addErrorMessage"),
            type: "error",
            duration: 7000,
          });
        });
    },
  },
};
</script>
<style lang="css" scoped>
.quotation-box-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quotation-box-form .text {
  text-align: center;
  padding-bottom: 20px !important;
}
.quotation-form {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}
/* .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1.2rem !important;
} */
.btnReset {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.btnSubmit {
  color: white !important;
  background: #082139 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.addRequest {
  background-color: #fff;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addContainer {
  border: none;
}

.delete-container {
  color: red;
  border: none;
}
.delete-container-div {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.words-divider {
  border: 1px solid;
  border-radius: 10px 10px 10px 10px;
  padding-top: 8px;
  color: white;
  background-color: #082139;
  height: 40px;
}
.words-divider p {
  font-weight: 500;
}

.cercle-steps {
  border: 2px solid #082139;
  color: #082139;
  border-radius: 50%;
  padding: 0.05rem 0.35rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}
.quotation-form .nav-tabs .nav-link.active .cercle-steps {
  color: #F2AE65 !important;
  border-color: #F2AE65 !important;
}
.quotation-form .nav-tabs .nav-link.active span {
  color: #F2AE65 !important;
}

.commodity-radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

@media only screen and (max-width: 575px) {
  .btn-footer-forms .ml-25 {
    margin-left: 0px !important;
  }
}
</style>