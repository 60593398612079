<template lang="html">
  <div class="booking-layout">
    <BookingBreadcrumb />
    <b-container>
      <b-row class="row-eq-height">
        <b-col col lg="4" md="4" sm="12" cols="12">
          <BookingForm />
        </b-col>
        <b-col col lg="8" md="8" sm="12" cols="12">
          <BookingResult />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BookingBreadcrumb from "../components/Booking/BookingBreadcrumb.vue";
import BookingForm from "../components/Booking/BookingForm.vue";
import BookingResult from "../components/Booking/BookingResult.vue";

export default {
  name: "Booking",
  components: {
    BookingBreadcrumb,
    BookingForm,
    BookingResult
  },
  methods: {},
};
</script>
<style lang="css">
.booking-layout {
  padding-top: 25vh !important;
}
</style>