<template lang="html">
  <b-card
  class="result-booking">
    <b-card-text class="empty breadcrumb-text">
      no result obtained please try the correct ID
    </b-card-text>
  </b-card>
</template>
<script lang="js">
export default {
  name: 'BookingResult'
}
</script>
<style lang="css" scoped>
.empty.breadcrumb-text {
  color: #6c757d !important;
}
.card {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}


@media only screen and (max-width: 768px) {
  .result-booking {
    margin-bottom: 5rem !important;
  }
}


</style>