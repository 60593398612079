<template lang="html">
  <div class="tracking-layout">
    <TrackingBreadcrumb />
    <TrackingForm />
    <!-- <b-container>
      <b-row class="row-eq-height">
        <b-col col lg="4" md="4" sm="12" cols="12">
          
        </b-col>
         <b-col col lg="8" md="8" sm="12" cols="12">
          <TrackingResult />
        </b-col> 
      </b-row>
    </b-container> -->
  </div>
</template>
<script>
import TrackingBreadcrumb from "../components/Tracking/TrackingBreadcrumb.vue";
import TrackingForm from "../components/Tracking/TrackingForm.vue";
// import TrackingResult from "../components/Tracking/TrackingResult.vue";

export default {
  name: "Tracking",
  components: {
    TrackingBreadcrumb,
    TrackingForm,
    // TrackingResult
  },
  methods: {},
};
</script>
<style lang="css">
.tracking-layout {
  padding-top: 25vh !important;
}
</style>