
<template lang="html">
  <div>
    <div class="schedule-content-bg mt-25">
      <b-row  class="schedule-content">

        
          <b-col col lg="6" md="6" sm="12" cols="12">
          <div class="schedule-card-text">
            <h2 class="h2-white"> {{$t('homePage.scheduleSection.title')}}</h2>
            <p class="schedule-card-p text-justify"> {{$t('homePage.scheduleSection.description')}}</p>
            <a href="/about-us" class="button-yellow" style="width:25%"> {{$t('homePage.scheduleSection.btn')}}</a>
               <!-- <b-row  class="schedule-counter">

                <b-col col lg="3" md="4" sm="12">  
                  <p>99<span>Route</span></p> 
                </b-col>
                  <b-col col lg="3" md="4" sm="12">  
                  <p>99<span>Route</span></p> 
                </b-col>
                   <b-col col lg="3" md="4" sm="12">  
                  <p>99<span>Route</span></p> 
                </b-col>

               </b-row> -->
          </div>
        </b-col>
          <b-col col lg="6" md="6" sm="12" cols="12">
            <div class="schedule-form">
               <h2>{{$t('homePage.scheduleSection.scheduleForms.title')}}</h2>
                <p>{{$t('homePage.scheduleSection.scheduleForms.subTitle')}}</p>
                <p>{{$t('homePage.scheduleSection.scheduleForms.subTitle2')}}</p>

                <!-- <b-row >
                    <b-col col lg="12" md="12" sm="12" cols="12">
                        <input class="input-outline-bleu" type="text" icon-after v-model="origin" :placeholder="$t('homePage.scheduleSection.scheduleForms.placeholderPortOfLoad')"/>
                    </b-col>
                     <b-col col lg="12" md="12" sm="12" cols="12" class="schedule-input">
                        <input  class="input-outline-bleu" type="text" icon-after v-model="destination" :placeholder="$t('homePage.scheduleSection.scheduleForms.placeholderPortOfDischarge')"/>
                    </b-col>
                     <b-col col lg="6" md="6" sm="12" cols="12">
                         <button class="button-yellow">{{$t('homePage.scheduleSection.scheduleForms.btn')}}</button>
                    </b-col>
                </b-row> -->
         <a href="/scheduling" class="button-yellow" style="width:100%"> {{$t('homePage.scheduleSection.scheduleForms.btn')}}</a>
            </div>
        </b-col>
      </b-row>


    </div>
  </div>
</template>
<script>
export default {
  name: "HomeSchedule",
  components: {},
  data() {
    return {
      origin: "",
      destination: "",
    };
  },
  computed: {},
  created() {},

  methods: {},
};
</script>
<style lang="css">
.schedule-content-bg {
  background: linear-gradient(99.27deg, #082139 -5.24%, #0081dc 106.47%);
}
.schedule-content {
  background: url(../../assets/img/world.png);

  background-position: center;
  background-repeat: repeat-x;
  padding: 4.5rem 4rem;
}

.schedule-card-p {
  font-size: 18px;
  color: #fff;
  width: 80%;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.schedule-counter {
  margin-top: 2.5rem !important;
}
.schedule-counter p {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  display: grid;
}
.schedule-form {
  background: #fff;
  border-radius: 12px 12px 12px 12px;
  padding: 1rem 1rem 2rem 1rem;
  width: 50%;
  margin-left: 35% !important;
  margin-top: 0.5% !important;
}
.schedule-form h2 {
  color: #082139;
}


.schedule-input {
  margin-top: 1.5rem !important;
  margin-bottom: 2rem !important;
}



@media only screen and (max-width: 1160px) {
  .schedule-form {
    width: 80%!important;
    margin-left: 10% !important;
  }
}
@media only screen and (max-width: 503px) {
   .schedule-content-bg .schedule-form {
    width: 100%!important;
    margin-left: 0% !important;
  }
  .schedule-content-bg .schedule-content{
    padding: 1rem 0.5rem!important;
  }
}
</style>