<template lang="html">
  <div class="layout">
    <CommonNavbar></CommonNavbar>
    <router-view />
    <CommonFooter></CommonFooter>
  </div>
</template>
<script>
import CommonNavbar from "../layouts/CommonNavbar.vue";
import CommonFooter from "../layouts/CommonFooter.vue";

export default {
  name: "Layout",
  components: {
    CommonNavbar,
    CommonFooter,
  },
};
</script>
<style lang="css">
.layout {
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
}
</style>
