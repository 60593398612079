import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from "./i18n";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faPhone, faEnvelope, faMapMarkerAlt, faArrowRight, faConciergeBell, faLightbulb, faShip, faPlane, faTrain } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import vSelect from "vue-select";

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/styles.css';
import "vue-select/dist/vue-select.css";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueToast);

library.add(faUser);
library.add(faPhone);
library.add(faEnvelope);
library.add(faMapMarkerAlt);
library.add(faArrowRight);
library.add(faFacebook);
library.add(faConciergeBell);
library.add(faLightbulb);
library.add(faShip);
library.add(faPlane);
library.add(faTrain);


Vue.component("v-select", vSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
