<template lang="html">
<div>
  <b-card body-class="text-left" header-tag="nav" class="card-text second-padding">
    <h2 class="h2-blue"> {{$t('ourSolutionPage.solutionSection.title')}}</h2>
    <b-card-text class="accept-text">
     {{$t('ourSolutionPage.solutionSection.text')}}
   </b-card-text>
   <br>
    <h2 class="h2-blue"> {{$t('ourSolutionPage.solutionSection.title2')}}</h2>
    <b-card-text class="accept-text">
     {{$t('ourSolutionPage.solutionSection.text2')}}
   </b-card-text>
    <br>
    <h2 class="h2-blue"> {{$t('ourSolutionPage.solutionSection.title3')}}</h2>
    <b-card-text class="accept-text">
     {{$t('ourSolutionPage.solutionSection.text3')}}
   </b-card-text>
    <br>
    <h2 class="h2-blue"> {{$t('ourSolutionPage.solutionSection.title4')}}</h2>
    <b-card-text class="accept-text">
     {{$t('ourSolutionPage.solutionSection.text4')}}
   </b-card-text>
    <br>
    <h2 class="h2-blue"> {{$t('ourSolutionPage.solutionSection.title5')}}</h2>
    <b-card-text class="accept-text">
     {{$t('ourSolutionPage.solutionSection.text5')}}
   </b-card-text>
    <br>
    <h2 class="h2-blue"> {{$t('ourSolutionPage.solutionSection.title6')}}</h2>
    <b-card-text class="accept-text">
     {{$t('ourSolutionPage.solutionSection.text6')}}
   </b-card-text>
    <br>
    <h2 class="h2-blue"> {{$t('ourSolutionPage.solutionSection.title7')}}</h2>
    <b-card-text class="accept-text">
     {{$t('ourSolutionPage.solutionSection.text7')}}
   </b-card-text>
  </b-card>


  </div>
</template>
<script lang="js">
export default {
  name: 'AcceptText'
}
</script>
<style lang="css" scoped>
</style>