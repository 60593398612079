
<template lang="html">
  <b-container fluid class="login-section">
    <b-row class="login-row">
        <b-col class="login-form" lg="4" md="6" offset-md="3" offset-lg="4" offset-sm="2" sm="8">
        <b-row>
           <b-col  col lg="6" md="6" sm="12" xs="12" ><h2>{{$t('loginPage.title')}}</h2></b-col>
      <b-col  col lg="6" md="6" sm="12" xs="12" class="text-right login-redirection"> <router-link to="/sign-up"><font-awesome-icon icon="arrow-right" /> {{$t('loginPage.signUpBtn')}} </router-link></b-col>
        </b-row>
        <b-container>
          <b-row>
            <input v-model="email" type="email" :placeholder="$t('loginPage.usernamePlaceholder')" required class="input-outline-yellow input-color-white"/>
          </b-row>
          <b-row>
            <b-input-group>
              <b-form-input v-model="password" :type="passwordFieldType" :placeholder="$t('loginPage.passwordPlaceholder')" required class="input-outline-yellow input-color-white"></b-form-input>
              <b-input-group-append >
                <b-icon 
                  :icon="passwordToggleIcon"
                  @click="togglePassword"
                />
                </b-input-group-append>  
              </b-input-group>
          </b-row>
          <b-row>
            <b-form-checkbox id="checkbox-1" v-model="rememberMe" name="rememberMe" value=true unchecked-value=false>
              {{$t('loginPage.checkRemember')}}
            </b-form-checkbox>
          </b-row>
          <b-row>
            <b-button type="button" block class="login-button" @click="login">{{$t('loginPage.loginBtn')}}</b-button>
          </b-row>
          <b-row>
            <router-link to="/forget-password">{{$t('loginPage.forgotPassword')}}</router-link>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from "axios";

export default {
  name: "LoginForm",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      formError: false,
      rememberMe: false,
      passwordFieldType: "password",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "eye-slash" : "eye";
    },
  },
  created() {},

  methods: {
    async login() {
      try {
        await axios
          .post("https://mtl-feeder-nodejs.onrender.com/api/login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            console.log(response)
            const loggedIn = response.data;
            
            localStorage.setItem("userKey", JSON.stringify(loggedIn.token));
            localStorage.setItem("userData", JSON.stringify(loggedIn));
            // this.$router.push("/");
             setTimeout(() => {
              // this.$router.push("/login");
              window.location.href = "/";
            }, 300);

            this.$toast.open({
              message: "Welcome",
              type: "success",
              duration: 7000,
            });
          })
          .catch((error) => {
            console.log(error.response.data.message);
            this.errorMessage = error.message;
            if (error.response.data.message=="user not found") {
              this.$toast.open({
                message: "Email does not exist",
                type: "error",
                duration: 7000,
              });
            } else  if (error.response.data.message=="password incorrect") {
              this.$toast.open({
                message: "Password incorret",
                type: "error",
                duration: 7000,
              });
            } else  {
              this.$toast.open({
                message: "Error during login",
                type: "error",
                duration: 7000,
              });
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>
<style lang="css" scoped>
.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  color: white;
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 2.5rem;
  /* margin: 1rem; */
  width: 40%;
  height: auto;
  margin-top: -10rem !important;
}
.login-section .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 1.7rem !important;
  margin-bottom: 1.7rem !important;
}
.login-button {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-redirection {
  display: flex;
  align-items: center;
  justify-content: right;
}
.login-button:hover,
.login-button:active {
  background-color: #ffd500e7 !important;
}
@media only screen and (max-width: 1193px) {
  .login-redirection {
    display: none !important;
  }
}
@media only screen and (max-width: 575px) {
  .login-form {
    width: 100%;
    /* margin-left: 0% !important; */
  }
}
</style>