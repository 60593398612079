
<template lang="html">
  <div>
    <div class="hero-bg login-hero" :style="getImageBackground()">

      <div class="hero-gradient">
        <!-- homePage -->
        <b-row class="hero-content" v-if="this.$route.name === 'Home'">
          <b-col col lg="6" md="6" sm="12" xs="12" class="hero-text-col">
            <div class="hero-card-text">
              <h2 class="h2-white"> {{$t('homePage.hero.title')}}</h2>
              <p class="p-white">{{$t('homePage.hero.description')}}</p>
              <b-button class="button-yellow btn-hero-header" to="/our-agency"> {{$t('homePage.hero.btn')}} </b-button>
            </div>
          </b-col>

          <b-col col lg="6" md="6" sm="12" xs="12" class="hero-tracking-col">
            <div class="hero-card-tracking">
              <h2 class="h2-white">{{$t('homePage.hero.tracking.title')}}</h2>
              <p class="p-white">{{$t('homePage.hero.tracking.subTitle')}}</p>
              <b-row>
                <b-col col lg="8" md="8" sm="12">
                  <input type="text" v-model="tracking" :placeholder="$t('homePage.hero.tracking.placeholder')" class="input-outline-yellow" />
                </b-col>
                <b-col col lg="4" md="4" sm="12" xs="12" class="hero-subcard-tracking">
                  <button class="button-yellow" @click="search()">{{$t('homePage.hero.tracking.btn')}}</button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <!-- About Page -->
        <b-row class="sub-hero-content" v-if="this.$route.name === 'AboutUs'">
          <b-col col lg="8" md="8" sm="12" cols="12" offset-lg="2" offset-md="2" align="center"
            justify="center">
            <div class="title-responsive">
              <h2 class="h2-white ">“ {{$t('aboutUsPage.heroSection.title')}} ”</h2>
               <p class="p-white">{{$t('aboutUsPage.heroSection.text')}}</p>
              
            </div>
          </b-col>
        </b-row>
        <!-- Our Fleet Page -->
        <b-row class="sub-hero-content" v-if="this.$route.name === 'OurFleet'">
          <b-col col lg="8" md="8" sm="12" cols="12" offset-lg="2" offset-md="2" align="center"
            justify="center">
            <div class="title-responsive">
              <h2 class="h2-white-hero  ">“ {{$t('ourFleetPage.heroSection.proverbs')}} ”
                <br><br>– {{$t('ourFleetPage.heroSection.author')}}</h2>
            </div>
          </b-col>
        </b-row>
        <!-- agency page -->
         <b-row class="sub-hero-content" v-if="this.$route.name === 'OurAgency'">
          <b-col col lg="8" md="8" sm="12" cols="12" offset-lg="2" offset-md="2"  align="center"
            justify="center">
              <div class="title-responsive">
              <h2 class="h2-white-hero ">“ {{$t('ourAgenciesPage.heroSection.proverbs')}} ”
                <br><br>– {{$t('ourAgenciesPage.heroSection.author')}}</h2>
            </div>
          </b-col>
        </b-row>
    <!-- agency page -->
         <b-row class="sub-hero-content" v-if="this.$route.name === 'Ceo'">
          <b-col col lg="8" md="8" sm="12" cols="12" offset-lg="2" offset-md="2" align="center"
            justify="center">
            <div class="title-responsive">
              <h2 class="h2-white-hero ">“ {{$t('ceoPage.heroSection.proverbs')}} ”
                <br><br>– {{$t('ceoPage.heroSection.author')}}</h2>
            </div>
          </b-col>
        </b-row>
         <!-- our solution page -->
         <b-row class="sub-hero-content" v-if="this.$route.name === 'OurSolution'">
          <b-col col lg="8" md="8" sm="12" cols="12" offset-lg="2" offset-md="2" align="center"
            justify="center">
            <div class="title-responsive">
              <h2 class="h2-white-hero ">“ {{$t('ourSolutionPage.heroSection.proverbs')}} ”
                <br><br>– {{$t('ourSolutionPage.heroSection.author')}}</h2>
            </div>
          </b-col>
        </b-row>

        <!-- our services page -->
         <b-row class="sub-hero-content" v-if="this.$route.name === 'OurServices'">
          <b-col col lg="8" md="8" sm="12" cols="12" offset-lg="2" offset-md="2" align="center"
            justify="center">
            <div class="title-responsive">
              <h2 class="h2-white-hero ">“ {{$t('ourServicesPage.heroSection.proverbs')}} ”
                <br><br>– {{$t('ourServicesPage.heroSection.author')}}</h2>
            </div>
          </b-col>
        </b-row>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Hero",
  components: {},
  data() {
    return {
      tracking: "",
    };
  },
  props: ["imgLink"],
  computed: {},
  created() {},

  methods: {
    // pageName(){
    //   return this.$route.name === 'home'
    // },
    getImageBackground() {
      if (this.$route.name === "AboutUs") {
        return `background-image: url(${require("@/assets" +
          this.$props.imgLink +
          "")});background-repeat: no-repeat;background-position: bottom;background-size: cover; height: 60vh;`;
      } else if (
        this.$route.name === "Login" ||
        this.$route.name === "SignUp" ||
        this.$route.name === "AcceptTermAndCondition" ||
        this.$route.name === "contact"
      ) {
        return `background-image: url(${require("@/assets" +
          this.$props.imgLink +
          "")});background-repeat: no-repeat;background-position: bottom;background-size: cover;  height: 55vh;`;
      } else if (
        this.$route.name === "Ceo" ||
        this.$route.name === "OurAgency" ||
        this.$route.name === "OurFleet"
      ) {
        return `background-image: url(${require("@/assets" +
          this.$props.imgLink +
          "")});background-repeat: no-repeat;background-position: bottom;background-size: cover;  height: 60vh;`;
      } else if (
        this.$route.name === "OurServices" ||
        this.$route.name === "OurSolution" 
      ) {
        return `background-image: url(${require("@/assets" +
          this.$props.imgLink +
          "")});background-repeat: no-repeat;background-position: bottom;background-size: cover;  height: 60vh;`;
      } else {
        return `background-image: url(${require("@/assets" +
          this.$props.imgLink +
          "")});background-repeat: no-repeat;background-position: center;background-size: cover;`;
      }
    },
    search() {
      console.log(this.tracking);
      var link = "/tracking?id=" + this.tracking;
      this.$router.push(link);
    },
  },
};
</script>
<style lang="css">
/* .login-hero {
  min-height: 90vh !important;
} */
.hero-bg {
  /* Boat */
  height: 80vh;
  /* width: 100vw; */
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-gradient {
  background: linear-gradient(
    180deg,
    rgba(13, 6, 39, 0.75) 0%,
    rgba(13, 6, 40, 0.747656) 0.01%,
    #0e0728 0.02%,
    rgba(13, 6, 39, 0) 45.1%
  );
  height: 80vh;
}
.hero-content {
  padding: 35vh 4rem;
}
.hero-content .col-md-6 {
  /* padding-left: 0 !important; */
  padding-right: 0 !important;
}

.hero-content .hero-card-text p {
  width: 80%;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.hero-card-tracking {
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1.25rem 1.25rem 2.25rem 1.25rem;
  width: 60%;
  margin-left: 30% !important;
}
.hero-card-tracking .row {
  padding-top: 1rem !important;
}
/* .hero-content .hero-card-tracking p {
  font-size: 18px;
  color: #fff;
  width: 80%;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
} */
.hero-content .hero-subcard-tracking {
  padding: 0rem 1rem;
}
.sub-hero-content {
  padding-top: 16% !important;
}
/* *********************************************************************************************************** */
/* responsive */
@media only screen and (max-width: 1347px) {
  .hero-card-tracking {
    width: 75%;
    margin-left: 25% !important;
  }
}
@media only screen and (max-width: 966px) {
  .hero-card-tracking {
    width: 100%;
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 768px) {
  .hero-bg {
    height: 100vh;
  }
  .hero-card-tracking {
    margin-top: 2rem !important;
  }
  .hero-subcard-tracking button {
    width: 100%;
    margin-top: 1rem !important;
  }
}
@media only screen and (max-width: 644px) {
  .hero-tracking-col {
    display: none;
  }
}
@media only screen and (max-width: 503px) {
  .hero-text-col {
    display: none;
  }
  .title-responsive {
    display: none;
  }
  .hero-bg {
    height: 30vh;
  }
}
.btn-hero-header{
  color: #212529!important;
  width: 25%!important;
}
</style>