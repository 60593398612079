<template lang="html">
  <b-container class="quotation-box-form">
      <div class="quotation-form">
           <b-row class="text-right mb-25 mr-25">
                <b-col  col lg="12" md="12" sm="12" cols="12">
                    <router-link to="/new-instant"><b-icon icon="plus-circle"></b-icon> {{$t('createBookingPage.titleBreadcrumb')}}</router-link>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div>
                        <b-tabs content-class="mt-3" align="center">
                            <b-tab title="Step 1" active>
                                <template #title>
                                    <span class="cercle-steps"> <span>1</span> </span>
                                    <span>{{$t('newQuickQuotePage.step1')}}</span>
                                </template> 
                                <p class="p-3">
                                    <b-row>
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newQuickQuotePage.routeSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                            <b-form-group :label="$t('newQuickQuotePage.routeSection.fromPort')" label-for="country-from">
                                                <b-form-input
                                                id="country-from"
                                                :placeholder="$t('newQuickQuotePage.routeSection.portPlaceholder')"
                                                v-model="form.loadingPort"
                                                disabled
                                                class="input-outline-bleu-border">
                                            </b-form-input>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                           <b-form-group :label="$t('newQuickQuotePage.routeSection.ToPort')" label-for="contry-to">
                                                <b-form-input
                                                    id="contry-to"
                                                    :placeholder="$t('newQuickQuotePage.routeSection.portPlaceholder')"
                                                    v-model="form.dischargePort"
                                                    disabled
                                                    class="input-outline-bleu-border">
                                                </b-form-input>
                                            </b-form-group>                                            
                                        </b-col>

                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                           <b-form-group :label="$t('newQuotePage.routeSection.incoterm')" label-for="Incoterm">
                                                <v-select
                                                id="Incoterm"
                                                :placeholder="$t('newQuotePage.routeSection.incotermPlaceholder')"
                                                v-model="form.incoterm"
                                                :options="IncotermOptions"
                                                required
                                                class="input-outline-bleu-border"/>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="6" md="6" sm="6" xs="6" cols="12" v-if="form.incoterm=='EXW' || form.incoterm=='FCA' ">
                                            <b-form-group :label="$t('newQuotePage.routeSection.transportType')" label-for="Transport">
                                                <v-select
                                                id="Transport"
                                                :placeholder="$t('newQuotePage.routeSection.transportTypePlaceholder')"
                                                v-model="form.transportType"
                                                :options="TransportLoadingOptions"
                                                required
                                                class="input-outline-bleu-border"/>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="6" md="6" sm="6" xs="6" cols="12" v-if="form.transportType=='Transport Marchant' && (form.incoterm=='EXW' || form.incoterm=='FCA')">
                                            <b-form-group :label="$t('newQuotePage.routeSection.departureAddressCity')" label-for="departureAddressCity">
                                                <b-form-input
                                                v-model="form.departureAddressCity"
                                                :placeholder="$t('newQuotePage.routeSection.departureAddressCity')"
                                                required
                                                class="input-outline-bleu-border"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="6" cols="12" v-if="form.incoterm=='DAP'  ">
                                            <b-form-group :label="$t('newQuotePage.routeSection.transportType')" label-for="Transport">
                                                <v-select
                                                id="Transport"
                                                :placeholder="$t('newQuotePage.routeSection.transportTypePlaceholder')"
                                                v-model="form.transportType"
                                                :options="TransportLoadingOptions"
                                                required
                                                class="input-outline-bleu-border"/>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="6" md="6" sm="6" xs="6" cols="12" v-if="form.transportType=='Transport Marchant' && form.incoterm=='DAP' ">
                                            <b-form-group :label="$t('newQuotePage.routeSection.arrivalAddressCity')" label-for="arrivalAddressCity">
                                                <b-form-input
                                                v-model="form.arrivalAddressCity"
                                                :placeholder="$t('newQuotePage.routeSection.arrivalAddressCity')"
                                                required
                                                class="input-outline-bleu-border"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('createBookingPageFromQuote.commodity/container')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row v-for="(item, index) in form.commodityContainer" :key="index">
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                            <b-form-group :label="$t('newQuickQuotePage.commoditySection.commodityLabel')" label-for="commodity">
                                                <v-select
                                                id="commodity"
                                                :placeholder="$t('newQuickQuotePage.commoditySection.commodityPlaceholder')"
                                                v-model="item.commodity.title"
                                                :options="commodities"
                                                required
                                                class="input-outline-bleu-border"/>
                                            </b-form-group>
                                            <b-form-group :label="$t('newQuickQuotePage.commoditySection.descLabel')" label-for="textarea">
                                                <b-form-textarea
                                                id="textarea"
                                                class="input-outline-bleu-border"
                                                :placeholder="$t('newQuickQuotePage.commoditySection.descLabel')"
                                                rows="3"
                                                max-rows="6"
                                                v-model="item.commodity.description"
                                                ></b-form-textarea>
                                            </b-form-group>
                                            <b-form-group>
                                            <b-form-radio-group v-model="item.selectedOption" class="commodity-radio-group" @change="updateSelectedOption(index)">
                                                <b-form-radio value="radio1">
                                                    {{ $t('newQuickQuotePage.commoditySection.radio1') }}
                                                </b-form-radio>
                                                <b-form-radio value="radio2">
                                                    {{ $t('newQuickQuotePage.commoditySection.radio2') }}
                                                </b-form-radio>
                                                <b-form-radio value="radio3">
                                                    {{ $t('newQuickQuotePage.commoditySection.radio3') }}
                                                </b-form-radio>
                                            </b-form-radio-group>
                                            </b-form-group>                              
                                        </b-col>

                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12" v-if="item.selectedOption === 'radio1'">
                                            <b-form-group :label="$t('newQuickQuotePage.commoditySection.TemperatureLabel')" label-for="temperature">
                                                <b-form-input
                                                id="temperature"
                                                :placeholder="$t('newQuickQuotePage.commoditySection.TemperaturePlaceholder')"
                                                v-model="item.commodity.temperatureControl"
                                                class="input-outline-bleu-border"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12" v-if="item.selectedOption === 'radio2'">
                                            <b-form-group :label="$t('newQuickQuotePage.commoditySection.HeightLabel')" label-for="height">
                                                <b-form-input
                                                id="height"
                                                :placeholder="$t('newQuickQuotePage.commoditySection.HeightPlaceholder')"
                                                v-model="item.commodity.specialEquipment.height"
                                                class="input-outline-bleu-border"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12" v-if="item.selectedOption === 'radio2'">
                                            <b-form-group :label="$t('newQuickQuotePage.commoditySection.WidthLabel')" label-for="width">
                                                <b-form-input
                                                id="width"
                                                :placeholder="$t('newQuickQuotePage.commoditySection.WidthPlaceholder')"
                                                v-model="item.commodity.specialEquipment.width"
                                                class="input-outline-bleu-border"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col> 
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12" v-if="item.selectedOption === 'radio2'">
                                            <b-form-group :label="$t('newQuickQuotePage.commoditySection.LengthLabel')" label-for="length">
                                                <b-form-input
                                                id="length"
                                                :placeholder="$t('newQuickQuotePage.commoditySection.LengthPlaceholder')"
                                                v-model="item.commodity.specialEquipment.length"
                                                class="input-outline-bleu-border"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                      
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12" v-if="item.selectedOption === 'radio3'">
                                            <b-form-group :label="$t('newQuickQuotePage.commoditySection.classeLabel')" label-for="classe">
                                                <v-select
                                                id="classe"
                                                :placeholder="$t('newQuickQuotePage.commoditySection.classePlaceholder')"
                                                v-model="item.commodity.commodityClass"
                                                :options="classes"
                                                class="input-outline-bleu-border"
                                                @input="updateClasse(index,item.commodity.commodityClass)"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12" v-if="item.selectedOption === 'radio3'">
                                            <b-form-group :label="$t('newQuickQuotePage.commoditySection.unLabel')" label-for="un">
                                                <v-select
                                                id="un"
                                                :placeholder="$t('newQuickQuotePage.commoditySection.unPlaceholder')"
                                                v-model="item.commodity.commodityUn"
                                                :options="uns"
                                                class="input-outline-bleu-border"
                                                @input="updateUn(index,item.commodity.commodityUn)"/>
                                            </b-form-group>
                                        </b-col> 
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12" style="margin-top: 20px;">
                                             <b-form-group :label="$t('newQuickQuotePage.containerSection.containerTypeLabel')" label-for="container">
                                                <v-select
                                                id="container"
                                                :placeholder="$t('newQuickQuotePage.containerSection.containerTypePlaceholder')"
                                                v-model="item.container.containerType" 
                                                :options="item.container.containers"
                                                required
                                                class="input-outline-bleu-border"
                                                @input="updateContainer(index,item.container.containerType)"/>
                                                </b-form-group>

                                                <b-form-group >
                                                    <b-form-checkbox v-model="item.container.isOwnedByShipper">{{$t('newQuickQuotePage.containerSection.checkboxShipper')}}</b-form-checkbox>
                                                </b-form-group>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12" style="margin-top: 20px;">
                                                <b-form-group :label="$t('newQuickQuotePage.containerSection.containerQuantityLabel')" label-for="Container-qte">
                                                <b-form-spinbutton 
                                                    id="Container-qte" 
                                                    v-model="item.container.quantity"
                                                    min="1" 
                                                    max="100000"
                                                     class="input-outline-bleu-border"
                                                    ></b-form-spinbutton> 
                                                </b-form-group>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12" style="margin-top: 20px;">
                                            <b-form-group :label="$t('newQuickQuotePage.containerSection.approximateWeightLabel')" label-for="Container-quantity">
                                                <b-form-input
                                                    placeholder="18000,000 KG"
                                                    v-model="item.container.weight"
                                                    @change="(financial(item))"
                                                    required
                                                    id="Container-quantity"
                                                    disable
                                                     class="input-outline-bleu-border"
                                                    ></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12" v-if="index>0">
                                            <div class="delete-container-div">
                                            <button class="delete-container" @click="deleteCommodityContainer(index)"><b-icon icon="plus-circle"></b-icon> {{$t('newQuickQuotePage.containerSection.DeleteBtn')}}</button>   
                                            </div>
                                        </b-col>
                                        <b-col v-if="index !== form.commodityContainer.length-1"><br><hr class="line"><br></b-col>       
                                    </b-row>
                                    <b-row>
                                        <b-col >
                                            <button class="addContainer" @click="addCommodityContainer()"><b-icon icon="plus-circle"></b-icon> {{$t('createBookingPageFromQuote.addCommodityContainer')}}</button>   
                                        </b-col>
                                    </b-row> 
                                    <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newQuickQuotePage.dateSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12"> 
                                            <b-form-group :label="$t('newQuotePage.dateSection.effectiveDate')" label-for="effectiveDate"> 
                                                <b-form-input
                                                 id="effectiveDate" 
                                                 type="date"
                                                 v-model="form.effectiveDate"
                                                 required
                                                  class="input-outline-bleu-border"
                                                >
                                                </b-form-input>
                                            </b-form-group>    
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">  
                                            <b-form-group :label="$t('newQuotePage.dateSection.expiryDate')" label-for="expiryDate"> 
                                                <b-form-input
                                                 id="expiryDate" 
                                                 type="date"
                                                 v-model="form.expiryDate"
                                                 required
                                                  class="input-outline-bleu-border"
                                                >
                                                </b-form-input>
                                            </b-form-group>   
                                        </b-col>
                                    </b-row>
                                </p>  
                            </b-tab>
                            <b-tab title="Step 2">
                                <template #title>
                                  <span class="cercle-steps"> <span>2</span> </span>
                                     <span>{{$t('newQuickQuotePage.step2')}}</span>
                                </template>  
                                 <p class="p-3" v-if="form.loadingPort && form.dischargePort && form.incoterm && form.effectiveDate && form.expiryDate &&
                                 form.commodityContainer.every(item => item.commodity.title !== null && item.container.containerType !== null && item.commodity.description !== null)">
                                    <b-row>
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newQuickQuotePage.routeSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                                <div v-if="form.loadingPort">
                                                    <span class="subTitle-forms">
                                                        {{$t('newQuickQuotePage.routeSection.fromPort')}}:
                                                    </span>
                                                      <span>
                                                         {{form.loadingPort}}
                                                    </span>                                                
                                                </div>

                                        </b-col>

                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                               <div v-if="form.dischargePort">
                                                    <span class="subTitle-forms">
                                                         {{ $t('newQuickQuotePage.routeSection.ToPort')}}:
                                                    </span>
                                                      <span>
                                                         {{form.dischargePort}}
                                                    </span>
                                                   
                                                </div> 
                                        </b-col>
                                                <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                            <div v-if="form.incoterm">
                                                    <span class="subTitle-forms">
                                                        {{ $t('newQuotePage.routeSection.incoterm') }}:
                                                    </span>
                                                      <span>
                                                         {{form.incoterm}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        
                                        <b-col lg="6" md="6" sm="6" xs="6" cols="12" v-if="form.incoterm=='EXW' || form.incoterm=='FCA' ">
                                                    <div v-if="form.transportType">
                                                        <span class="subTitle-forms">
                                                            {{ $t('newQuotePage.routeSection.transportType') }}:
                                                        </span>
                                                        <span>
                                                            {{form.transportType}}
                                                        </span>
                                                    </div>
                                        </b-col>

                                             <b-col lg="6" md="6" sm="6" xs="12" cols="12" v-if="form.transportType=='Transport Marchant' && (form.incoterm=='EXW' || form.incoterm=='FCA')">
                                                <div v-if="form.departureAddressCity">
                                                        <span class="subTitle-forms">
                                                            {{ $t('newQuotePage.routeSection.departureAddressCity') }}:
                                                        </span>
                                                        <span>
                                                            {{form.departureAddressCity}}
                                                        </span>
                                                </div>
                                            </b-col>
                                              <b-col lg="6" md="6" sm="6" xs="6" cols="12" v-if="form.incoterm=='DAP' ">
                                                  <div v-if="form.transportType">
                                                        <span class="subTitle-forms">
                                                            {{ $t('newQuotePage.routeSection.transportType') }}:
                                                        </span>
                                                        <span>
                                                            {{form.transportType}}
                                                        </span>
                                                </div>
                                            </b-col>

                                             <b-col lg="6" md="6" sm="6" xs="6" cols="12" v-if="form.transportType=='Transport Marchant' && form.incoterm=='DAP' ">
                                                 <div v-if="form.arrivalAddressCity">
                                                        <span class="subTitle-forms">
                                                            {{ $t('newQuotePage.routeSection.arrivalAddressCity') }}:
                                                        </span>
                                                        <span>
                                                            {{form.arrivalAddressCity}}
                                                        </span>
                                                </div>
                                            </b-col>   
                                    </b-row>
                                    <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('createBookingPageFromQuote.commodity/container')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row v-for="(item, index) in form.commodityContainer" :key="index">
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                            <div v-if="item.commodity.title">
                                                    <span class="subTitle-forms">
                                                       {{ $t('newQuickQuotePage.commoditySection.commodityLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.commodity.title}}
                                                    </span>
                                            </div>
                                        </b-col>
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                            <div v-if="item.commodity.description">
                                                    <span class="subTitle-forms">
                                                       {{ $t('newQuickQuotePage.commoditySection.descLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.commodity.description}}
                                                    </span>
                                            </div>
                                        </b-col>
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                              <div v-if="item.commodity.temperatureControl">
                                                  <span class="subTitle-forms">
                                                      {{ $t('newQuickQuotePage.commoditySection.TemperatureLabel')}}:
                                                  </span>
                                                  <span>
                                                      {{item.commodity.temperatureControl}}
                                                  </span>
                                              </div>
                                          </b-col>
                                        <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                                            <div v-if="item.commodity.specialEquipment && item.commodity.specialEquipment.height">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.commoditySection.HeightLabel')}}:
                                                </span>
                                                <span>
                                                    {{item.commodity.specialEquipment.height}}
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                                            <div v-if="item.commodity.specialEquipment && item.commodity.specialEquipment.width">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.commoditySection.WidthLabel')}}:
                                                </span>
                                                <span>
                                                    {{item.commodity.specialEquipment.width}}
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                                            <div v-if="item.commodity.specialEquipment && item.commodity.specialEquipment.length">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.commoditySection.LengthLabel')}}:
                                                </span>
                                                <span>
                                                    {{item.commodity.specialEquipment.length}}
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12" >
                   
                                                 <div v-if="item.commodity.commodityClass">
                                                    <span class="subTitle-forms">
                                                       {{ $t('newQuickQuotePage.commoditySection.classeLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.commodity.commodityClass}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12" >
                                           <div v-if="item.commodity.commodityUn">
                                                    <span class="subTitle-forms">
                                                       {{ $t('newQuickQuotePage.commoditySection.unLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.commodity.commodityUn}}
                                                    </span>
                                                </div>
                                        </b-col> 

                                        <b-col lg="4" md="4" sm="4" xs="12" cols="12">
                                            <div v-if="item.container.containerType">
                                                    <span class="subTitle-forms">
                                                       {{$t('newQuickQuotePage.containerSection.containerTypeLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.container.containerType}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col>
                                        <div v-if="item.container.containerType && item.container.quantity">
                                                    <span class="subTitle-forms">
                                                       {{$t('newQuickQuotePage.containerSection.containerQuantityLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.container.quantity}}
                                                    </span>
                                                </div>
                                        </b-col>
                                             
                                                     
                                        <b-col >
                                              <div v-if="item.container.containerType && item.container.weight">
                                                    <span class="subTitle-forms">
                                                        {{$t('newQuickQuotePage.containerSection.approximateWeightLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.container.weight}}
                                                    </span>
                                                </div>
                                        </b-col>   
                                        <b-col cols="12" v-if="index !== form.commodityContainer.length-1"><br><hr class="line"><br></b-col>  
                                    </b-row>

                                    <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newQuickQuotePage.dateSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12"> 

                                                <div v-if="form.effectiveDate">
                                                    <span class="subTitle-forms">
                                                       {{$t('newQuotePage.dateSection.effectiveDate')}}:
                                                    </span>
                                                      <span>
                                                         {{form.effectiveDate}}
                                                    </span>
                                                </div>    
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">        
                                             <div v-if="form.expiryDate">
                                                    <span class="subTitle-forms">
                                                       {{$t('newQuotePage.dateSection.expiryDate')}}:
                                                    </span>
                                                      <span>
                                                         {{form.expiryDate}}
                                                    </span>
                                                </div>    
                                        </b-col>
                                    </b-row>
                                </p>
                                <p class="p-3 text-center" v-else>{{$t('newBLPage.step2Desc')}}</p>
                                </b-tab>
                                <b-tab>
                                <template #title>
                                   <span class="cercle-steps"><span>3</span></span>
                                    <span>{{$t('createBookingPageFromQuote.step3')}}</span>
                                </template>
                                <p class="p-3" v-if="form.loadingPort && form.dischargePort && form.incoterm && form.effectiveDate && form.expiryDate &&
                                 form.commodityContainer.every(item => item.commodity.title !== null && item.container.containerType !== null)">
                                     <b-row>
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('createBookingPageFromQuote.priceOwnerSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="5" md="6" sm="6" xs="12" cols="12">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                            <b-form-radio v-model="selectedOption" :aria-describedby="ariaDescribedby" name="customer" value="iamPriceOwner" @input="changeRadio">{{$t('createBookingPageFromQuote.priceOwnerSection.checkbox1')}}</b-form-radio>
                                            <b-form-radio v-model="selectedOption" :aria-describedby="ariaDescribedby" name="priceOwner" value="selectPriceOwner" @input="changeRadio">{{$t('createBookingPageFromQuote.priceOwnerSection.checkbox2')}}</b-form-radio>
                                        </b-form-group>
                                        </b-col>
                                    </b-row>    
                                    <b-row v-if="selectedOption === 'selectPriceOwner'">                       
                                           <b-col lg="12" md="12" sm="12" xs="12" cols="12">                                             
                                                    <b-form-group :label="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.customerCode')" label-for="customerCode">
                                                        <b-form-input
                                                        id="customerCode"
                                                        v-model="form.customerCode"
                                                        :placeholder="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.customerCode')"
                                                        required
                                                        class="input-outline-bleu-border"
                                                        ></b-form-input>
                                                    </b-form-group>
                                            </b-col>

                                             <b-col lg="5" md="5" sm="12" xs="12" cols="12" class="mt-25 mb-25">
                                               <hr>
                                            </b-col>
                                            <b-col  lg="1" md="1" sm="1" xs="12" cols="12" class="mt-25 mb-25">
                                               {{$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.orLine')}}
                                            </b-col>
                                            <b-col lg="6" md="5" sm="12" xs="12" cols="12" class="mt-25 mb-25">
                                               <hr>
                                            </b-col>
                                            
                                            <b-col lg="6" md="6" sm="6" xs="6" cols="12">
                                                <b-form-group :label="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.companyName')" label-for="companyName" >
                                                        <b-form-input
                                                        v-model="form.companyName"
                                                        :placeholder="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.companyName')"
                                                        required
                                                        class="input-outline-bleu-border"
                                                        ></b-form-input>
                                                    </b-form-group>
                                             </b-col>
                                              <b-col lg="6" md="6" sm="6" xs="6" cols="12">
                                                <b-form-group :label="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.country')" label-for="country">
                                                    <b-form-input
                                                    id="country"
                                                    v-model="form.country"
                                                    :placeholder="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.country')"
                                                    required
                                                     class="input-outline-bleu-border"
                                                    ></b-form-input>
                                               </b-form-group>
                                            </b-col>

                                            <b-col lg="6" md="6" sm="6" xs="6" cols="12" >
                                                <b-form-group :label="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.postal')" label-for="postal" >
                                                        <b-form-input
                                                        v-model="form.zipCode"
                                                        :placeholder="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.postalPlaceholder')"
                                                        class="input-outline-bleu-border"
                                                        ></b-form-input>
                                                    </b-form-group>
                                             </b-col>
                                              <b-col lg="6" md="6" sm="6" xs="6" cols="12">
                                                 <b-form-group :label="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.streetName')" label-for="streetName" >
                                                        <b-form-input
                                                        v-model="form.streetName"
                                                        :placeholder="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.streetNamePlaceholder')"
                                                        class="input-outline-bleu-border"
                                                        ></b-form-input>
                                                    </b-form-group>
                                            </b-col>
                                             <b-col lg="6" md="6" sm="6" xs="6" cols="12" >
                                                <b-form-group :label="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.POBox')" label-for="POBox" >
                                                        <b-form-input
                                                        v-model="form.POBox"
                                                        :placeholder="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.POBoxPlaceholder')"
                                                        class="input-outline-bleu-border"
                                                        ></b-form-input>
                                                    </b-form-group>
                                             </b-col>
                                              <b-col lg="6" md="6" sm="6" xs="6" cols="12">
                                                 <b-form-group :label="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.streetNumber')" label-for="streetNumber" >
                                                        <b-form-input
                                                        v-model="form.streetNumber"
                                                        :placeholder="$t('createBookingPageFromQuote.priceOwnerSection.selectPriceOwnerSection.streetNumberPlaceholder')"
                                                        class="input-outline-bleu-border"
                                                        ></b-form-input>
                                                    </b-form-group>
                                            </b-col>

                                       
                                    </b-row>
                                        <b-form-group class="mt-25">
                                                    <b-form-checkbox v-model="acceptTerm">
                                                        {{$t('createBookingPageFromQuote.priceOwnerSection.termsAndConditionsOfUse1')}}
                                                        <router-link to="/accept-term-condition" class="linkAccept">{{$t('createBookingPageFromQuote.priceOwnerSection.termsAndConditionsOfUse2')}}</router-link>
                                                    </b-form-checkbox>
                                                </b-form-group>
                                    <b-row class="btn-footer-forms">
                                        <b-col lg="2" md="3" sm="4" xs="5" cols="12">
                                            <b-button class="btnSubmit" type="submit" @click="onSubmit()"> {{$t('newQuickQuotePage.valideteBtn')}} </b-button>
                                         </b-col>
                                         <b-col lg="2" md="3" sm="4" xs="5" cols="12" class="ml-25">    
                                            <b-button class="btnReset" type="reset" @click="onReset()">{{$t('newQuickQuotePage.resetBtn')}}</b-button>
                                        </b-col>
                                    </b-row>     
                                </p>
                                <p class="p-3 text-center" v-else>{{$t('newBLPage.step2Desc')}}</p>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-col>
            </b-row>
             <br>
        </div>
        <b-modal v-model="isModalOpen" id="voyages" size="lg" centered  title="Voyages" @ok="handleOk"><voyages :data="voyage" @data-return="getVoyage"></voyages></b-modal>
    </b-container>
</template>

<script>
import axios from "axios";
import voyages from "../CreateBooking/BookingVoyages.vue"
export default {
    name: "BookingForm",
    components: {
        voyages
    },
    data() {
    return {
        form : {
            quotation:this.$router.history.current.query.id,
            customer:JSON.parse(localStorage.getItem("userData")).user._id,
            loadingPort: null,
            dischargePort: null,
            incoterm:null,
            transportType:null,
            departureAddressCity:null,
            TransportDischarge:null,
            arrivalAddressCity:null,
            commodityContainer : [
                {
                    selectedOption : null,
                    commodity : {
                        title : null,
                        description : null,
                        requiresTemperatureControl: false,
                        requiresSpecialEquipment:false,
                        dangerousCargo:false,
                        commodityClass: null,
                        commodityUn: null,
                        temperatureControl: null,
                        specialEquipment : {
                            height: null,
                            width: null,
                            length: null,
                        },              
                    },
                    container: {
                        containers : [],
                        containerType: null,
                        quantity: 1,
                        weight: '1000.000',
                        isOwnedByShipper: false,
                    }
                }
            ],
            effectiveDate: null,
            expiryDate: null,
            customerCode: '',
            companyName: '',
            country: '',
            zipCode: '', 
            streetName: '',
            POBox: '',
            streetNumber: '',
            merchandiseType: null,
        },
        IncotermOptions:['FOB', 'CFR', 'EXW', 'FCA', 'DAP'],
        TransportLoadingOptions:['Transport Marchant', 'Transport Carrier'],
        ports: [],
        departurePorts: [],
        arrivalPorts: [],
        commodities: [],
        classes: [],
        uns : [],
        containers : [],
        acceptTerm : false,
        selectedOption : null,
        isModalOpen: false,
        voyage: {
            loadingPort: null,
            dischargePort: null
        },
        voyageId : null,
        secondForm : {}
      }
    },
    async created() {
        this.getData()
        this.getPorts()
        this.getCommodities()
        this.getClasses()
        this.getUns()
        this.getContainers()
    },

  methods: {
    async getData() {
        const containers = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/containers")
        const response = await axios.get(
            "https://mtl-feeder-nodejs.onrender.com/api/bookings?id="+ this.$router.history.current.query.id
        );  
        this.form = response.data.data
        this.form.customer=JSON.parse(localStorage.getItem("userData")).user._id
        this.form.arrivalPortAgent = this.form.arrivalPortAgent.agent.user
        this.form.departurePortAgent = this.form.departurePortAgent.agent.user
        this.form.commodityContainer.forEach((element, index) => {
            if (element.commodity.requiresTemperatureControl) {
                this.$set(this.form.commodityContainer[index], 'selectedOption', 'radio1');
                const filterCon = containers.data.data
                .filter(item => item.frigorifique === true)
                .map(item => ({
                    // label: "Container " + item.type + "/" + item.size, 
                    label: "Container " + item.sizeType ,
                    value: {"_id": item._id , "title": item.title},
                }));
                this.$set(this.form.commodityContainer[index].container, 'containers', filterCon);
            }
            if (element.commodity.requiresSpecialEquipment) {
                this.$set(this.form.commodityContainer[index], 'selectedOption', 'radio2');
                this.$set(this.form.commodityContainer[index].container, 'containers', this.containers);
            }
            if (element.commodity.dangerousCargo) {
                this.$set(this.form.commodityContainer[index], 'selectedOption', 'radio3');
                this.$set(this.form.commodityContainer[index].container, 'containers', this.containers);
            }
            if (!element.commodity.requiresSpecialEquipment) {
                this.$set(this.form.commodityContainer[index].commodity, 'specialEquipment', {
                height: null,
                width: null,
                length: null,
                });
            }
        });
        const dateObject1 = new Date(this.form.effectiveDate);
        this.form.effectiveDate = dateObject1.toISOString().split("T")[0];
        const dateObject2 = new Date(this.form.expiryDate);
        this.form.expiryDate = dateObject2.toISOString().split("T")[0];
        if (this.form.customerCode  || this.form.companyName || this.form.country) {
            this.selectedOption = "selectPriceOwner"
        } 
        else {
            this.selectedOption = "iamPriceOwner"
        }
    },
    async getPorts() {
        const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/ports"); 
        this.ports = response.data.data.map(item => ({
            _id : item._id,
            portAgent : item.portAgent._id,
            title : item.title
        }))
        this.departurePorts = this.ports.filter(item => item.portAgent !== this.form.arrivalPortAgent)
        .map(item => ({
            label: item.title,
            value: {"_id": item._id , "portAgent":item.portAgent , "title": item.title},
        }));
        this.arrivalPorts = this.ports.filter(item => item.portAgent !== this.form.departurePortAgent)
        .map(item => ({
            label: item.title,
            value: {"_id": item._id , "portAgent":item.portAgent , "title": item.title}
        }));
    },
    async getCommodities() {
        const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/commodities"); 
        this.commodities = response.data.data.map(item => item.title)
    },
    async getClasses() {
        const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/classes"); 
        this.classes = response.data.data.map (item => ({
            label: item.title,
            value: {"_id": item._id , "title": item.title},
        }));
    },
    async getUns() {
        const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/uns"); 
        this.uns = response.data.data.map(item => ({
            label: item.title,
            value: {"_id": item._id , "title": item.title},
        }));
    },
    async getContainers() {
        const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/containers"); 
        const uniqueSizes = new Set();
        const uniqueArray = response.data.data.filter(obj => {
          if (!uniqueSizes.has(obj.sizeType)) {
            uniqueSizes.add(obj.sizeType);
            return true;
          }
          return false;
        });

        this.containers = uniqueArray.map(item => ({
            // label: "Container " + item.type + "/" + item.size, 
                    label: "Container " + item.sizeType ,
            value: {"_id": item._id , "title": item.title},
        }));
        this.form.commodityContainer.forEach(element => {
            element.container.containers = this.containers
        })
    },
    updateArrivalPorts() {
        if (this.form.loadingPort) {
            this.form.departurePortAgent = this.form.loadingPort.value.portAgent
            this.form.loadingPort = this.form.loadingPort.value.title
            this.arrivalPorts = this.ports
            .filter(item => item.title !== this.form.loadingPort)
            .map(item => ({label: item.title,value: {"_id": item._id , "portAgent": item.portAgent , "title": item.title}}));
        }
    },
    updateDeparturePorts() {
        if (this.form.dischargePort) {
            this.form.arrivalPortAgent = this.form.dischargePort.value.portAgent
            this.form.dischargePort = this.form.dischargePort.value.title
            this.departurePorts = this.ports
            .filter(item => item.title !== this.form.dischargePort)
            .map(item => ({label: item.title,value: {"_id": item._id , "portAgent":item.portAgent , "title": item.title}}));
        }
    },
    async updateSelectedOption(index) {
        if (this.form.commodityContainer[index].selectedOption === "radio1") {
            const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/containers")
            this.form.commodityContainer[index].container.containers = response.data.data
            .filter(item => item.frigorifique === true)
            .map(item => ({
                // label: "Container " + item.type + "/" + item.size, 
                    label: "Container " + item.sizeType ,
                value: {"_id": item._id , "title": item.title},
            }));
            this.form.commodityContainer[index].container.containerType = null
            this.form.commodityContainer[index].commodity.requiresTemperatureControl = true;
            this.form.commodityContainer[index].commodity.requiresSpecialEquipment = false;
            this.form.commodityContainer[index].commodity.dangerousCargo = false;
            this.form.commodityContainer[index].commodity.commodityClass = null
            this.form.commodityContainer[index].commodity.commodityUn = null
            this.form.commodityContainer[index].commodity.specialEquipment.height = null;
            this.form.commodityContainer[index].commodity.specialEquipment.width = null;
            this.form.commodityContainer[index].commodity.specialEquipment.length = null;
        } else if (this.form.commodityContainer[index].selectedOption === "radio2") {
            this.form.commodityContainer[index].container.containers = this.containers
            this.form.commodityContainer[index].commodity.requiresTemperatureControl = false;
            this.form.commodityContainer[index].commodity.requiresSpecialEquipment = true;
            this.form.commodityContainer[index].commodity.dangerousCargo = false;
            this.form.commodityContainer[index].commodity.commodityClass = null
            this.form.commodityContainer[index].commodity.commodityUn = null
            this.form.commodityContainer[index].commodity.temperatureControl = null
        } else if (this.form.commodityContainer[index].selectedOption === "radio3") {
            this.form.commodityContainer[index].container.containers = this.containers
            this.form.commodityContainer[index].commodity.requiresTemperatureControl = false;
            this.form.commodityContainer[index].commodity.requiresSpecialEquipment = false;
            this.form.commodityContainer[index].commodity.dangerousCargo = true;
            this.form.commodityContainer[index].commodity.specialEquipment.height = null;
            this.form.commodityContainer[index].commodity.specialEquipment.width = null;
            this.form.commodityContainer[index].commodity.specialEquipment.length = null;
            this.form.commodityContainer[index].commodity.temperatureControl = null
        }
    },
    updateClasse(index,classe) {
        if (classe) {
            this.form.commodityContainer[index].commodity.commodityClass = classe.label
        }
    },
    updateUn(index,un) {
        if (un) {
            this.form.commodityContainer[index].commodity.commodityUn = un.label
        }
    },
    updateContainer(index,container) {
        if (container) {
            this.form.commodityContainer[index].container.containerType = container.label
        }
    },
    financial(item) {
        item.container.weight = Number.parseFloat(item.container.weight).toFixed(3)
        return Number.parseFloat(item.container.weight).toFixed(3)
    },
    addCommodityContainer() {
        this.form.commodityContainer.push({
            commodity : {
                title : null,
                description : null,
                requiresTemperatureControl: false,
                requiresSpecialEquipment:false,
                dangerousCargo:false,
                commodityClass: null,
                commodityUn: null,
                temperatureControl: null,
                specialEquipment : {
                    height: null,
                    width: null,
                    ength: null,
                },              
            },
            container: {
                containers : this.containers,
                containerType: null,
                quantity: 1,
                weight: '1000.000',
                isOwnedByShipper: false,
            }
        })
    },
    deleteCommodityContainer(index) {
        this.form.commodityContainer.splice(index,1)
    },
    changeRadio() {
        if (this.selectedOption === "iamPriceOwner") {
            this.form.customerCode = ''
            this.form.companyName = ''
            this.form.country = ''
            this.form.zipCode = ''
            this.form.streetName = ''
            this.form.POBox = ''
            this.form.streetNumber = ''
        }
    },
    handleOk(event) {
        if (this.voyageId===null || this.voyageId==="" || this.voyageId.length===0) {  
            event.preventDefault()         
            this.$toast.open({
                message: this.$t('createBookingPageFromQuote.chooseOption'),
                type: "warning",
                duration: 3000
            });  
        }
        else {
            if (this.voyageId.trans) {
                this.secondForm.transshipment = this.voyageId._id        
            }
            else {
                this.secondForm.voyage = this.voyageId._id
            }
            axios
            .post(`https://mtl-feeder-nodejs.onrender.com/api/bookings`, this.secondForm)
                            .then((response) => {
                                    this.$toast.open({
                                        message: this.$t('createBookingPageFromQuote.bookingAdded'),
                                        type: "success",
                                        duration: 3000
                                    });
                            })
                            .catch((error) => {
                            this.$toast.open({
                                    message: this.$t('addErrorMessage'),
                                    type: "error",
                                    duration: 3000
                                });
                            })
        }
    },
    getVoyage(voyage) {
        this.voyageId = voyage
    },
    onSubmit() {
        const id = this.$router.history.current.query.id
        if (this.selectedOption) {
            if (this.selectedOption === "selectPriceOwner" &&
            (!this.form.customerCode || this.form.customerCode === "") &&
            (!this.form.country || this.form.country === "") &&
            (!this.form.companyName || this.form.companyName === "")) {
                this.$toast.open({
                    message: this.$t('newBLPage.step2Desc'),
                    type: "warning",
                    duration: 3000
                });        
            }
            else {
                if (this.acceptTerm) {
                    const filteredObj = Object.fromEntries(
                    Object.entries(this.form).filter(([key, value]) => value !== null && value !== "")
                );
                filteredObj.commodityContainer.forEach(item => {
                    item.commodity = Object.fromEntries(
                    Object.entries(item.commodity).filter(([key, value]) => value !== null)
                    );
                    if (item.commodity.requiresSpecialEquipment===false) {
                        delete item.commodity.specialEquipment
                    }
                });
                if (this.form.customerCode) {
                    axios.get('https://mtl-feeder-nodejs.onrender.com/api/customers', {
                        params : {id : JSON.parse(localStorage.getItem("userData")).user.customer._id }
                    })
                    .then((response) => {
                        if (this.form.customerCode === response.data.data[0].code) {
                            this.voyage.loadingPort = this.form.loadingPort
                            this.voyage.dischargePort = this.form.dischargePort
                            this.secondForm = filteredObj
                            this.isModalOpen = true;
                            /*axios
                    .put(
                        `https://mtl-feeder-nodejs.onrender.com/api/bookings/${id}`, filteredObj
                    )
                            .then((response) => {
                                    this.$toast.open({
                                        message: this.$t('createBookingPageFromQuote.bookingUpdated'),
                                        type: "success",
                                        duration: 3000
                                    });
                            })
                            .catch((error) => {
                            this.$toast.open({
                                    message: this.$t('updateErrorMessage'),
                                    type: "error",
                                    duration: 3000
                                });
                            })*/
                        }
                        else {
                            this.$toast.open({
                            message: this.$t('createBookingPageFromQuote.invalidCode'),
                            type: "warning",
                            duration: 3000
                            });
                        }
                    })
                    .catch((error) => {
                    })
                }
                else {
                    this.voyage.loadingPort = this.form.loadingPort
                    this.voyage.dischargePort = this.form.dischargePort
                    this.secondForm = filteredObj
                    this.isModalOpen = true;
                    /*axios
                    .put(
                        `https://mtl-feeder-nodejs.onrender.com/api/bookings/${id}`, filteredObj
                    )
                    .then((response) => {
                            this.$toast.open({
                                message: this.$t('createBookingPageFromQuote.bookingUpdated'),
                                type: "success",
                                duration: 3000
                            });
                    })
                    .catch((error) => {
                    this.$toast.open({
                            message: this.$t('updateErrorMessage'),
                            type: "error",
                            duration: 3000
                        });
                    })*/
                }
                }
                else {
                    this.$toast.open({
                    message: this.$t('createBookingPageFromQuote.acceptTerms'),
                    type: "warning",
                    duration: 3000
                });                      
                }
            }
        }
        else {
            this.$toast.open({
                message: this.$t('createBookingPageFromQuote.chooseOption'),
                type: "warning",
                duration: 3000
            });   
        }   
    },
    onReset() {
        this.form = {
            quotation:this.$router.history.current.query.id,
            customer:JSON.parse(localStorage.getItem("userData")).user._id,
            loadingPort: null,
            dischargePort: null,
            incoterm:null,
            transportType:null,
            departureAddressCity:null,
            TransportDischarge:null,
            arrivalAddressCity:null,
            commodityContainer : [
                {
                    selectedOption : null,
                    commodity : {
                        title : null,
                        requiresTemperatureControl: false,
                        requiresSpecialEquipment:false,
                        dangerousCargo:false,
                        commodityClass: null,
                        commodityUn: null,
                        temperatureControl: null,
                        specialEquipment : {
                            height: null,
                            width: null,
                            length: null,
                        },              
                    },
                    container: {
                        containers: this.containers,
                        containerType: null,
                        quantity: 1,
                        weight: '1000.000',
                        isOwnedByShipper: false,
                    }
                }
            ],
            effectiveDate: null,
            expiryDate: null,
            customerCode: '',
            companyName: '',
            country: '',
            zipCode: '', 
            streetName: '',
            POBox: '',
            streetNumber: '',
            merchandiseType: null,
        },
        this.selectedOption = null
        this.acceptTerm = false
    }
  },
};
</script>
<style lang="css" scoped>
.quotation-box-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quotation-form {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}
/* .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1.2rem !important;
} */
.btnReset {
    color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
    margin-top: 10px;
}
.btnSubmit {
  color: white !important;
  background: #082139  !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.addRequest {
    background-color: #fff;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addContainer {
    border: none;
}

.delete-container {
    color: red;
    border: none;
}
.delete-container-div{
    height: 100%;
    display: flex;
    margin-bottom: 15px;
}
.delete-container,.addContainer {
    background-color: transparent;
}

.words-divider {
    border: 1px solid;
    border-radius: 10px 10px 10px 10px;
    padding-top: 8px;
    color: white;
    background-color: #082139;
    height: 40px;
}
.words-divider p {
    font-weight: 500;
}

.cercle-steps{
    border: 2px solid #082139;
    color: #082139;
    border-radius: 50%;
    padding: 0.05rem 0.35rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;
}
.quotation-form .nav-tabs .nav-link.active .cercle-steps{
    color: #F2AE65!important;
    border-color: #F2AE65!important;
}
.quotation-form .nav-tabs .nav-link.active span{
    color:#F2AE65!important;
}

.commodity-radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.linkAccept {
    text-decoration: underline !important;
}
.line {
  background-color: #495057!important;
}
@media only screen and (max-width: 575px) {
 .btn-footer-forms .ml-25{
    margin-left: 0px!important;
  }
}
</style>