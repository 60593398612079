<template lang="html">
  <div class="booking-layout">
    <BookingBreadcrumb />
     <div class="forms-padding">
      <b-row class="row-eq-height">
        <b-col col lg="3" md="3" sm="12" cols="12" class="profile-menu-card">
            <profileMenu />
        </b-col>
        <b-col col lg="9" md="9" sm="12" cols="12" class="forms-quoation-card mb-100">
          <BookingTab />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import BookingBreadcrumb from "../components/Booking/BookingBreadcrumb.vue";
import profileMenu from "../components/Profile/profileMenu.vue";
import BookingTab from "../components/Booking/BookingTab.vue";

export default {
  name: "Booking",
  components: {
    BookingBreadcrumb,
    profileMenu,
    BookingTab,
  },
  methods: {},
};
</script>
<style lang="css">
.booking-layout {
  padding-top: 25vh !important;
}
</style>