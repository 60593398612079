<template lang="html">
    <b-container class="scheduling-box-form">
        <div class="scheduling-form p-3">
            <b-row >
                <b-col>
                    <p><strong>{{$t('homePage.scheduleSection.scheduleTitle1')}}</strong></p>
                 </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p><strong>{{$t('homePage.scheduleSection.scheduleTitle2')}}</strong></p>
                 </b-col>
            </b-row>
            <b-row class="p-3">
                <b-col lg="6" md="6" sm="6" cols="12">
                    <b-form-group :label="$t('newQuickQuotePage.routeSection.fromPort')" label-for="country-from">
                        <v-select
                        id="country-from"
                        :placeholder="$t('newQuickQuotePage.routeSection.portPlaceholder')"
                        v-model="form.loadingPort"
                        :options="departurePorts"
                        required
                        class="input-outline-bleu-border"
                        @input="updateArrivalPorts"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="6" cols="12">
                    <b-form-group :label="$t('newQuickQuotePage.routeSection.ToPort')" label-for="country-to">
                        <v-select
                        id="contry-to"
                        :placeholder="$t('newQuickQuotePage.routeSection.portPlaceholder')"
                        v-model="form.dischargePort"
                        :options="arrivalPorts"
                        required
                        class="input-outline-bleu-border"
                        @input="updateDeparturePorts"/>
                    </b-form-group>       
                </b-col>
            </b-row>       
            <b-row class="p-3">
                <b-col  cols lg="3" md="3" sm="4" xs="5">    
                    <b-button class="btnSubmit" @click="search()" type="submit">{{$t('homePage.hero.tracking.btn')}}</b-button>
                </b-col>
            </b-row>  
            <br>  
            <b-card class="result-tracking" v-if="clickSerch">
                <div v-if="voyagesData.length > 0">
                    <div class="data-structure" v-for="(item, index) in voyagesData" :key="index">
                        <b-row class="results-schedule1">
                            <b-col cols lg="3" md="6" sm="12" xs="12">
                                <div>
                                    <div><b-icon icon="truck" font-scale="1.5"></b-icon>&nbsp; {{$t('schedulingPage.schedulingResult.vessel')}}</div>
                                    <div class="cell">{{ item.vessel }}</div>
                                    <div class="cell">{{ item.numero }}</div>
                                </div>  
                            </b-col>
                            <b-col cols lg="2" md="6" sm="12" xs="12">
                                <div>
                                    <div><b-icon icon="calendar-date" font-scale="1.5"></b-icon>&nbsp; {{$t('createBookingPageFromQuote.voyage.startDate')}}</div>
                                    <div class="cell">{{ formatDate(item.startDate) }}</div>
                                </div>  
                            </b-col>
                            <b-col cols lg="3" md="6" sm="12" xs="12">
                                <div>
                                    <div><b-icon icon="calendar-date" font-scale="1.5"></b-icon>&nbsp; {{$t('createBookingPageFromQuote.voyage.endDate')}}</div>
                                    <div class="cell">{{ formatDate(item.endDate) }}</div>
                                </div>  
                            </b-col>
                            <b-col cols lg="4" md="6" sm="12" xs="12">
                                <div>
                                    <div><b-icon icon="stop" font-scale="1.5"></b-icon>&nbsp; {{$t('homePage.scheduleSection.scheduleForms.stop')}}</div>
                                    <div class="cell">
                                        <ul style="list-style: none;padding: 0;">
                                        <li v-for="(stop, i) in item.stopovers" :key="i">
                                        <strong> {{stop.loadingPort }} </strong>{{"-->"}} <strong>{{ stop.dischargePort }}</strong><br>
                                            <span> {{ formatDate(stop.startDate) }} {{"-->"}} {{ formatDate(stop.endDate) }}</span>
                                        </li>
                                        </ul>
                                    </div>
                                </div>  
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div v-else>
                    {{$t('homePage.scheduleSection.scheduleForms.noResult')}}
                </div>
            </b-card> 
        </div>
    </b-container>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "SchedulingForm",
  components: {},
  data() {
    return {
      voyages: [],
      voyagesData: [],
      form: {
        loadingPort: null,
        dischargePort: null,
      },
      ports: [],
      departurePorts: [],
      arrivalPorts: [],
      clickSerch: false,
    };
  },
  async created() {
    await this.getPorts();
    await this.getAllDataVoyage();
  },
  methods: {
    async getPorts() {
      const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/ports");
      this.ports = response.data.data.map((item) => ({
        _id: item._id,
        portAgent: item.portAgent._id,
        title: item.title,
      }));
      this.departurePorts = this.ports.map((item) => ({
        label: item.title,
        value: { _id: item._id, portAgent: item.portAgent, title: item.title },
      }));
      this.arrivalPorts = this.ports.map((item) => ({
        label: item.title,
        value: { _id: item._id, portAgent: item.portAgent, title: item.title },
      }));
    },
    async getAllDataVoyage() {
      const response = await axios.get("https://mtl-feeder-nodejs.onrender.com/api/voyages");
      this.voyages = response.data.data;
    },
    updateArrivalPorts() {
      if (this.form.loadingPort) {
        this.form.loadingPort = this.form.loadingPort.value.title;
        this.arrivalPorts = this.ports
          .filter((item) => item.title !== this.form.loadingPort)
          .map((item) => ({
            label: item.title,
            value: {
              _id: item._id,
              portAgent: item.portAgent,
              title: item.title,
            },
          }));
      } else {
        this.getPorts();
      }
    },
    updateDeparturePorts() {
      if (this.form.dischargePort) {
        this.form.dischargePort = this.form.dischargePort.value.title;
        this.departurePorts = this.ports
          .filter((item) => item.title !== this.form.dischargePort)
          .map((item) => ({
            label: item.title,
            value: {
              _id: item._id,
              portAgent: item.portAgent,
              title: item.title,
            },
          }));
      } else {
        this.getPorts();
      }
    },
    search() {
      this.voyagesData= []
      console.log("search1", this.form, this.voyages);
      if (this.form.loadingPort && this.form.dischargePort) {
        const currentDate = new Date().toISOString().slice(0, 10);

        const filteredVoyagesByDirect = this.voyages.filter(
          (voyage) =>
            voyage.loadingPort == this.form.loadingPort &&
            voyage.dischargePort == this.form.dischargePort &&
            new Date(voyage.startDate).getTime() >=
              new Date(currentDate).getTime()
        );
        const filteredVoyagesByStopover = [];
        this.voyages.filter((voyage) => {
          const matchingStopovers = voyage.stopovers.filter((stop) => 
            stop.loadingPort == this.form.loadingPort &&
            stop.dischargePort == this.form.dischargePort &&
            new Date(stop.startDate).getTime() >=
            new Date(currentDate).getTime()
          );
          if (matchingStopovers.length > 0) {
            filteredVoyagesByStopover.push(voyage);
          }
        });
        const combinedVoyages = [
          ...filteredVoyagesByDirect,
          ...filteredVoyagesByStopover,
        ];
        if (combinedVoyages.length > 0) {
          this.voyagesData = combinedVoyages;
          this.clickSerch = true;
        } 

        console.log("search2", this.voyagesData);
        // var voyagesData = this.voyages.filter(
        //   (item) =>
        //     item.loadingPort === this.form.loadingPort &&
        //     item.dischargePort === this.form.dischargePort
        // );

        // console.log("search", voyagesData);

        // this.voyagesData = voyagesData.filter((voyage) => {
        //   // Parse the 'startDate' string into a Date object
        //   const startDate = new Date(voyage.startDate);

        //   // Convert both dates to milliseconds for reliable comparison
        //   return startDate.getTime() > new Date(currentDate).getTime();
        // });
        // console.log("search2", this.voyagesData);

        // this.voyagesData = voyagesData.filter(item => {
        // // Check for each stopover
        //         const mainStartDate = new Date(item.startDate);
        //         const mainEndDate = new Date(item.endDate);
        //           if (mainStartDate >= currentDate && mainEndDate >= currentDate) {
        //             var stopoversValid = item.stopovers.every(stopover => {
        //             var startDate = new Date(stopover.startDate);
        //             var endDate = new Date(stopover.endDate);
        //             // Check if both start and end dates are equal to or greater than the current date
        //             return startDate >= currentDate && endDate >= currentDate;

        //         });
        //         return true
        //     }
        //     return item;
        // });
        this.clickSerch = true;
      } else {
        this.$toast.open({
          message: this.$t(
            "homePage.scheduleSection.scheduleForms.errorMessage"
          ),
          type: "info",
          duration: 3000,
        });
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
  },
};
</script>
<style lang="css" scoped>
.scheduling-box-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scheduling-form {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}

.btnSubmit {
  color: #082139 !important;
  background: #f2ae65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.title-h1 {
  margin-left: 2em;
}
.description {
  margin-left: 5em;
}

.scheduling-form .nav-tabs .nav-link.active .cercle-steps {
  color: #f2ae65 !important;
  border-color: #f2ae65 !important;
}
.scheduling-form .nav-tabs .nav-link.active span {
  color: #f2ae65 !important;
}
.results-schedule1 {
  background-color: #ececec;
  padding: 22px 16px 16px;
}
.results-schedule2 {
  /* background-color: #ececec; */
  padding: 22px 16px 0px;
}
.data-structure {
  margin: 0.5rem;
  margin-bottom: 1.2rem !important;
}
.link-details {
  color: #f2ae65 !important;
  text-decoration: underline !important;
  /* text-align: center !important; */
  padding-left: 1.5rem;
  font-weight: bold;
}
.data-link {
  padding-left: 1.5rem;
}
.cell {
  padding-left: 1.5rem;
  font-weight: bold;
}
.deadline-data {
  padding-left: 1.5rem;
  font-weight: bold;
  font-size: 15px;
  /* margin-top: -6rem ; */
}
@media only screen and (max-width: 575px) {
  .btn-footer-forms .ml-25 {
    margin-left: 0px !important;
  }
}
</style>