<template lang="html">
  <div class="quotation-layout">
    <MyBLBreadcrumb />
    <div class="forms-padding">
      <b-row class="row-eq-height">
        <b-col col lg="3" md="3" sm="12" cols="12" class="profile-menu-card">
            <ProfileMenu />
        </b-col>
        <b-col col lg="9" md="9" sm="12" cols="12" class="forms-quoation-card mb-100">
             <MyBLTab />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import MyBLBreadcrumb from "../components/MyBL/MyBLBreadcrumb.vue";
import ProfileMenu from "../components/Profile/profileMenu.vue";
import MyBLTab from "../components/MyBL/MyBLTab.vue";
export default {
  name: "MyBL",
  components: {
    MyBLBreadcrumb,
    ProfileMenu,
    MyBLTab,
  },
  methods: {},
};
</script>
<style lang="css">
.quotation-layout {
  padding-top: 25vh !important;
}
@media only screen and (max-width: 1077px) {
 .profile-menu-card{
     padding: 0px!important;
  }
  .forms-quoation-card{
      padding: 0px!important;
  }
}

</style>