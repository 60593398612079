<template lang="html">
  <b-container class="quote-box-tab">
      <div class="quote-tab">
        <h2 class="quote-title mb-25">{{$t('profileMenu.links.eQuote.myEQuote')}}</h2>
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{$t('homePage.hero.tracking.btn')}}:</label>
              <b-form-input
                v-model="searchTerm"
               :placeholder="$t('homePage.hero.tracking.btn')"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      class="mb-50"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.state)" class="state">
            {{ props.row.state }}
          </b-badge>
        </span>
        <!-- Column: Container -->
        <span v-else-if="props.column.field === 'container'">
          <ul class="list-containers">
            <li v-for="container in props.row.containers">{{ container.containerType }} - {{ container.quantity }} - {{ container.weight }}KG</li>
          </ul>
        </span>
           <!-- Column: PDF -->
           <span v-if="props.column.field === 'pdf'">
          <b-button class="detail-button" @click="downloadFile(props.row.pdf)" v-if="props.row.pdf">
            <b-icon icon="download" font-scale="0.9" class="text-body align-middle mr-25"></b-icon>
          </b-button>
          <span v-else>No Quotation Yet</span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <b-icon icon="three-dots-vertical" font-scale="0.9" class="text-body align-middle mr-25"></b-icon>
              </template>
              <b-dropdown-item>
                <b-icon icon="eye" font-scale="0.9" class="mr-50"></b-icon>
                <span @click="ViewQuote(props.row._id)"> {{$t('myQuickQuotePage.actions.viewBtn')}}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="updateQuotation(props.row._id)" v-if="props.row.state==='pending'">
                <b-icon icon="pencil-square" font-scale="0.9" class="mr-50"></b-icon>
                <span > {{$t('myQuickQuotePage.actions.updateBtn')}}</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-icon icon="trash" font-scale="0.9" class="mr-50"></b-icon>
                <span @click="DeleteQuote(props.row._id)"> {{$t('myQuickQuotePage.actions.deleteBtn')}}</span>
              </b-dropdown-item>

              <b-dropdown-item >
                <b-icon icon="plus-circle" font-scale="0.9" class="mr-50"></b-icon>
                <span v-if="props.row.state === 'accepted'"><router-link :to="getLink(props.row._id)"> {{$t('createBookingPage.titleBreadcrumb')}}</router-link></span>
                <span disabled class="disable-text" v-else> {{$t('createBookingPage.titleBreadcrumb')}}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
       <span v-else-if="props.column.field == 'customer'">
          <p v-if="props.row.customer">{{ props.row.customer.email }} - {{ props.row.customer.customer.firstName }} {{ props.row.customer.customer.lastName }}</p>
        </span>
       <span v-else-if="props.column.field == 'departurePortAgent'">
          <p >{{ props.row.departurePortAgent.agent.seaPort }}</p>
        </span>
        <span v-else-if="props.column.field == 'arrivalPortAgent'">
          <p>{{ props.row.arrivalPortAgent.agent.seaPort }}</p>
        </span>
    
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{$t('myQuickQuotePage.pagination.showing')}}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{$t('myQuickQuotePage.pagination.of')}} {{ props.total }} {{$t('myQuickQuotePage.pagination.entries')}} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <b-icon icon="chevron-compact-left"></b-icon>
              </template>
              <template #next-text>
                <b-icon icon="chevron-compact-right"></b-icon>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>  

           </div> 
    </b-container>
</template>
<script>
import moment from 'moment';
import axios from "axios";
import { VueGoodTable } from 'vue-good-table';
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
export default {
  name: "InformationForm",
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
      return {
      pageLength: 3,
      columns: [
         {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'Document',
          field: 'pdf',
        },
     {
          width: '100px',
          label: this.$t('createBookingPageFromQuote.state'),
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('createBookingPageFromQuote.search.stateSearch'),
          },
          formatFn: (value) => {
            return moment(value).format('MM/DD/YYYY');
          },
        },
        {
          width : '150px',
          label: this.$t('myQuickQuotePage.labels.portFrom'),
          field: 'loadingPort',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.portFrom'),
          },
        },
        {
          width : '150px',
          label: this.$t('myQuickQuotePage.labels.portTo'),
          field: 'dischargePort',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.portTo'),
          },
        },
        {
          label: 'Incoterm',
          field: 'incoterm',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newQuotePage.routeSection.incotermSearch'),
          },
        },
        {
          label: this.$t('myQuickQuotePage.labels.commodity'),
          field: 'commodity',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.commodity'),
          },
        },
        {
          width : '320px',
          label: this.$t('myQuickQuotePage.labels.container'),
          field: 'container',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('myQuickQuotePage.search.container'),
          },
        },
        {
          width: '140px',
          label: this.$t('newQuotePage.dateSection.effectiveDate'),
          field: 'effectiveDate',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newQuotePage.dateSection.effectiveDateSearch'),
          },
          formatFn: (value) => {
            return moment(value).format('MM/DD/YYYY');
          },
        },
        {
          width: '140px',
          label: this.$t('newQuotePage.dateSection.expiryDate'),
          field: 'expiryDate',
          filterOptions: {
            enabled: true,
            placeholder: this.$t('newQuotePage.dateSection.expiryDateSearch'),
          },
          formatFn: (value) => {
            return moment(value).format('MM/DD/YYYY');
          },
        },
       
        
      ],
      rows:[],
      searchTerm: '',
      disabled: true,
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newLocale) {
        this.columns[3].label = this.$t('myQuickQuotePage.labels.portFrom');
        this.columns[3].filterOptions.placeholder = this.$t('myQuickQuotePage.search.portFrom');
        this.columns[4].label = this.$t('myQuickQuotePage.labels.portTo');
        this.columns[4].filterOptions.placeholder = this.$t('myQuickQuotePage.search.portTo');
        this.columns[5].label = this.$t('newQuotePage.routeSection.incotermSearch');
        this.columns[5].filterOptions.placeholder = this.$t('newQuotePage.routeSection.incotermSearch');
        this.columns[6].label = this.$t('myQuickQuotePage.labels.commodity');
        this.columns[6].filterOptions.placeholder = this.$t('myQuickQuotePage.search.commodity');
        this.columns[7].label = this.$t('myQuickQuotePage.labels.container');
        this.columns[7].filterOptions.placeholder = this.$t('myQuickQuotePage.search.container');
        this.columns[8].label = this.$t('newQuotePage.dateSection.effectiveDate');
        this.columns[8].filterOptions.placeholder = this.$t('newQuotePage.dateSection.effectiveDateSearch');
        this.columns[9].label = this.$t('newQuotePage.dateSection.expiryDate');
        this.columns[9].filterOptions.placeholder = this.$t('newQuotePage.dateSection.expiryDateSearch');
      }
    }
  },
  computed: {
    statusVariant() {
      const stateColor = {
        accepted     : 'success',
        refused     : 'danger',
        pending     : 'warning',
      }
        return state => stateColor[state]
    },
  },
  async created() {
    await this.getAllDataQuote();
  },
  methods: {
    ViewQuote(id) {
      this.$router.push({name: 'ViewQuote',query: { id }});
    },
    updateQuotation(id){
      var link="/quotation-edit?id="+id
      this.$router.push(link);
    },
    getLink(id){
      return '/create-booking?id='+id
    },
    async getAllDataQuote() { 
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/quotations?user="+JSON.parse(localStorage.getItem("userData")).user._id
      );
      this.rows = response.data.data.reverse();;
    },
    DeleteQuote(id) {
          axios
            .put(
              `https://mtl-feeder-nodejs.onrender.com/api/quotations/${id}`, { active : false }
            )
            .then((response) => {
              this.getAllDataQuote();         
            })
            .catch((error) => {
            })
    },
    downloadFile(url) {
      axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "File.pdf";  
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },
  }
}
</script>
<style lang="css">
.quote-box-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quote-tab {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}
.quote-title {
  position: relative;
  text-align: center;
}
.disable-text {
  color: #8a8a8a;
  cursor: not-allowed !important;
}
.quote-box-tab table.vgt-table td {
  padding: .60em 0 .60em 0 !important;
  text-align: center;
  vertical-align: middle !important;
}
.quote-box-tab .list-containers {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mr-25 {
  margin-right: 0 !important;
}
.quote-box-tab .state {
  padding : 6px 10px !important;
  border-radius: 5px !important
}
</style>