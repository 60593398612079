<template lang="html">
  <b-container class="booking-box">
      <b-row class="booking-row">
        <b-col class="booking-form" md="12">
          <b-container>
            <b-row>
              <b-col col lg="12" md="12" sm="12" cols="12">
                <h2>Find a schedule</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label>Search the schedule that matches your shipment readiness date.</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col col lg="12" md="12" sm="12" cols="12">
                <input class="input-outline-bleu" type="text" icon-after v-model="origin" placeholder="Port of Load "/>
              </b-col>
              <b-col col lg="12" md="12" sm="12" cols="12" class="schedule-input">
                        <input  class="input-outline-bleu" type="text" icon-after v-model="destination" placeholder="Port of Discharge"/>
                    </b-col>
                     <b-col col lg="6" md="6" sm="12" cols="12">
                         <button class="button-yellow">Find Now</button>
                    </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import axios from "axios";
export default {
  name: "BookingForm",
  components: {},
  data() {
    return {
        origin: "",
        destination: "",
    };
  },
  created() {},

  methods: {
    async search() {
      if (!this.correctPassword) {
        try {
          const res = await axios.post(
            "https://mtl-feeder-nodejs.onrender.com/api/users",
            this.user
          );
          this.$router.push("/");
        } catch (error) {
          console.error(error);
        }
      }
    },
    onSubmit(e) {
      e.preventDefault();
    },
  },
};
</script>
<style lang="css" scoped>
.booking-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.booking-form {
  color: #fff;
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 40%;
  height: auto;
}
.booking-box .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1.2rem !important;
}
.booking-box {
  margin-bottom: 20vh !important;
  padding: 0rem 1rem;
}
/* *********************************************************************************************************** */
/* responsive */
@media only screen and (max-width: 966px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .booking-form {
    width: 100%;
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .booking-form {
    width: 100%;
    margin-left: 0% !important;
  }
  .booking-box {
    margin-bottom: 5vh !important;
  }
}

@media only screen and (max-width: 644px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .booking-form {
    width: 100%;
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 372px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .booking-form {
    width: 100%;
    margin-left: 0% !important;
  }
}
</style>