
<template lang="html">
  <div>
    <div class="about-schedule-content-bg">
      <b-row  class="about-schedule-content">
        <b-col  col lg="8" md="8" sm="12" offset-lg="2" offset-md="2" >
          <div class="text-center mt-25">
            <h2 class="h2-white mt-25">{{$t('aboutUsPage.scheduleSection.title')}} </h2>
            <p class="p-white">{{$t('aboutUsPage.scheduleSection.description')}}</p>
             <div class="text-center schedule-btn">
            <router-link  to="/quotation" class="button-yellow ">{{$t('aboutUsPage.scheduleSection.btn')}}</router-link>
            </div>

            <!-- <b-row  class="schedule-counter">
              <b-col col lg="2" md="2" sm="12" offset-lg="3" offset-md="3" class="mt-25">  
                  <p>99<span>Route</span></p>
              </b-col>
                <b-col col lg="2" md="2" sm="12"  class="mt-25">  
                  <p>99<span>Route</span></p>
              </b-col>
                <b-col col lg="2" md="2" sm="12"  class="mt-25">  
                  <p>99<span>Route</span></p>
              </b-col>
            </b-row> -->
          </div>
        </b-col>
      </b-row>
      <br>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUsSchedule",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},

  methods: {},
};
</script>
<style lang="css">
.about-schedule-content-bg{
background: linear-gradient(99.27deg, #082139 -5.24%, #0081DC 106.47%);
}
.about-schedule-content{
  background: url(../../assets/img/world.png);

  background-position: center;
  background-repeat: repeat-x;
  padding: 4.5rem 4rem;
}
.schedule-btn{
  margin-top: 5%!important;
}
.schedule-btn a{
  display: initial!important;
  padding: 0.8rem 1.5rem!important;
}
.about-schedule-counter {
 margin-top: 2.5rem!important;
}
.about-schedule-counter p{
 font-size: 25px;
 font-weight: 600;
 color: #fff;
}


</style>