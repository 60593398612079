
<template lang="html">
  <b-container fluid class="login-section mb-100">
    <b-row class="login-row ">
        <b-col class="login-form" lg="4" md="6" offset-md="3" offset-lg="4" offset-sm="2" sm="8">
        <b-row>
           <b-col  col lg="8" md="6" sm="12" xs="12" ><h2>Reset Password</h2></b-col>
      <b-col  col lg="6" md="6" sm="12" xs="12" class="text-right login-redirection"></b-col>
        </b-row>
        <b-container>
              <b-row>
               <b-col col lg="12" md="12" sm="12" cols="12" class="mt-10">
                <b-input-group>
                  <b-form-input   
                  @input="checkPassword"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" v-model="password" :type="passwordFieldTypeNew" :placeholder="$t('registrePage.placeholderCreatePassword')" required class="input-outline-yellow input-color-white"></b-form-input>
                  <b-input-group-append>
                    <b-icon
                      :icon="passwordToggleIconNew"
                      @click="togglePasswordNew"
                    />
                    </b-input-group-append> 
                      <div class="checkmark_container" v-bind:class="{ show_checkmark: valid_password }">		
                      <svg width="50%" height="50%" viewBox="0 0 140 100">
                        <path class="checkmark" v-bind:class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
                      </svg>
                    </div>  
                  </b-input-group>
              </b-col>
               <b-col col lg="12" md="12" sm="12" cols="12" class="mt-10">
                <b-input-group>
                  <b-form-input v-model="confirmPassword" :type="passwordFieldTypeRetype" :placeholder="$t('registrePage.placeholderConfirmPassword')" required class="input-outline-yellow input-color-white"></b-form-input>
                  <b-input-group-append>
                        <b-icon
                          :icon="passwordToggleIconRetype"
                          @click="togglePasswordRetype"
                        />
                    </b-input-group-append>  
                  </b-input-group>
              </b-col>
                 <b-col col lg="12" md="12" sm="12" cols="12" class="mt-10">
              	<ul class="password-strength">
                 	<li v-bind:class="{ is_valid: contains_eight_characters }">8 Characters</li>
                  <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
                  <li v-bind:class="{ is_valid: contains_uppercase }">Contains Uppercase</li>
                  <li v-bind:class="{ is_valid: contains_special_character }">Contains Special Character</li>
                </ul>

              
               </b-col>
            </b-row>


          <b-row>
            <b-button type="button" block class="login-button" @click="send()">Send</b-button>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from "axios";

export default {
  name: "LoginForm",
  components: {},
  data() {
    return {
        password_length: 0,
      contains_eight_characters: false,
      // contains_eight_characters_classe:"",
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,

      password: "",
      confirmPassword: "",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    correctPassword() {
      return (
        this.user.password !== "" &&
        this.user.confirmPassword !== "" &&
        this.user.password !== this.user.confirmPassword
      );
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "eye-slash" : "eye";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password" ? "eye-slash" : "eye";
    },
  },
  methods: {
        checkPassword() {
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
        // this.contains_eight_characters_classe="contains_eight_characters"
      } else {
        this.contains_eight_characters = false;
        // this.contains_eight_characters_classe=""
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    async send() {
       
      try {
        // const params = new URLSearchParams(location.search);
        // const { email, hash, fullName } = params;
         var email=this.$route.query.email
        var hash=this.$route.query.hash
        var fullName=this.$route.query.fullName
        console.log(hash)
        await axios
          .put("https://mtl-feeder-nodejs.onrender.com/api/account/password", {
            password: this.password,
            email:email,
            hash:hash,
            fullName:fullName,
          })
          .then((response) => {
            this.password=""
            this.confirmPassword=""
            this.$toast.open({
              message: "Password updated",
              type: "success",
              duration: 7000,
            });
            this.$router.push("/login");
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.$toast.open({
              message: "Error during update",
              type: "error",
              duration: 7000,
            });
          });
      } catch (error) {
        console.error(error);
      }
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>
<style lang="css" scoped>
.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  color: white;
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 2.5rem;
  /* margin: 1rem; */
  width: 40%;
  height: auto;
  margin-top: -10rem !important;
}
.login-section .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 1.7rem !important;
  margin-bottom: 1.7rem !important;
}
.login-button {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-redirection {
  display: flex;
  align-items: center;
  justify-content: right;
}
.login-button:hover,
.login-button:active {
  background-color: #ffd500e7 !important;
}
@media only screen and (max-width: 1193px) {
  .login-redirection {
    display: none !important;
  }
}
@media only screen and (max-width: 575px) {
  .login-form {
    width: 100%;
    /* margin-left: 0% !important; */
  }
}
</style>