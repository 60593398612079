<template>
    <vue-good-table
      styleClass="vgt-table bordered"
      theme="polar-bear"
        :line-numbers="true"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      class="voyage-tab mb-50"
    >
    <template
        slot="table-row"
        slot-scope="props"
      >
      <span v-if="props.column.field === 'option'">
        <b-form-radio name="radio" :value=props.row v-on:change="handleRadioChange"></b-form-radio>
      </span>
    </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap bottom-table-style">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
             {{$t('myQuickQuotePage.pagination.showing')}}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{$t('myQuickQuotePage.pagination.of')}} {{ props.total }} {{$t('myQuickQuotePage.pagination.entries')}} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <b-icon icon="chevron-compact-left"></b-icon>
              </template>
              <template #next-text>
                <b-icon icon="chevron-compact-right"></b-icon>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>  
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { VueGoodTable } from 'vue-good-table';
export default {
    name: "BookingVoyages",
    components: {
        VueGoodTable
    },
    props: {
        data: Object
    },
    data() {
        return {
            pageLength: 5,
            searchTerm: '',
            rows: [],
            columns: [
              {
                label: "",
                field: "option",
                sortable: false,
              },
              {
                label: this.$t('createBookingPageFromQuote.voyage.number'),
                field: 'numero',
                filterOptions: {
                    enabled: true,
                    placeholder: "",
                },
              },
              {
                label: this.$t('createBookingPageFromQuote.voyage.vessel'),
                field: 'vessel',
                filterOptions: {
                    enabled: true,
                    placeholder: "",
                },
              },
              {
                label: this.$t('createBookingPageFromQuote.voyage.startDate'),
                field: 'startDate',
                filterOptions: {
                    enabled: true,
                    placeholder: "",
                },
                formatFn: (value) => {
                  return moment(value).format('MM/DD/YYYY');
                },
              },
              {
                label: this.$t('createBookingPageFromQuote.voyage.endDate'),
                field: 'endDate',
                filterOptions: {
                    enabled: true,
                    placeholder: "",
                },
                formatFn: (value) => {
                  return moment(value).format('MM/DD/YYYY');
                },
              },
            ],
            voyagesStopovers: [],
            voyagesTrans : []
        }
    },
    async created() {
        const response = await axios.get('https://mtl-feeder-nodejs.onrender.com/api/voyages')  
        const trans = await axios.get('https://mtl-feeder-nodejs.onrender.com/api/transshipment')  
        const voyages = response.data.data
        .filter(voyage => voyage.loadingPort === this.data.loadingPort && voyage.dischargePort === this.data.dischargePort)
        .map(voyage => ({_id : voyage._id , startDate : voyage.startDate , endDate : voyage.endDate,
        vessel: voyage.vessel,numero: voyage.numero}));

        this.voyagesTrans = trans.data.data
        .filter(voyage => voyage.loadingPort === this.data.loadingPort && voyage.dischargePort === this.data.dischargePort)
        .map(voyage => ({_id : voyage._id , startDate : voyage.startDate , endDate : voyage.endDate,vessel: voyage.vessel, numero: voyage.numero , trans : true}));

        response.data.data.forEach(voyage => {
            const filteredVoyages = voyage.stopovers
            .filter(stopover => 
                stopover.loadingPort === this.data.loadingPort && stopover.dischargePort === this.data.dischargePort
            )
            .map(stopover => ({
            _id: voyage._id,
            startDate: stopover.startDate,
            endDate: stopover.endDate,
            vessel: voyage.vessel,numero: voyage.numero
            }));
            this.voyagesStopovers.push(...filteredVoyages);
        });
        if (voyages.length > 0) {
            this.rows.push(...voyages);
        }
        if (this.voyagesStopovers.length > 0) {
            this.rows.push(...this.voyagesStopovers);
        }
        if (this.voyagesTrans.length > 0) {
            this.rows.push(...this.voyagesTrans);
        }
    },
    methods: {
      handleRadioChange(value) {
        this.$emit('data-return', value);
      },
    },
}
</script>

<style>
.voyage-tab .vgt-table.polar-bear td {
  padding: .60em 0 .60em 0 !important;
  text-align: center;
  vertical-align: middle !important;
}
</style>