import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'
import { io } from "socket.io-client";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userId: localStorage.getItem('userData-I') || null,
    token: localStorage.getItem('userData-T') || null,
    role: localStorage.getItem('userData-R') || null,
    cloudinaryUrl: "https://res.cloudinary.com/dba2dcapc/image/upload/v1611178444/",
    socket: null,
    notifications: [],
    locale: localStorage.getItem('lang') || 'fr'
  },
  getters: {
    isLoggedIn(state) {
      return !!state.userId && !!state.token
    }
  },
  mutations: {
  SET_USER(state, user) {
    state.user = user;
    state.userId = (user) ? user._id : null;
    state.role = user ? user.role : null
    if (user) {
      localStorage.setItem("algerieDok-R", user.role);
      localStorage.setItem("algerieDok-U", user._id);
    }
    else {
      localStorage.removeItem("algerieDok-U");
      localStorage.removeItem("algerieDok-R");
    }
  },

  SET_TOKEN(state, token) {
    state.token = token
    if (token)
      localStorage.setItem('algerieDok-T', token)
    else
      localStorage.removeItem('algerieDok-T')
  },

  SET_SOCKET(state, socket) {
    state.socket = socket
  },

  SET_NOTIFS(state, notifs) {
    state.notifications = notifs
  },

  SET_LANGUAGE(state, lang) {
    state.locale = lang
    localStorage.setItem('lang', lang)
  }
},
  actions: {
  async getPatient({ state, commit }) {
    try {
      const res = await axios.get(`/patients/${state.userId}`)
      console.log(res.data);
      commit('SET_USER', res.data)
      return res.data
    }
    catch (err) {
      commit('SET_USER', null)
      throw err.response.data.message
    }
  },

  async getDoctor({ commit, state }) {
    try {
      const res = await axios.get(`/doctors/${state.userId}`)
      console.log(res.data);
      commit('SET_USER', res.data)
      return res.data
    }
    catch (err) {
      commit('SET_USER', null)
      throw err.response.data.message
    }
  },

  async createRoom({ state }, doctorId) {
    try {
      if (!state.userId)
        return false
      const users = [state.userId, doctorId]
      const res = await axios.post(`/chatRooms/`, { userId: state.userId, users })
      return res.data.chatRoom
    } catch (err) {
      throw err.response.data.message
    }
  },
  async createAdminRoom(_, doctorId) {
    try {
      // const users = ["600c5fb978abb54080f5efe9", doctorId]
      const res = await axios.post(`/chatRooms/admin`, { userId: doctorId })
      return res.data.chatRoom
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getChatRooms({ state }) {
    try {
      const res = await axios.get(`/chatRooms/${state.userId}`)
      return res.data.chatRooms
    } catch (err) {
      throw err.response.data.message
    }
  },

  async sendMessage(_, message) {
    try {
      const res = await axios.post(`/messages`, { message })
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getMessages(_, roomId) {
    try {
      const res = await axios.get(`/messages/room/${roomId}`)
      return res.data.messages
    } catch (err) {
      throw err.response.data.message
    }
  },

  async sendReaction(_, { messageId, reactions }) {
    try {
      const res = await axios.put(`/messages/message/${messageId}`, { reactions })
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async updateMessage(_, { messageId, message }) {
    try {
      const res = await axios.put(`/messages/message/${messageId}`, message)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async deleteMessage(_, { messageId }) {
    try {
      const res = await axios.delete(`/messages/message/${messageId}`)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async getNotifications({ commit, state }) {
    try {
      const res = await axios.get(`/notifications/${state.userId}`)
      commit("SET_NOTIFS", res.data)
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  async updateNotification(_, notifId) {
    try {
      const res = await axios.put(`/notifications/${notifId}`)
      console.log(res.data);
      return res.data
    } catch (err) {
      throw err.response.data.message
    }
  },

  connectSocket({ commit, state, dispatch }) {
    const socket = io("https://algedoc-backend.herokuapp.com/");
    // const socket = io("https://algedoc-backend.herokuapp.com/");
    // const socket = io("https://algedoc-backend.herokuapp.com");
    socket.on('connect', () => {
      socket.emit('joinConnectedUsers', { userId: state.user._id })
      console.log("joined socket");

      socket.on('appointmentAccepted', () => {
        console.log("accepted");
        dispatch('getNotifications')
      })
      socket.on('appointmentDeclined', () => {
        dispatch('getNotifications')
      })

      socket.on('accountVerified', (notif) => {
        dispatch('getNotifications')
        Vue.$toast.open({
          message: notif.description,
          type: "success",
        });
      })
    })

    commit('SET_SOCKET', socket)
  },

  closeSocket({ commit, state }) {
    if (state.socket) {
      state.socket.emit('disconnected', { userId: state.userId })
      console.log("closing socket");
      state.socket.close()
      commit('SET_SOCKET', null)
    }
  },

  sendAppointmentNotification({ state }, doctorId) {
    console.log("sending appointment notif");
    state.socket.emit('takeAppointment', { doctorId, userId: state.userId })
  }
  },
  modules: {
  }
});
