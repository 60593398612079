<template lang="html">
  <b-container class="information-box-form">
      <div class="information-form">
            <!-- <b-row class="text-right mb-25 mr-25">
                <b-col  col lg="12" md="12" sm="12" cols="12">
                    <router-link to=""><b-icon icon="plus-circle"></b-icon> Edit</router-link>
                </b-col>
            </b-row> -->
            <b-row class="mt-50">
                <b-col  col lg="12" md="12" sm="12" xs="12" cols="12" class="mb-25">
                    <div class="mb-2 text-center">
                        <!-- <b-avatar src="https://placekitten.com/300/300" size="7rem"></b-avatar> -->
                        <div class="user-avatar">
                          <span>
                            <font-awesome-icon icon="user" size="lg"/>
                          </span>
                        </div>
                    </div>
                </b-col>
                 <b-col  col lg="12" md="12" sm="12" xs="12" cols="12">
                    <div class="mb-2 text-center">
                      <span v-if="user.code">ID: {{user.code}}</span>
                      <span v-else="user.code">ID: No Code yet</span>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-50">
              <b-col offset-lg="3" offset-md="2" col lg="3" md="4" sm="6" cols="12">
                <input v-model="user.firstName" type="text" :placeholder="$t('informationPage.personalInformation.firstName')" required class="input-outline-bleu-border"></input>
              </b-col>
              <b-col col lg="3" md="4" sm="6" cols="12">
                <input v-model="user.lastName" type="text" :placeholder="$t('informationPage.personalInformation.secondName')" required class="input-outline-bleu-border"></input>
              </b-col>
            </b-row>
            <b-row class="mt-25">
              <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12">
                <input v-model="user.user.email" type="email" :placeholder="$t('informationPage.personalInformation.email')" required class="input-outline-bleu-border"></input>
              </b-col>
            </b-row>
            <b-row class="mt-25">
              <b-col offset-lg="3" offset-md="2" col lg="2" md="3" sm="4" cols="12">
                <!-- <input v-model="user.phonePrefix" type="text" :placeholder="$t('informationPage.personalInformation.prefix')" required class="input-outline-bleu-border"></input> -->
           <v-select v-model="user.phonePrefix" :options="info" :reduce="info => info.dial_code" label="dial_code" 
                  class="input-outline-bleu-border-select"/>
              </b-col>
              <b-col col lg="4" md="5" sm="8" cols="12">
                <input v-model="user.phoneNumber" type="text" :placeholder="$t('informationPage.personalInformation.phone')" required class="input-outline-bleu-border"></input>
              </b-col>
            </b-row>


            <b-row class="mt-50">
              <b-col  offset-lg="3" offset-md="2" col lg="3" md="4" sm="6" cols="12">
                <h5>{{$t('informationPage.company.title')}}</h5>
              </b-col>
              <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12">
                <input v-model="user.company" type="text" :placeholder="$t('informationPage.company.companyName')" required class="input-outline-bleu-border"></input>
              </b-col>
            </b-row>
            <b-row class="mt-25">
              <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12">
                <!-- <input v-model="user.country" type="text" :placeholder="$t('informationPage.company.country')" required class="input-outline-bleu-border"></input> -->
             <v-select v-model="user.country" :options="info" :reduce="info => info.name" label="name" 
                  class="input-outline-bleu-border-select"/>
              </b-col>
            </b-row>
            <b-row class="mt-25"> 
              <b-col offset-lg="3" offset-md="2" col lg="6" md="6" sm="12" cols="12">
                <input v-model="user.city" type="text" :placeholder="$t('informationPage.company.city')" required class="input-outline-bleu-border"></input>
              </b-col>
                <!-- <b-col col lg="3" md="4" sm="6" cols="12">
                <input v-model="user.zipCode" type="text" placeholder="Zip Code" required class="input-outline-bleu-border"></input>
              </b-col> -->
            </b-row>
            
            <b-row class="mt-25">
              <b-col offset-lg="3" offset-md="2" col lg="6" md="6" sm="12" cols="12">
                <input v-model="user.address" type="text" :placeholder="$t('informationPage.company.address')" required class="input-outline-bleu-border"></input>
              </b-col>
            </b-row>
            <b-row class="mb-50 mt-25"> 
              <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12">
                <input v-model="user.taxRegistrationNumber" type="text" :placeholder="$t('informationPage.company.taxRegistrationNumber')" required class="input-outline-bleu-border"></input>
              </b-col>
            </b-row> 
  <!-- <b-row class="mb-50 mt-25"> 
              <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12">
                <input v-model="user.taxRegistrationFile" type="text" :placeholder="$t('registrePage.placeholderTaxFile')" required class="input-outline-bleu-border"></input>
              </b-col>
            </b-row>  -->
            <b-row class="mt-50 mb-50"> 
                  <b-col offset-lg="3" offset-md="2" col lg="3" md="4" sm="6" cols="12">
                      <b-button class="button-bleu" type="submit" @click="updateInformation()" > {{$t('informationPage.company.updateBtn')}}</b-button>
                   </b-col>
              </b-row>  

            <b-row class="mt-50 mb-50 input_container-validator">
              <b-col  offset-lg="3" offset-md="2" col lg="3" md="4" sm="6" cols="12">
                <h5>{{$t('informationPage.settings.settingsName')}}</h5>
              </b-col>
              <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12" >
                <b-input-group class="group">
                  <b-form-input v-model="password.oldPassword" :type="passwordFieldTypeOld" :placeholder="$t('informationPage.settings.oldPassword')" required class="input-outline-bleu-border"></b-form-input>
                  <b-input-group-append>
                    <b-icon
                      class="Eye-icon"
                      :icon="passwordToggleIconOld"
                      @click="togglePasswordOld"
                    />
                    </b-input-group-append>  
                </b-input-group>
              </b-col>
               <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12" class="mt-25">
                 <b-input-group>
                <b-form-input 
                @input="checkPassword"
                v-model="password.newPassword" :type="passwordFieldTypeNew" :placeholder="$t('informationPage.settings.newPassword')" required class="input-outline-bleu-border"></b-form-input>
                <b-input-group-append>
                    <b-icon
                      :icon="passwordToggleIconNew"
                      @click="togglePasswordNew"
                    />
                    </b-input-group-append>  
                </b-input-group>
              </b-col>
               <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12" class="mt-25">
                 <b-input-group>
                    <b-form-input v-model="confirmPassword" :type="passwordFieldTypeRetype" :placeholder="$t('informationPage.settings.confirmPassword')" required class="input-outline-bleu-border"></b-form-input>
                    <b-input-group-append>
                        <b-icon
                          :icon="passwordToggleIconRetype"
                          @click="togglePasswordRetype"
                        />
                    </b-input-group-append>  
                  </b-input-group>
              </b-col>
               <b-col offset-lg="3" offset-md="2" col lg="6" md="8" sm="12" cols="12" class="mt-25">
              	<ul class="password-strength">
                 	<li v-bind:class="{ is_valid: contains_eight_characters }">8 Characters</li>
                  <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
                  <li v-bind:class="{ is_valid: contains_uppercase }">Contains Uppercase</li>
                  <li v-bind:class="{ is_valid: contains_special_character }">Contains Special Character</li>
                </ul>

              
               </b-col>
            </b-row>
              <b-row class="mt-50 mb-50"> 
                  <b-col offset-lg="3" offset-md="2" col lg="4" md="6" sm="6" cols="12">
                      <b-button class="button-bleu" type="submit" @click="updatePassword()"> {{$t('informationPage.settings.validateBtn')}}</b-button>
                   </b-col>
              </b-row>  
        </div> 
    </b-container>
</template>
<script>
import axios from "axios";
import country from "./../../assets/files/country.json";

export default {
  name: "InformationForm",
  components: {},
  data() {
    return {
      info: country,
      user: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        phonePrefix: "",
        company: "",
        country: "",
        city: "",
        // zipCode: "",
        address: "",
        taxRegistrationNumber: "",
      },
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      password: {
        oldPassword: "",
        newPassword: "",
      },
      confirmPassword: "",
      password_length: 0,
      contains_eight_characters: false,
      // contains_eight_characters_classe:"",
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
    };
  },
  created() {},
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password" ? "eye-slash" : "eye";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "eye-slash" : "eye";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password" ? "eye-slash" : "eye";
    },
  },
  async created() {
    await this.getAllInformation();
  },
  methods: {
    checkPassword() {
      console.log("tetst");
      this.password_length = this.password.newPassword.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
        // this.contains_eight_characters_classe="contains_eight_characters"
      } else {
        this.contains_eight_characters = false;
        // this.contains_eight_characters_classe=""
      }

      this.contains_number = /\d/.test(this.password.newPassword);
      this.contains_uppercase = /[A-Z]/.test(this.password.newPassword);
      this.contains_special_character = format.test(this.password.newPassword);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    async getAllInformation() {
      var idUser = JSON.parse(localStorage.getItem("userData"));
      //console.log(idUser);
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/customers?id=" + idUser.user.customer._id
      );
      //console.log(response);
      this.user = response.data.data[0];
    },
    updateInformation() {
      console.log(this.user);
      axios
        .put("https://mtl-feeder-nodejs.onrender.com/api/users/" + this.user.user._id, this.user)
        .then((response) => {
          console.log(response);
          this.$toast.open({
            message: "Your information has been updated",
            type: "success",
            duration: 7000,
          });
        })
        .catch((error) => {
          this.$toast.open({
            message: "Error during update",
            type: "error",
            duration: 7000,
          });
          this.errorMessage = error.message;
        });
    },
    updatePassword() {
      if (this.confirmPassword == this.password.newPassword) {
        axios
          .put("https://mtl-feeder-nodejs.onrender.com/api/users/" + this.user.user._id, {
            password: this.password.newPassword,
          })
          .then((response) => {
            console.log(response);
            this.$toast.open({
              message: "Your information has been updated",
              type: "success",
              duration: 7000,
            });
          })
          .catch((error) => {
            this.$toast.open({
              message: "Error during update",
              type: "error",
              duration: 7000,
            });
            this.errorMessage = error.message;
          });
      } else {
        this.$toast.open({
          message: "Password not match",
          type: "error",
          duration: 7000,
        });
      }
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
  },
};
</script>
<style lang="css" scoped>
.information-box-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.information-form {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}
.user-avatar span {
  border: 1px solid #fff;
  padding: 1.7rem 1.7rem 1.5rem 1.7rem;
  border-radius: 50%;
  background: #fff;
}

.eye-icon {
  position: absolute;
  float: right;
}
.group {
  position: relative;
}

/* .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1.2rem !important;
} */
</style>