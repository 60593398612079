<template lang="html">
    <div>
         <Hero imgLink="/img/shutterstock2.jpg"/>
         <div>
          <div class="ceo-content">
            <b-row class="">
              <b-col col lg="6" md="6" sm="12" xs="12" >
                <div class="ceo-img">
                  <img src="../assets/img/ceo.jpg" alt="">
                </div>
              </b-col>
              <b-col col lg="6" md="6" sm="12" cols="12" >
                <div class="ceo-card-text-right">
                  <h2>{{$t('ceoPage.ceoMessage.titleCeoMessage')}}</h2>
                    <p class="text-justify"  v-html="$t('ceoPage.ceoMessage.textCeoMessage0')">
                      <!-- <h4>{{$t('ceoPage.ceoMessage.ceoAuthor')}}</h4> -->
                      <!-- <div class="ceo-sig">
                          <img src="../assets/img/signature.png" alt="">
                      </div> -->
                    </p>
                  </div>
              </b-col>
            </b-row>
             <b-row class="mt-100">
              <b-col col lg="6" md="6" sm="12" cols="12" >
                <div class="ceo-card-text-left">
                    <p class="text-justify"  v-html="$t('ceoPage.ceoMessage.textCeoMessage3')">
                    </p>
                    <div style="float:right">
                         <h5 class=""  v-html="$t('ceoPage.ceoMessage.ceoAuthor')">
                    </h5>
                        <h5 class=""  v-html="$t('ceoPage.ceoMessage.ceoAuthorCompany')">
                    </h5>
                      </div>
                 
                  </div>
              </b-col>
               <b-col col lg="6" md="6" sm="12" xs="12" class="dl-img">
                <div class="ceo-img">
                  <img src="../assets/img/ceo.jpg" alt="">
                </div>
              </b-col>
            </b-row>
             <b-row class="mt-50">
           
              <!--    <b-col col lg="6" md="6" sm="12" xs="12" >
                <div class="ceo-img">
                  <img src="../assets/img/ceo.jpg" alt="">
                </div>
              </b-col>
                 <b-col col lg="6" md="6" sm="12" cols="12" >
                <div class="ceo-card-text-right">
                    <p class="text-justify"  v-html="$t('ceoPage.ceoMessage.textCeoMessage7')">
                    </p>
                  </div>
              </b-col> -->
            </b-row>
          </div>
        </div>
      <AboutUsTimeLine/>
         <!-- <CeoTimeLine/> -->
         <Partners/>
    </div>
</template>
<script>
import Partners from "../components/Partners.vue";
// import CeoTimeLine from "../components/Ceo/CeoTimeLine.vue";
import Hero from "../components/Hero.vue";
import AboutUsTimeLine from "../components/AboutUs/AboutUsTimeLine.vue";

export default {
  name: "Ceo",
  components: {
    Hero,
    Partners,
    // CeoTimeLine,
    AboutUsTimeLine,
  },
  methods: {},
};
</script>
<style lang="css">
.ceo-content {
  padding: 8rem 12rem 8rem 12rem;
}
/* .ceo-content-h2 {
  font-size: 35px;
  color: #082139;
}
.ceo-content-p {
  font-size: 18px;
  color: #082139;
  width: 70%;
} */
/* img */
.ceo-img img {
  width: 100%;
  height: 100%;
}
.dl-img{
display: flex;
    justify-content: center;
    align-items: center;
}

.ceo-sig img {
  margin-top: 2rem;
  width: 200px;
  height: 120px;
}
.ceo-card-text-left {
  margin-right: 10% !important;
}
.ceo-card-text-left h2 {
  font-size: 30px;
  color: #082139;
  font-weight: bolder;
}
.ceo-card-text-left p {
  font-size: 17px;
  color: #212529;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.ceo-card-text-right {
  margin-left: 10% !important;
}
.ceo-card-text-right h2 {
  font-size: 30px;
  color: #082139;
  font-weight: bolder;
}
.ceo-card-text-right p {
  font-size: 17px;
  color: #212529;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 966px) {
  .ceo-content {
    padding: 4rem 6rem 4rem 6rem;
  }
}
@media only screen and (max-width: 768px) {
  .ceo-img {
    display: none;
  }
}
@media only screen and (max-width: 503px) {
  .ceo-content {
    padding: 1rem 2rem 1rem 2rem;
  }
}
</style>
