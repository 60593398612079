<template lang="html">
  <b-card body-class="text-center" header-tag="nav" class="card-text second-padding">
     <h2 class="h2-blue"> {{$t('AcceptTermAndConditionPage.title')}}</h2>
    <b-card-text class="accept-text">
     <p class="text-justify mt-25" v-html="$t('AcceptTermAndConditionPage.textTermAndCondition')"></p>
   </b-card-text>
  </b-card>
</template>
<script lang="js">
export default {
  name: 'AcceptText'
}
</script>
<style lang="css" scoped>

.accept-text {
  padding: 0rem !important;
  text-align: left !important;
}

@media only screen and (max-width: 768px) {
 .card-text{
  width: 100%!important;
  margin-left: 0%!important;
}
}
</style>