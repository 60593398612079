<template lang="html">
    <div>
        <Hero imgLink="/img/port.jpg"/>
        <AcceptText />
    </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import AcceptText from "../components/AcceptTermAndCondition/AcceptText.vue";

export default {
  name: "Login",
  components: {
    Hero,
    AcceptText,
  },
  methods: {},
};
</script>
<style lang="css"></style>