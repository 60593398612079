<template lang="html">
    <div>
      <Hero imgLink="/img/shutterstock2.jpg" />
      <!-- <OurFleetHero /> -->
      <div>
        <div class="fleet-content">
          <b-row class="">
           
            <!-- <b-col col lg="3" md="6" sm="12" cols="12" class="image-fleet">
              <div class="fleet-img">
                <img src="../assets/img/home.jpeg" alt="">
              </div>
            </b-col> -->
            <b-col col lg="12" md="12" sm="12" cols="12" >
              <div class="">
                <h2 class="h2-blue text-center">{{$t('ourFleetPage.ourFleetSection.title')}}</h2>
                <!-- <p class="p-black">{{$t('ourFleetPage.ourFleetSection.text')}}</p> -->
                <b-table striped bordered hover :items="items1" :fields="fields1"></b-table>

              </div>
            </b-col>
             <b-col col lg="6" md="6" sm="12" cols="12" class="image-fleet mt-25">
              <div class="fleet-img">
                <img src="../assets/img/fleet.png" alt="">
              </div>
            </b-col>
            <b-col col lg="6" md="6" sm="12" cols="12" class="mt-25">
              <b-table striped bordered hover :items="items2" :fields="fields2"></b-table>
            </b-col>
             <b-col col lg="6" md="6" sm="12" cols="12" class=" mt-25">
              <b-table striped bordered hover :items="items3" :fields="fields3"></b-table>
            </b-col>
             <b-col col lg="6" md="6" sm="12" cols="12" class=" mt-25">
              <b-table striped bordered hover :items="items4" :fields="fields4"></b-table>
            </b-col>
             <b-col col lg="12" md="6" sm="12" cols="12" class=" mt-25">
              <p>Vessel is able to load container of upto 2,595 m breadth with a distance of 25 mm in all positions on
                deck. </p>
              <h3>Stability condition</h3>
              <p>400 TEU x 14 mtons hom.<br>
                Intakes are always subject to vessel’s stability, trim, deadweight, permissible stack weights, exact
                container weights, container lashing manual, subject to regulations of visibility and at Master’s
                discretion.</p>
              <h3>Reefer connections</h3>
              <p>100 reefer plugs on deck<br>
                10 reefer plugs in hold<br>
              110 reefer plugs total (AC 380/440 V 50/60 cycles 32 AMP – earth pin at 3 o’clock
              </p>
              <h3>Stackweights</h3>
              <p>Container stackweights (all in metric tons per stack)</p>
               <b-table striped bordered hover :items="items5" :fields="fields5"></b-table>
                <p>Distribution of container weights within a single 20’/40’ stack on deck to comply with the board manual
for stowage and lashing of containers approved by Germanischer Lloyd.</p>
                 <h3>Fittings</h3>
                <ul>
                  <li>  Fully cellularized in holds for 40’ containers. Alternatively 2 x 20’ units can be stowed into each
                        40’ compartment. </li>
                  <li>  Vessel is fully fitted with loose lashing materials, fittings and stacking cones for 20’ and 40’
                        units under and on deck </li>
                  <li>Fully equipped with all modern nautical / communication systems</li>
                </ul>
                <p>Vessel is electrically ventilated /  6-fold air changes basis empty holds </p>
                <p>3 holds / 4 hatches – Hydraulic operated folding steel hatchcovers </p>
                 <h3>Hatch dimensions:</h3>
                  <b-table striped bordered hover :items="items6" :fields="fields6"></b-table>
                  <p>Grain / Bale Capacity: 364700 / 350300 cbf</p>
                  <h3>Dangerous Cargo</h3>
              <p>The vessel is able to carry dangerous goods in holds and on deck in packed condition according to
              Solas regulation II-2/54 and in accordance with the Document of Compliance for the Carriage of
              Dangerous Goods. However, IMO 1 / 2 / 6.2 / 7 cargo always to be excluded. <br>
              All other allowed cargoes mentioned in IMDG code which are not explicit excluded by Owners to be 
              packed / labeled / loaded / stowed / shipped / discharged always in accordance with the IMO-, local-,
              federal-, and canal- and / or river- and Coast Guard regulations and vessel’s class and certificates.
              </p>
              <h3>Speed / Consumption</h3>
              <p>All figures are about and based on good weather conditions and smooth sea maximum Beaufort 2 and
              maximum Douglas Sea State 2, and basis design draft of 6,90 m on even keel, in deep water with
              clean bottom and currentless waters, maximum 28 degr. Celsius – with shaft generator connected for
              sea navigation only:<br>
              <ul>
                 <li>  17.0 knots on 26.0 mtons IFO 380. </li>
                 <li> 16.0 knots on 22.0 mtons IFO 380. </li>
                 <li> 15.0 knots on 18.5 mtons IFO 380. </li>
                 <li> 14.0 knots on 17.0 mtons IFO 380. </li>
                 <li> 13.0 knots on 15.5 mtons IFO 380. </li>
               </ul>
                No MGO at sea provided the shaft generator is connected, except when reefer containers are carried
                or hold ventilation being used, or during bad weather or in case of emergency and / or navigation with 
                <br>
                reduced speed and / or in restricted areas like approaches, shallow waters and when maneuvering in
                rivers / channels / canals / in port resp. when shifting.
                <br>
                Gasoil may be consumed for safety reasons. Charterers to provide sufficient quantity of GasOil during
                sea passages for operating auxiliaries / generators in case of an emergency.
                <br>
                Port consumption:
                Average consumption abt. 1.5 mtons GasOil per day when idle, excluding reefers and tank heating.
                Exact consumption depends on weather conditions and other circumstances, vessel consuming
                GasOil in port for pre-heating.
              </p>
              <h3>Fuel Specification</h3>
              <p>The fuel oil supplied to be in accordance with:<br>
                  RMG 380  for IFO 380<br>
                  DMA   for Gasoil<br>
                  The IFO 380 grade has to be in accordance with ISO fuel standard 8217:2010 RMG 380 or latest
                  amendment thereto and always according to latest International regulations resp. local / federal
                  regulations of vessel’s trade, like IMO / Marpol etc., incl. but not limited to Marpol VI.
                  <br>
                  The MGO to be in accordance with ISO fuel standard 8217:2010 DMA with max density of 860 kg /
                  cbm and max 0.2 pct sulphur content or latest amendment thereto and always according to latest
                  International regulations resp. local / federal regulations of vessel’s trade, like IMO / Marpol etc., incl.
                  but not limited to Marpol VI. The Charterers shall supply fuel of such specifications and grades to
                  permit the Vessel, at all times, to comply with the maximum sulphur content requirements of any
                  emission control zone when the vessel is ordered to trade within that zone.
                  <br>
                  Charterers shall supply suitable fuels to enable main propulsion and auxiliary machinery to operate
                  efficiently and without harmful effects. Fuels to contain no waste lubricants or chemicals. All fuel
                  delivered to the vessel has to be a mineral oil product and shall not contain taroil and / or inorganic
                  acid substances and to be of stable and homogen nature. Charterers not to comingle different bunker
                  stems in the same storage tank. The Charterers shall supply fuel of such specifications and grades to
                  permit the Vessel, at all times, to comply with the maximum sulphur content requirements of any
                  emission control zone when the vessel is ordered to trade within that zone.
                  <br>
                  The vessel will participate in the Lintec fuel quality testing program. Samples will be taken during each
                  bunkering. Costs involved for such testing to be equally shared between Owners and Charterers.
                  <br>
                  Sludge removal, if any, always to be for Charterers’ account and time.

                  </p>
              <h3>Engine</h3>
              <p>MaK Type 8 M 43 with abt. 7.200 KW at 500 rpm<br>
                Pitch propeller Lips<br>
                BowThruster with 410 kW<br>
                Shaft Generator of 1.200 KW<br>
                2 x Diesel Generators with 350 KVA each<br>
                1 x Emergency Generator with 350 KVA<br>

                All details are about and without guarantee.</p>
            </b-col>
          </b-row>
        </div>
      </div>
      <AboutUsTimeLine/>
      <AboutUsSchedule />
      <Partners />
  </div>
</template>
<script>
// import OurFleetHero from "../components/OurFleet/OurFleetHero.vue";
import Hero from "../components/Hero.vue";
import Partners from "../components/Partners.vue";
// import OurFleetTimeLine from "../components/OurFleet/OurFleetTimeLine.vue";
// import OurFleetSchedule from "../components/OurFleet/OurFleetSchedule.vue";
import AboutUsTimeLine from "../components/AboutUs/AboutUsTimeLine.vue";
import AboutUsSchedule from "../components/AboutUs/AboutUsSchedule.vue";

export default {
  name: "OurFleet",
  components: {
    // OurFleetHero,
    Hero,
    Partners,
    // OurFleetTimeLine,
    // OurFleetSchedule,
    AboutUsTimeLine,
    AboutUsSchedule,
  },
  data() {
    return {
      // Note `isActive` is left out and will not appear in the rendered table
      fields1: ["Name", "Value"],
      items1: [
        { Name: "Call Sign", Value: "TNSF" },
        { Name: "IMO-No.", Value: "9244192" },
        { Name: "Flag", Value: "Tunisian" },
        { Name: "Home Port", Value: "LA GOULETTE" },
        {
          Name: "Class",
          Value:
            "Bureau Veritas + Container ship (SOLAS II-2, Reg. 19) – equipped for carriage of containers Unrestricted navigation   ",
        },
        { Name: "GT / NT ", Value: "6191 /  3075" },
        { Name: "Deadweight", Value: "8.003 tdwat sfb-draft abt. 7,00 m" },
        { Name: "Built", Value: "2002" },
        { Name: "Last Dry-dock", Value: "July 2017" },
        { Name: "Next Dry-dock", Value: "July 2022" },
        { Name: "P&I Club", Value: "Steamship Mutual" },
        { Name: "MMSI No. ", Value: "672 000 030" },
        { Name: "Satcom-C ", Value: "conmarfjord1@skyfile-c.com" },
        { Name: "E-Mail ", Value: "charterer.conmarfjord@conmar-fleet.de" },
        { Name: "Mobile Phone ", Value: "+216 98 199 039" },
      ],

      fields2: ["Dimensions", "Value"],
      items2: [
        { Dimensions: "LOA  ", Value: "132,60 m" },
        { Dimensions: "LBP", Value: "123,40 m" },
        { Dimensions: "Beam (moulded)", Value: "19,20 m" },
        { Dimensions: "Draft (ssw)", Value: "7,00 m" },
      ],

      fields3: ["Tank_capacities", "Value"],
      items3: [
        { Tank_capacities: "Waterballast max", Value: "2857 cbm" },
        { Tank_capacities: "Freshwater max", Value: "107 cbm" },
        { Tank_capacities: "Bunkers IFO", Value: "347 mtons" },
        { Tank_capacities: "GO", Value: "321 mtons" },
      ],

      fields4: ["Value", "20'", "40'"],
      items4: [
        {
          Value: "In holds",
          "20'": "188 TEU",
          "40'": "91 FEU +  6 TEU",
        },
        {
          Value: "On deck",
          "20'": "519 TEU",
          "40'": "251 FEU + 17 TEU",
        },
        {
          Value: "Total",
          "20'": "707 TEU",
          "40'": "342 FEU + 23 TEU",
        },
      ],

      fields5: ["Value", "20'", "40'"],
      items5: [
        {
          Value: "Tanktop",
          "20'": "62.7 mtons",
          "40'": "102 mtons",
        },
        {
          Value: "Hatchcovers",
          "20'": "43.5 mtons",
          "40'": "65 mtons",
        },
        {
          Value: "Deck (in front of accommodation)",
          "20'": "36.0 mtons",
          "40'": "54 mtons (for 24’6” containers only)",
        },
      ],

      fields6: ["No.1A", "12,30m x 12,90m / 7,94m", "- 40’ can be handled"],
      items6: [
        {
          "No.1A": "No.1B  -",
          "12,30m x 12,90m / 7,94m": "12,30m x 15,52m",
          "- 40’ can be handled":
            "- both 40’ sections can be handled separately /  independently, if transverse containers are not  loaded.",
        },
        {
          "No.1A": "No.2  -",
          "12,30m x 12,90m / 7,94m": "25,20m x 15,52m",
          "- 40’ can be handled":
            "- both 40’ sections can be handled separately / independently, if transverse containers are not loaded.",
        },
        {
          "No.1A": "No.3  -",
          "12,30m x 12,90m / 7,94m": "25,20m x 15,52m",
          "- 40’ can be handled":
            "- both 40’ sections can be handled separately / independently, if transverse containers are not loaded.",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="css">
.fleet-content {
  padding: 8rem 12rem 8rem 12rem;
}
.fleet-content-h2 {
  font-size: 35px;
  color: #082139;
}
.fleet-content-p {
  font-size: 18px;
  color: #082139;
  width: 70%;
}

/* img */
.fleet-img img {
  width: 95%;
  height: 78%;
}
.fleet-card-text {
  margin-left: 5% !important;
}
.fleet-card-text h2 {
  font-size: 35px;
  color: #082139;
  font-weight: bolder;
}
.fleet-card-text p {
  font-size: 20px;
  color: #082139;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 991px) {
  .fleet-content {
    padding: 2rem 3rem 2rem 3rem;
  }
  .fleet-card-text {
    text-align: center;
    margin-top: 5rem;
  }
}
@media only screen and (max-width: 768px) {
  .image-fleet {
    display: none;
  }
}
</style>
