
<template lang="html">
  <b-container class="tracking-box">
      <b-row class="tracking-row">
        <b-col col lg="4" md="4" sm="12" cols="12" class="tracking-form ">
          <b-container>
            <b-row>
              <b-col col lg="12" md="12" sm="12" cols="12">
                <h2>{{$t('profileMenu.links.tracking')}}</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label>{{$t('trackingPage.trackingForm.subtitle')}}</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col col lg="8" md="8" sm="12" cols="12">
                <input v-model="tracking.idBl" type="text" :placeholder="$t('trackingPage.trackingForm.placeholderBl')" required class="input-outline-yellow"/>
              </b-col>
              <b-col col lg="4" md="4" sm="12" cols="12">
                <b-button type="button" block class="tracking-button" @click="searchBl">{{$t('homePage.hero.tracking.btn')}}</b-button>
              </b-col>
            </b-row>
                <b-row>
              <b-col col lg="8" md="8" sm="12" cols="12">
                <input v-model="tracking.idContainer" type="text" :placeholder="$t('trackingPage.trackingForm.placeholderContainer')" required class="input-outline-yellow"/>
              </b-col>
              <b-col col lg="4" md="4" sm="12" cols="12">
                <b-button type="button" block class="tracking-button" @click="searchContainer">{{$t('homePage.hero.tracking.btn')}}</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p>{{$t('trackingPage.trackingForm.description')}}</p>
              </b-col>
            </b-row>
          </b-container>
        </b-col>


           <b-col col lg="8" md="8" sm="12" cols="12">
            <b-card
              class="result-tracking">
                <b-card-text class="empty breadcrumb-text">
                  <div v-if="resultSearch.length > 0 && clickSerch">
                      <div class="data-structure" v-for="(item, index) in resultSearch" :key="index">
                        <b-row class="results-schedule1">
                            <b-col cols lg="3" md="4" sm="6" xs="12">
                              <div>
                                <div class="h4-blue"><b-icon icon="truck" font-scale="1.5"></b-icon>&nbsp; {{$t('schedulingPage.schedulingResult.vessel')}}</div>
                                <div class="cell" v-if="item.booking.voyage">{{ item.booking.voyage.vessel }}</div>
                                <div class="cell" v-if="item.booking.voyage"><span style="font-weight:normal">{{ item.booking.voyage.numero }}</span></div>
                              </div>  
                            </b-col>
                             <b-col cols lg="3" md="4" sm="6" xs="12">
                              <div>
                                <div class="h4-blue"><b-icon icon="box" font-scale="1.5"></b-icon>&nbsp; {{$t('schedulingPage.schedulingResult.vessel')}}</div>
                                  <div>
                                    <div class="cell" v-for="(containerElement, index) in item.booking.commodityContainer" :key="index">
                                       <div class="cell" v-if="containerElement.container.containerType">{{ containerElement.container.containerType }}</div>
                                        <div class="cell" v-if="containerElement.container.reference"><span style="font-weight:normal">{{ containerElement.container.reference }}</span></div>
                                  </div>
                              </div>                               
                              </div>  
                            </b-col>
                            <b-col cols lg="2" md="4" sm="6" xs="12">
                              <div>
                                <div class="h4-blue"><b-icon icon="geo-alt" font-scale="1.5"></b-icon>&nbsp; {{$t('schedulingPage.schedulingResult.pol')}}</div>
                                <div class="cell">{{ item.booking.loadingPort }}</div>
                              </div>  
                            </b-col>
                            <b-col cols lg="2" md="4" sm="6" xs="12">  
                                <div >
                                    <div class="h4-blue"><b-icon icon="geo-alt" font-scale="1.5"></b-icon>&nbsp; {{$t('schedulingPage.schedulingResult.pod')}}</div>
                                    <div class="cell">{{ item.booking.dischargePort }}</div>
                                </div> 
                            </b-col>
                             <b-col cols lg="2" md="4" sm="6" xs="12">  
                                <div >
                                    <div class="h4-blue"><b-icon icon="calendar-date" font-scale="1.3"></b-icon>&nbsp; Date</div>
                                    <div class="cell">{{ formatDate(item.booking.effectiveDate) }}</div>
                                    <div class="cell">{{ formatDate(item.booking.expiryDate) }}</div>
                                </div> 
                            </b-col>                           
                        </b-row>
                        <!-- <b-row class="results-schedule2">
                            <b-col>
                                <div><b-icon icon="alarm" font-scale="1.3"></b-icon>&nbsp; {{$t('schedulingPage.schedulingResult.deadlines')}}</div>
                            </b-col>
                        </b-row>
                        <b-row class="results-schedule2">
                            <b-col cols lg="3" md="6" sm="6" xs="12" cols="12">
                                <div >
                                    <a href="#" class="deadline-data">{{$t('schedulingPage.schedulingResult.containerGateIn')}}</a>
                                    <div class="data-link"> {{ item.Deadlines.Container }} </div>
                                </div>  
                            </b-col>
                            <b-col cols lg="3" md="6" sm="6" xs="12" cols="12">  
                                <div >
                                    <a href="#" class="deadline-data">{{$t('schedulingPage.schedulingResult.shippingInstructions')}}</a>
                                    <div class="data-link">{{ item.Deadlines.Shipping }}</div>
                                </div> 
                            </b-col>
                            <b-col cols lg="3" md="6" sm="6" xs="12" cols="12">  
                                <div >
                                    <a href="#" class="deadline-data">{{$t('schedulingPage.schedulingResult.shippingInstructionsAMS')}}</a>
                                    <div class="data-link">{{ item.Deadlines.ShippingAMS }}</div>
                                </div> 
                            </b-col>
                            <b-col cols lg="3" md="6" sm="6" xs="12" cols="12">  
                                <div >
                                    <a href="#" class="deadline-data">{{$t('schedulingPage.schedulingResult.verifiedGrossMass')}}</a>
                                    <div class="data-link">{{ item.Deadlines.VerifiedMass }}</div>
                                </div> 
                            </b-col>
                          </b-row> -->
                      </div>
                  </div>
                  <div v-else>
                    {{$t('trackingPage.trackingResult')}}
                  </div>
                        
                </b-card-text>
            </b-card>
        </b-col> 
      </b-row>
     
    </b-container>

</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "TrackingForm",
  components: {},
  data() {
    return {
      tracking: {
        idBl: "",
        idContainer: "",
      },
      resultSearch: [],
      clickSerch: false,
      dataBl: [],
    };
  },
  async created() {
    await this.getAllDataBL();
  },
  methods: {
    async getAllDataBL() {
      const response = await axios.get(
        "https://mtl-feeder-nodejs.onrender.com/api/bls"
      );
      this.dataBl = response.data.data;
    },
    searchContainer() {
      this.tracking.idBl = "";
      console.log(this.tracking.idContainer,this.dataBl)
      if (this.tracking.idContainer != "") {
        this.resultSearch = this.dataBl.filter((objet) => {
          return objet.cargoDetails.some(
            (cargo) => cargo.containerNumber === this.tracking.idContainer
          );
        });
        if (this.resultSearch.length > 0) {
          this.clickSerch = true;
        } else {
          this.$toast.open({
            message: this.$t("newBLPage.infoBlSearch"),
            type: "info",
            duration: 3000,
          });
        }
        this.clickSerch = true;
      } else {
        this.$toast.open({
          message: this.$t("newBLPage.errorBlSearch"),
          type: "info",
          duration: 3000,
        });
      }
    },
    searchBl() {
      this.tracking.idContainer = "";
      if (this.tracking.idBl != "") {
        this.resultSearch = this.dataBl.filter(
          (objet) => objet.booking.reference == this.tracking.idBl
        );
        // this.resultSearch.push({
        //   Port: "CMA CGM PUCCINI",
        //   Code: "144S | 148N",
        //   Terminal: "Flinders Adelaide Container Termina",
        //   Arrival: "27 Feb 2022 03:46",
        //   Departure: "28 Feb 2022 22:00",
        //   Deadlines: {
        //     Container: "N/A",
        //     Shipping: "04 Mar 2022 22:00",
        //     ShippingAMS: "02 Mar 2022 11:00",
        //     VerifiedMass: "03 Mar 2022 22:00",
        //   },
        // });
        if (this.resultSearch.length > 0) {
          this.clickSerch = true;
          console.log(this.resultSearch);
        } else {
          this.$toast.open({
            message: this.$t("newBLPage.infoBlSearch"),
            type: "info",
            duration: 3000,
          });
        }
        this.clickSerch = true;
      } else {
        this.$toast.open({
          message: this.$t("newBLPage.errorBlSearch"),
          type: "info",
          duration: 3000,
        });
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
  },
};
</script>
<style lang="css" scoped>
.tracking-box {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding: 2.5rem 4rem !important;
}
.tracking-form {
  color: white;
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 40%;
  height: auto;
}
.tracking-box .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1.2rem !important;
}
.tracking-redirection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tracking-button {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-outline-yellow {
  color: #ffffff !important;
}
.tracking-box {
  margin-bottom: 20vh !important;
  padding: 0rem 1rem;
}
.empty.breadcrumb-text {
  color: #6c757d !important;
}
.card {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.scheduling-box-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scheduling-form {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}

.btnSubmit {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.title-h1 {
  margin-left: 2em;
}
.description {
  margin-left: 5em;
}

.results-schedule1 {
  background-color: #ececec;
  padding: 18px 16px 16px;
}
.results-schedule2 {
  /* background-color: #ececec; */
  padding: 12px 16px 0px;
}
.data-structure {
  margin: 0.3rem;
}
.link-details {
  color: #F2AE65 !important;
  text-decoration: underline !important;
  /* text-align: center !important; */
  padding-left: 1.5rem;
  font-weight: bold;
}
.data-link {
  padding-left: 1.5rem;
}
.cell {
  padding-left: 1.5rem;
  font-weight: bold;
}
.deadline-data {
  padding-left: 1.5rem;
  font-weight: bold;
  font-size: 15px;
  /* margin-top: -6rem ; */
}

@media only screen and (max-width: 768px) {
  .result-tracking {
    margin-bottom: 5rem !important;
  }
}

/* *********************************************************************************************************** */
/* responsive */
/*@media only screen and (max-width: 1347px) {
  .container {
    display: block;
  }
}*/
@media only screen and (max-width: 966px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .tracking-form {
    width: 100%;
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .tracking-form {
    width: 100%;
    margin-left: 0% !important;
  }
  .tracking-button {
    margin-top: 1rem !important;
  }
  .tracking-box {
    margin-bottom: 5vh !important;
  }
}

@media only screen and (max-width: 644px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .tracking-form {
    width: 100%;
    margin-left: 0% !important;
  }
}
@media only screen and (max-width: 372px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .tracking-form {
    width: 100%;
    margin-left: 0% !important;
  }
}
</style>