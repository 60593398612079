<template lang="html">
  <div class="my-quote-layout">
    <QuickQuoteBreadcrumb />
    <div class="forms-padding">
      <b-row class="row-eq-height">
        <b-col col lg="3" md="3" sm="12" cols="12" class="profile-menu-card">
            <profileMenu />
        </b-col>
        <b-col col lg="9" md="9" sm="12" cols="12" class="forms-quoation-card mb-100">
          <QuickQuoteTab />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import QuickQuoteBreadcrumb from "../components/MyQuickQuote/QuickQuoteBreadcrumb.vue";
import profileMenu from "../components/Profile/profileMenu.vue";
import QuickQuoteTab from "../components/MyQuickQuote/QuickQuoteTab.vue";

export default {
  name: "MyQuote",
  components: {
    QuickQuoteBreadcrumb,
    profileMenu,
    QuickQuoteTab,
  },
  methods: {},
};
</script>
<style lang="css">
.my-quote-layout {
  padding-top: 25vh !important;
}
@media only screen and (max-width: 1077px) {
 .profile-menu-card{
     padding: 0px!important;
  }
  .forms-quoation-card{
      padding: 0px!important;
  }
}
</style>