<template lang="html">
    <b-container class="quotation-box-form">
      <div class="quotation-form" v-if="role==='customer'">
            <b-row>
                <b-col>
                    <div>
                            <div title="Step 2">
                                 <p class="p-3">
                                    <b-row>
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newQuickQuotePage.routeSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                            <div v-if="form.loadingPort">
                                                <span class="subTitle-forms">
                                                    {{$t('newQuickQuotePage.routeSection.fromPort')}}:
                                                </span>
                                                <span>
                                                    {{form.loadingPort}}
                                                </span>                                                
                                            </div>
                                        </b-col>

                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12">
                                            <div v-if="form.dischargePort">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.routeSection.ToPort')}}:
                                                </span>
                                                <span>
                                                    {{form.dischargePort}}
                                                </span>                 
                                            </div>                                         
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newQuickQuotePage.commoditySection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                            <div v-if="form.commodity">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.commoditySection.commodityLabel')}}:
                                                </span>
                                                <span>
                                                    {{form.commodity}}
                                                </span>
                                            </div>
                                        </b-col>

                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12">
                                            <div v-if="form.TemperatureControl">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.commoditySection.TemperatureLabel')}}:
                                                </span>
                                                <span>
                                                    {{form.TemperatureControl}}
                                                </span>
                                            </div>
                                        </b-col>

                                        <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                                            <div v-if="form.specialEquipment.height">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.commoditySection.HeightLabel')}}:
                                                </span>
                                                <span>
                                                    {{form.specialEquipment.height}}
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                                            <div v-if="form.specialEquipment.width">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.commoditySection.WidthLabel')}}:
                                                </span>
                                                <span>
                                                    {{form.specialEquipment.width}}
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col lg="4" md="4" sm="12" xs="12" cols="12">
                                            <div v-if="form.specialEquipment.length">
                                                <span class="subTitle-forms">
                                                    {{ $t('newQuickQuotePage.commoditySection.LengthLabel')}}:
                                                </span>
                                                <span>
                                                    {{form.specialEquipment.length}}
                                                </span>
                                            </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12" >
                   
                                                 <div v-if="form.commodityClass">
                                                    <span class="subTitle-forms">
                                                       {{ $t('newQuickQuotePage.commoditySection.classeLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{form.commodityClass}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="6" xs="12" cols="12" >
                                           <div v-if="form.commodityUn">
                                                    <span class="subTitle-forms">
                                                       {{ $t('newQuickQuotePage.commoditySection.unLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{form.commodityUn}}
                                                    </span>
                                                </div>
                                        </b-col> 

                                    </b-row>     
                           <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newQuickQuotePage.containerSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                     <b-row v-for="(item, index) in form.containers" :key="index">
                                        <b-col lg="4" md="4" sm="6" xs="12" cols="12">
                                            <div v-if="item.containerType">
                                                    <span class="subTitle-forms">
                                                       {{$t('newQuickQuotePage.containerSection.containerTypeLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.containerType}}
                                                    </span>
                                                </div>
                                        </b-col>
                                        <b-col>
                                        <div v-if="item.quantity">
                                                    <span class="subTitle-forms">
                                                       {{$t('newQuickQuotePage.containerSection.containerQuantityLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.quantity}}
                                                    </span>
                                                </div>
                                        </b-col>
                                             
                                                     
                                        <b-col >
                                              <div v-if="item.weight">
                                                    <span class="subTitle-forms">
                                                       {{$t('newQuickQuotePage.containerSection.approximateWeightLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{item.weight}}
                                                    </span>
                                                </div>
                                        </b-col>    
                                    </b-row>

                                                           <b-row class="mt-25">
                                        <b-col class="words-divider" cols="12">
                                            <p>{{$t('newQuickQuotePage.dateSection.title')}}</p>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12"> 

                                                <div v-if="form.estimationDate">
                                                    <span class="subTitle-forms">
                                                       {{$t('newQuickQuotePage.dateSection.pricingDateLabel')}}:
                                                    </span>
                                                      <span>
                                                         {{form.estimationDate}}
                                                    </span>
                                                </div>    
                                        </b-col>
                                    </b-row>
 
                                    <b-row v-if="form.estimationPrice">
                                        <b-col lg="12" md="12" sm="12" xs="12" cols="12"> 
                                            <div class="quick-price">Total Price : {{ totalPrice }} TND</div>    
                                        </b-col>
                                    </b-row>   
                                </p>
                        </div>
                    </div>
                </b-col>
            </b-row>
             <br>
        </div>
        <div class="quotation-form text" v-else>{{$t('messageAccess')}}</div>
    </b-container>
</template>
<script>
import axios from "axios";
import moment from 'moment';
export default {
    name: "QuotationForm",
    components: {},
    data() {
    return {
        role : JSON.parse(localStorage.getItem("userData")).user.role,
        form: {
            customer: JSON.parse(localStorage.getItem("userData")).user._id,
            arrivalPortAgent: null,
            departurePortAgent: null,
            loadingPort: null,
            dischargePort: null,
            commodity: null,
            /*requiresTemperatureControl: false,*/
            requiresSpecialEquipment:false,
            dangerousCargo:false,
            TemperatureControl: null,
            specialEquipment : {
                height: null,
                width: null,
                length: null,
            },
            commodityClass: null,
            commodityUn: null,
            containers: [
                {
                    id : null,
                    containerType: null,
                    quantity: 1,
                    weight: '1000.000',
                    isOwnedByShipper: false,
                }
            ],
            estimationDate: null,
        },
        ports: [],
        departurePorts: [],
        arrivalPorts: [],
        commodities : [],
        classes: [],
        uns : [],
        containers : [],
        selectedOption : null, 
        departurePortId: null,
        arrivalPortId: null,
        classPrice: null,
        unPrice: null,
        showPrice: false,
        totalPrice: null,
        maxWeight : null,
        maxPrice : null,
      }
    },
    async created() {
        await this.getData()
    },

    methods: {
        async getData() {
            const response = await axios.get(
            "https://mtl-feeder-nodejs.onrender.com/api/instant-estimations?id="+ this.$router.history.current.query.id
            );  
            this.form = response.data.data
            this.form.estimationDate = moment(this.form.estimationDate).format('YYYY-MM-DD');
            this.totalPrice = this.form.estimationPrice
            if (this.form.requiresSpecialEquipment) {
                this.selectedOption = "radio2"
            }
            else if (this.form.dangerousCargo) {
                this.selectedOption = "radio3"
                this.form.specialEquipment = {
                    height: null,
                    width: null,
                    length: null
                }
            }
            else {
                this.form.specialEquipment = {
                    height: null,
                    width: null,
                    length: null
                }
            }
        },
        financial(item) {
            item.weight = Number.parseFloat(item.weight).toFixed(3)
            return Number.parseFloat(item.weight).toFixed(3)
        },
  },
};
</script>
<style lang="css" scoped>
.quotation-box-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quotation-box-form .text {
    text-align: center;
    padding-bottom: 20px !important;
}
.quotation-form {
  color: #082139;
  background: #f3f3f3;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
}
/* .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 1.2rem !important;
} */
.btnReset {
    color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
    margin-top: 10px;
}
.btnSubmit {
  color: white !important;
  background: #082139  !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.addRequest {
    background-color: #fff;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addContainer {
    border: none;
}

.delete-container {
    color: red;
    border: none;
}
.delete-container-div{
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
}


.words-divider {
    border: 1px solid;
    border-radius: 10px 10px 10px 10px;
    padding-top: 8px;
    color: white;
    background-color: #082139;
    height: 40px;
}
.words-divider p {
    font-weight: 500;
}

.cercle-steps{
    border: 2px solid #082139;
    color: #082139;
    border-radius: 50%;
    padding: 0.05rem 0.35rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;
}
.quotation-form .nav-tabs .nav-link.active .cercle-steps{
    color: #F2AE65!important;
    border-color: #F2AE65!important;
}
.quotation-form .nav-tabs .nav-link.active span{
    color:#F2AE65!important;
}

@media only screen and (max-width: 575px) {
 .btn-footer-forms .ml-25{
    margin-left: 0px!important;
  } 
}
/* Commodity Radios */
.commodity-radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

/* Quick Quote Total Price */
.quick-price {
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}
</style>