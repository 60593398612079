<template lang="html">
    <div>
      <b-container class="booking-header-box">
        <b-row>
          <h1 class="breadcrumb-header">{{$t('profileMenu.links.editBooking')}}</h1>
        </b-row>
      </b-container>
      <b-container class="booking-breadcrumb-box">
        <b-row>
          <!-- <b-breadcrumb :items="items" class="breadcrumb-links"></b-breadcrumb> -->
           <b-breadcrumb class="breadcrumb-links">
            <b-breadcrumb-item href="../">{{$t('nav.home')}}</b-breadcrumb-item>
            <b-breadcrumb-item active>{{$t('profileMenu.links.booking')}}</b-breadcrumb-item>
            <b-breadcrumb-item active>{{$t('profileMenu.links.editBooking')}}</b-breadcrumb-item>
          </b-breadcrumb>
        </b-row>
      </b-container>
    </div>
</template>
<script lang="js">
export default {
  name: 'BookingBreadcrumbEdit',
  data() {
      return {
      }
    }
}
</script>
<style lang="css" scoped>
.booking-breadcrumb-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vh !important;
}
.booking-header-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadcrumb-links {
  background-color: #fff !important;
}
.breadcrumb-links li:last-child {
  font-weight: 600;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">"
}
.breadcrumb-item.active {
  color: #082139 !important;
}
li:not(.breadcrumb-item.active) {
  color: #6c757d !important;
}
.breadcrumb-header {
  color: #082139 !important;
}
</style>