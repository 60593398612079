<template lang="html">
    <div class="home">
        <Hero imgLink="/img/boat.jpg"/>
        <div class="home-container">
          <div class="home-content">
            <h2 class="home-content-h2">
               {{$t('homePage.missionSection.title')}}
               </h2>
            <div class="title-line"></div>
             <b-row>
              <b-col col lg="4" md="4" sm="12" cols="12">
                <p class="home-content-p" v-html="$t('homePage.missionSection.subTitle1')"></p>
              </b-col>
               <b-col col lg="4" md="4" sm="12" cols="12">
                <p class="home-content-p" v-html="$t('homePage.missionSection.subTitle2')"></p>
              </b-col>
               <b-col col lg="4" md="4" sm="12" cols="12">
                <p class="home-content-p" v-html="$t('homePage.missionSection.subTitle3')"></p>
              </b-col>
            </b-row>

            <b-row>
              <b-col col lg="4" md="4" sm="12" cols="12" align="center" justify="center" class="home-card-service">
                  <div class="home-sub-card-img1">

                <div class="home-sub-card ">
                      <h3>{{$t('homePage.missionSection.servicesSection.title')}}</h3>
                        <p>{{$t('homePage.missionSection.servicesSection.description')}}</p>
                        <div class="home-card-icon">
                          <font-awesome-icon icon="concierge-bell" />
                        </div>
                      </div>
                  </div>
                 
              </b-col>

              <b-col col lg="4" md="4" sm="12" cols="12" align="center" justify="center" class="home-card-service">
                <div class=" home-sub-card-img2"> 
                    <div class="home-sub-card">
                  <h3>{{$t('homePage.missionSection.solutionsSection.title')}}</h3>
                  <p>{{$t('homePage.missionSection.solutionsSection.description')}}</p>
                  <div class="home-card-icon">
                    <font-awesome-icon icon="lightbulb" />
                  </div>
                  </div>
                </div>
              </b-col>
              <b-col col lg="4" md="4" sm="12"  cols="12" align="center" justify="center" class="home-card-service">

              <div class=" home-sub-card-img3"> 
                    <div class="home-sub-card">
                  <h3>{{$t('homePage.missionSection.fleetSection.title')}}</h3>
                  <p>{{$t('homePage.missionSection.fleetSection.description')}}</p>
                  <div class="home-card-icon">
                    <font-awesome-icon icon="ship" />
                  </div>
                </div>
              </div>
              </b-col>
            </b-row>

            <b-row class="mt-50 mb-50">

              <b-col col lg="6" md="6" sm="12" cols="12">

                <div class="home-img">
                  <router-link to="/quotation"><img src="../assets/img/ship.jpg" alt=""></router-link>

                </div>
              </b-col>
              <b-col col lg="6" md="6" sm="12" cols="12">
                <div class="home-card-text">
                  <h2>{{$t('homePage.quotationSection.title')}}</h2>
                  <div class="title-line"></div>
                  <p class="text-justify">{{$t('homePage.quotationSection.description')}}</p>
                  <router-link to="/quotation"><button class="button-yellow">{{$t('homePage.quotationSection.btn')}}</button></router-link>
                </div>
              </b-col>
            </b-row>

          </div>
        </div>
        <HomeSchedule/>
        <Partners/>
    </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import HomeSchedule from "../components/Home/HomeSchedule.vue";
import Partners from "../components/Partners.vue";

export default {
  name: "HomePage",
  components: {
    Hero,
    HomeSchedule,
    Partners,
  },
  created() {
    if (this.$route.query.isVerified == "true") {
      this.$toast.open({
        message: "Your account is verified",
        type: "success",
        duration: 7000,
      });
    }
  },
  methods: {},
};
</script>
<style lang="css">
.home,
.home-container {
  min-height: 100vh !important;
}
.home-sub-card {
  background: #08213980;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  /* margin: 0rem 0.5rem !important; */
}

.home-sub-card-img2 {
  background: url(../assets/img/home2.jpeg);
  height: 30vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border-radius: 12px 12px 12px 12px; */
}
.home-sub-card-img1 {
  background: url(../assets/img/home.png);
  height: 30vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border-radius: 12px 12px 12px 12px; */
}
.home-sub-card-img3 {
  background: url(../assets/img/home3.jpeg);
  height: 30vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border-radius: 12px 12px 12px 12px; */
}
.home-sub-card h3 {
  color: #fff;
  font-size: 25px;
  padding-top: 2rem;
}
.home-sub-card p {
  color: #fff;
  font-size: 16px;
  padding-top: 1rem;
}
.home-content {
  padding: 2.5rem 4rem;
}
.home-content-h2 {
  font-size: 35px;
  color: #082139;
}
.home-content-p {
  font-size: 18px;
  color: #000;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
/* icon */
.home-card-icon {
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, -50%);
  background: #082139;
  padding: 1rem 1.3rem;
  border-radius: 12px 12px 12px 12px;
  color: #fff;
}
/* img */
.home-img img {
  margin-top: 15% !important;
  /* margin-left: 10% !important; */
  width: 100%;
  max-height: 400px;
}

.home-card-text {
  margin-top: 25% !important;
  margin-left: 10%;
}
.home-card-text h2 {
  font-size: 35px;
  color: #082139;
}
.home-card-text p {
  font-size: 18px;
  color: #212529;
  width: 80%;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 768px) {
  .home-card-service {
    margin-bottom: 3rem !important;
  }
}
@media only screen and (max-width: 445px) {
  .home-content {
    padding: 1rem 0.5rem;
  }
  .home-content h2 {
    padding-right: 25px;
    padding-left: 25px;
  }
  .home-content p {
    padding-right: 25px;
    padding-left: 25px;
  }
  .home-sub-card {
    height: 100% !important;
  }
}
</style>
