<template lang="html">
    <div>
        <Hero imgLink="/img/port.jpg"/>
        <LoginForm />
        <LoginRegister />
        
    </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import LoginForm from "../components/Login/LoginForm.vue";
import LoginRegister from "../components/Login/LoginRegister.vue";

export default {
  name: "Login",
  components: {
    Hero,
    LoginForm,
    LoginRegister
  },
    created() {
    if (this.$route.query.isVerified == "true") {
      this.$toast.open({
        message: "Your account is verified",
        type: "success",
        duration: 7000,
      });
    }
  },
  methods: {},
};
</script>
<style lang="css"></style>
