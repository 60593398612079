
<template lang="html">
  <b-container class="register-box">
      <b-row class="login-row">
        <b-col class="login-form" lg="6" md="6" offset-md="3" offset-lg="3" sm="12">
          <b-form @submit.stop.prevent="signUp()">
          <b-container>
            <b-row>
              <b-col col lg="8" md="12" sm="12" cols="12">
                <h2>{{$t('registrePage.title')}}</h2>
              </b-col>
              <b-col class="text-right login-redirection" col lg="4" md="12" sm="12" cols="12">
                <router-link to='/login'><font-awesome-icon icon="arrow-right" />  {{$t('loginPage.title')}}</router-link>
              </b-col>
            </b-row>
              <b-row>
              <b-col col lg="12" md="12" sm="12" cols="12">
                <input v-model="user.username" type="text" :placeholder="$t('registrePage.placeholderUsername')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
            </b-row>
            <b-row>
              <b-col col lg="6" md="6" sm="12" cols="12">
                <input v-model="user.firstName" type="text" :placeholder="$t('registrePage.placeholderFirstname')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
              <b-col col lg="6" md="6" sm="12" cols="12">
                <input v-model="user.lastName" type="text" :placeholder="$t('registrePage.placeholderSecondName')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input v-model="user.email" type="email" :placeholder="$t('registrePage.placeholderEmail')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <!-- <input v-model="user.phonePrefix" type="text" :placeholder="$t('registrePage.placeholderPrefix')" required class="input-outline-yellow input-color-white"></input> -->
              <!-- <b-form-select
               v-model="user.phonePrefix"
               :options="info"
               value-field="dial_code"
                text-field="dial_code"
               required
               class="input-outline-white-border"
               ></b-form-select> -->

               <v-select v-model="user.phonePrefix" :options="info" :reduce="info => info.dial_code" label="dial_code" 
                  class="input-outline-white-border-select"/>


              </b-col>
              <b-col lg="8">
                <input v-model="user.phoneNumber" @keypress="onlyNumberKey($event)" type="tel" :placeholder="$t('registrePage.placeholderPhone')" required class="input-outline-yellow input-color-white"></input>
                <!-- <input v-model="user.phoneNumber" type="number" :placeholder="$t('registrePage.placeholderPhone')" required class="input-outline-yellow input-color-white scroll-number"></input> -->
              </b-col>
            </b-row>
            <!-- <b-row class="title-signup-form">
              <b-col>
                <label class="companyTitle">{{$t('registrePage.titleCompany')}}</label> 
              </b-col>
            </b-row> -->
            <b-row>
              <b-col>
                <input v-model="user.company" type="text" :placeholder="$t('registrePage.placeholderCompanyName')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <!-- <input v-model="user.country" type="text" :placeholder="$t('registrePage.placeholderCountry')" required class="input-outline-yellow input-color-white"></input> -->
              <!-- <b-form-select
               v-model="user.country"
               :options="info"
               value-field="name"
                text-field="name"
               required
               class="input-outline-white-border"
               ></b-form-select> -->
                <v-select v-model="user.country" :options="info" :reduce="info => info.name" label="name" 
                  class="input-outline-white-border-select"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input v-model="user.city" type="text" :placeholder="$t('registrePage.placeholderCity')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
              <b-col>
                <input v-model="user.zipCode" type="text" :placeholder="$t('registrePage.placeholderZipCode')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input v-model="user.address" type="text" :placeholder="$t('registrePage.placeholderAddress')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
            </b-row>

                <b-row class="title-signup-form">
              <b-col>
                <label class="companyTitle">Company registration document</label> 
              </b-col>
            </b-row>
            <b-row class="mt-0">
                <b-col col lg="12" md="12" sm="12" cols="12" class="input-file">
                <input  type="button" @click.prevent="openUploadModal" :value="btnValue" :placeholder="$t('registrePage.placeholderTaxFile')" class="input-outline-yellow input-color-white"></input>
           <span class="small-text">Le fichier doit être : PDF, JPG, JPEG, PNG et le taille ne dépasse pas 10 Mega</span>
            <!-- v-model="user.taxRegistration" -->
              </b-col>
            </b-row>
                        <b-row>
                 <b-col col lg="12" md="12" sm="12" cols="12">
                <input v-model="user.taxRegistrationNumber" type="text" :placeholder="$t('registrePage.placeholderTax')" required class="input-outline-yellow input-color-white"></input>
              </b-col>
                </b-row>
            <b-row class="input_container-validator">
              <b-col col lg="6" md="6" sm="12" cols="12">
                <b-input-group>
                  <b-form-input pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  v-model="user.password" 
                  @input="checkPassword"
                  :type="passwordFieldTypeNew" 
                  :placeholder="$t('registrePage.placeholderCreatePassword')" 
                  required class="input-outline-yellow input-color-white"
                  :title="$t('registrePage.passwordConditions')"></b-form-input>
                  <b-input-group-append>
                    <b-icon
                      :icon="passwordToggleIconNew"
                      @click="togglePasswordNew"
                    />
                    </b-input-group-append> 
                    <div class="checkmark_container" v-bind:class="{ show_checkmark: valid_password }">		
                      <svg width="50%" height="50%" viewBox="0 0 140 100">
                        <path class="checkmark" v-bind:class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
                      </svg>
                    </div> 
                  </b-input-group>
              </b-col>
              <b-col col lg="6" md="6" sm="12" cols="12">
                <b-input-group>
                  <b-form-input v-model="user.confirmPassword" 
                  @input="checkConfirmPassword"
                  :type="passwordFieldTypeRetype" :placeholder="$t('registrePage.placeholderConfirmPassword')" required class="input-outline-yellow input-color-white"></b-form-input>
                  <b-input-group-append>
                        <b-icon
                          :icon="passwordToggleIconRetype"
                          @click="togglePasswordRetype"
                        />
                    </b-input-group-append>  
                  </b-input-group>
              </b-col>
               <b-col col lg="6" md="6" sm="6" cols="6" class="mt-10">
              	<ul class="password-strength">
                 	<li v-bind:class="{ is_valid: contains_eight_characters }">8 Characters</li>
                  <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
                  <li v-bind:class="{ is_valid: contains_uppercase }">Contains Uppercase</li>
                  <li v-bind:class="{ is_valid: contains_special_character }">Contains Special Character</li>
                </ul>

              
               </b-col>
                 <b-col col lg="6" md="6" sm="6" cols="6" class="mt-10">
                    <span class="small-text text-danger" v-if="testConfirmPasswordWrong">The confirm password is incorrect</span>
                    <span class="small-text text-correct" v-if="testConfirmPasswordCorrect">The confirm password is correct</span>
               </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox id="checkbox-1" v-model="acceptCookies" name="acceptCookies" value=true unchecked-value=false>
                  {{$t('registrePage.acceptTerm')}}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button type="submit" block class="login-button" required>{{$t('loginPage.signUpBtn')}}</b-button>
              </b-col>
            </b-row>
          </b-container>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import axios from "axios";
import country from "./../../assets/files/country.json";

export default {
  name: "SignUpForm",
  components: {},
  data() {
    return {
      btnValue:"add Company registration document",
      testConfirmPasswordCorrect: false,
      testConfirmPasswordWrong: false,
      password_length: 0,
      contains_eight_characters: false,
      // contains_eight_characters_classe:"",
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      url: "",
      publicId: "",
      user: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        phonePrefix: "+216",
        company: "",
        country: "Tunisia",
        city: "",
        zipCode: "",
        address: "",
        taxRegistrationNumber: "",
        taxRegistration: "",
        password: "",
        confirmPassword: "",
        role: "customer",
      },
      info: country,
      formError: false,
      acceptCookies: false,
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    correctPassword() {
      return (
        this.user.password !== "" &&
        this.user.confirmPassword !== "" &&
        this.user.password !== this.user.confirmPassword
      );
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "eye-slash" : "eye";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password" ? "eye-slash" : "eye";
    },
  },
  created() {
    // axios
    //   .get("https://ipinfo.io")
    //   .then((response) => {
    //     console.log("location", response.city, response.country);
    //   })
    //   .catch((error) => {
    //     console.log("location", error);
    //   });
  },
  async mounted() {
    await axios
      .get("http://ipinfo.io/json?token=7f2f4735626039")
      .then((response) => {
        console.log("location", response.data);
        country.forEach((element) => {
          if (response.data.country == element.code) {
            this.user.phonePrefix = element.dial_code;
          }
        });
      });
  },
  methods: {
    checkConfirmPassword() {
      if (this.user.confirmPassword == "" || !this.user.confirmPassword) {
        this.testConfirmPasswordCorrect = false;
        this.testConfirmPasswordWrong = false;
      } else {
        if (this.user.password != this.user.confirmPassword) {
          this.testConfirmPasswordCorrect = false;
          this.testConfirmPasswordWrong = true;
        } else {
          this.testConfirmPasswordWrong = false;
          this.testConfirmPasswordCorrect = true;
        }
      }
    },

    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dtd7nsjuf", upload_preset: "fcjp4dy2" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.user.taxRegistration = result.info.public_id;
              this.btnValue = "Your file name is: " + result.info.original_filename;
            }
          }
        )
        .open();
    },
    checkPassword() {
      this.password_length = this.user.password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
        // this.contains_eight_characters_classe="contains_eight_characters"
      } else {
        this.contains_eight_characters = false;
        // this.contains_eight_characters_classe=""
      }

      this.contains_number = /\d/.test(this.user.password);
      this.contains_uppercase = /[A-Z]/.test(this.user.password);
      this.contains_special_character = format.test(this.user.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    onlyNumberKey(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async reset() {
      this.user.username = "";
      this.user.firstName = "";
      this.user.lastName = "";
      this.user.email = "";
      this.user.phoneNumber = "";
      this.user.phonePrefix = "+216";
      this.user.company = "";
      this.user.country = "Tunisia";
      this.user.city = "";
      this.user.zipCode = "";
      this.user.address = "";
      this.user.taxRegistrationNumber = "";
      this.user.password = "";
      this.user.confirmPassword = "";
      this.user.role = "customer";
      this.user.taxRegistration = "";
    },
    async signUp() {

       if (!this.correctPassword) {
          try {
            await axios
              .post(
                "https://mtl-feeder-nodejs.onrender.com/api/signup",
                this.user
              )
              .then((response) => {
                const loggedIn = response.data;
                this.$toast.open({
                  message: "An email has been sent, please verify your account",
                  type: "success",
                  duration: 7000,
                });
                this.reset();
                this.$router.push("/login");
              })
              .catch((error) => {
                this.errorMessage = error.message;
                this.$toast.open({
                  message: "Your Email already exist",
                  type: "error",
                  duration: 7000,
                });
                this.reset();
              });
          } catch (error) {
            this.reset();
          }
        }
      //         setTimeout(() => {
      //   this.$router.push("/login");
      // }, "1000");
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
  },
};
</script>
<style lang="css" scoped>
.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  color: white;
  background: #082139;
  border-radius: 12px 12px 12px 12px;
  padding: 1.5rem 2.5rem;
  /* margin: 1rem; */
  margin-top: -10rem !important;
  width: 40%;
  height: auto;
}
.register-box .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem !important;
}
.login-redirection {
  display: flex;
  align-items: center;
  justify-content: right;
}
.login-button {
  color: #082139 !important;
  background: #F2AE65 !important;
  border: none;
  border-radius: 12px 12px 12px 12px !important;
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-box {
  margin-bottom: 20vh !important;
}

body #app .title-signup-form {
  padding-top: 2.5rem;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.companyTitle {
  font-weight: bold;
}
/* Chrome, Safari, Edge, Opera */
.scroll-number::-webkit-outer-spin-button,
.scroll-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.scroll-number[type="number"] {
  -moz-appearance: textfield;
}
.input-file .input-outline-yellow{
    border: 2px solid #fff;
}
/* *********************************************************************************************************** */
/* responsive */
/*@media only screen and (max-width: 1347px) {
  .container {
    display: block;
  }
}*/
@media only screen and (max-width: 1193px) {
  .login-redirection {
    display: none !important;
  }
}
@media only screen and (max-width: 966px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .login-form {
    width: 100%;
    /* margin-left: 0% !important; */
  }
}
@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .login-form {
    width: 100%;
    /* margin-left: 0% !important; */
  }
}
@media only screen and (max-width: 644px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .login-form {
    width: 100%;
    /* margin-left: 0% !important; */
  }
}
@media only screen and (max-width: 372px) {
  .container {
    width: 100%;
    margin-left: 0% !important;
  }
  .login-form {
    width: 100%;
    /* margin-left: 0% !important; */
  }
}
.small-text {
  font-size: 13px;
}
</style>

