<template lang="html">
    <div>
        <Hero imgLink="/img/port.jpg"/>
        <ContactForm />
        <ContactText />
    </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import ContactForm from "../components/Contact/ContactForm.vue";
import ContactText from "../components/Contact/ContactText.vue";
export default {
  name: "Login",
  components: {
    Hero,
    ContactForm,
    ContactText
  },
  methods: {},
};
</script>
<style lang="css"></style>
