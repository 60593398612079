<template>
    <vue-good-table
        styleClass="vgt-table bordered"
        theme="polar-bear"
        :line-numbers="true"
        :columns="columns"
        :rows="data"
        :search-options="{
            enabled: true,
            externalQuery: searchTerm
        }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        class="cargo-details mb-50"
    >
        <template
            slot="table-row"
            slot-scope="props"
        >
            <span v-if="props.column.field === 'description'">
                <p class="column-desc">{{ props.row.commodity.description }}</p>
            </span>
            <span v-if="props.column.field === 'container'">
                <p v-if="props.row.container.reference">{{ props.row.container.reference }} - {{ props.row.container.containerType }} - {{ props.row.container.quantity }} - {{ props.row.container.weight }}KG</p>
                <p v-else>{{ props.row.container.containerType }} - {{ props.row.container.quantity }} - {{ props.row.container.weight }}KG</p>
            </span>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </template>
        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
        <div class="d-flex justify-content-between flex-wrap bottom-table-style">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
             {{$t('myQuickQuotePage.pagination.showing')}}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> {{$t('myQuickQuotePage.pagination.of')}} {{ props.total }} {{$t('myQuickQuotePage.pagination.entries')}} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <b-icon icon="chevron-compact-left"></b-icon>
              </template>
              <template #next-text>
                <b-icon icon="chevron-compact-right"></b-icon>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
export default {
    name: "CommodityContainer",
    components: {
        VueGoodTable
    },
    props: {
        data: Array
    },
    data() {
        return {
            pageLength: 3,
            rows: [],
            searchTerm: '',
            columns: [
                {
                    label: this.$t('myQuickQuotePage.labels.commodity'),
                    field: 'commodity.title',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('myQuickQuotePage.search.commodity'),
                    },
                },
                {
                    width: "450px",
                    label: this.$t('newQuickQuotePage.commoditySection.descLabel'),
                    field: 'description',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('newQuickQuotePage.commoditySection.descSearch'),
                    },
                },
                {
                    width: "400px",
                    label: this.$t('myQuickQuotePage.labels.container2'),
                    field: 'container',
                    filterOptions: {
                        enabled: true,
                        placeholder: this.$t('myQuickQuotePage.search.container'),
                    },
                },
            ]
        }
    },
}
</script>

<style>
.column-desc {
    padding-left: 10px;
    text-align: left !important;
    max-height: 120px;
    overflow-y: auto;
    margin-bottom : 0 !important
}
</style>